import { Box, FormLabel, Stack, Typography } from "@mui/material";
import { Field, FieldArray, Form, Formik, getIn } from "formik";
import NormalInput from "../../components/inputs/normalInput";

import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate, useParams } from "react-router-dom";
import FileUpload from "../../components/inputs/file";
import Schema from "../../schema";
import { createBrand, updateBrandbyId } from "./action";

const AddBrandForCompany = () => {
    const { id } = useParams()
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { state } = useLocation();
    const [formData, setFormData] = useState(new FormData());

    const initialValues = { brandName: state?.brandName || "", product: state?.product || [{ name: "" }], brandImage: state?.brandImage || "" }
    const [value, setValue] = useState(initialValues);





    return <Box>
        <Stack direction={"row"} alignItems={"center"} mb={2}>
            <Stack

                direction={"row"}
                alignItems={"center"}
                justifyContent={"space-between"}
                sx={{
                    minHeight: '42px'
                }}
            >
                <Typography variant="h1" className="page-title">
                    {id ? "Edit Brand" : "Create Brand"}

                </Typography>

                <Stack
                    className="backText"
                    direction={"row"}
                    gap={1}
                    alignItems={"center"}
                    sx={{ cursor: "pointer" }}
                    onClick={() => navigate("/company-brands")}
                >
                    <svg
                        width="16"
                        height="12"
                        viewBox="0 0 16 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M8.5 7.66667L5.16667 4.33333L8.5 1M4.33333 7.66667L1 4.33333L4.33333 1"
                            stroke="#0077FF"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                        <path
                            d="M5.1665 4.33331H10.9998C11.8839 4.33331 12.7317 4.6845 13.3569 5.30962C13.982 5.93474 14.3332 6.78259 14.3332 7.66665C14.3332 8.5507 13.982 9.39855 13.3569 10.0237C12.7317 10.6488 11.8839 11 10.9998 11H10.1665"
                            stroke="#0077FF"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                    </svg>
                    <Typography variant="h5" color={"primary"} >
                        Back
                    </Typography>
                </Stack>
            </Stack>
        </Stack>
        <Box
            p={3}
            sx={{
                backgroundColor: '#fff',
                transition: 'all .5s ease-in-out',
                position: 'relative',
                border: '1px solid rgba(170, 170, 170, 0.3)',
                borderRadius: '12px',
                boxShadow: '0px 5px 5px 0px rgba(82, 63, 105, 0.05)',
                overflow: 'hidden',
            }}
        >
            <Formik
                initialValues={initialValues}
                validationSchema={Schema.BrandsForCompany}
                enableReinitialize
                onSubmit={(values, { resetForm }) => {

                    values['product'] = JSON.stringify(values['product'])
                    const { brandName, product, brandImage } = values;
                    const formData = new FormData();

                    formData.append("brandName", brandName)
                    formData.append("product", product)
                    formData.append("brandImage", brandImage)

                    if (id) dispatch(updateBrandbyId(formData, id))
                    else dispatch(createBrand(formData));

                    navigate("/company-brands")


                }}
            >
                {({
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    isSubmitting,
                    touched,
                    values,
                    setFieldValue,
                    isValid,
                    setFieldError,
                    dirty,
                }) => {
                    return (
                        <Form>
                            <div className="row">
                                <div className="col-xxl-5 col-xl-6 col-lg-7 col-md-8 col-sm-12 col-12">
                                    <div className="col-xxl-11 col-xl-11 col-lg-11 col-md-11 col-sm-11 col-12 mb-3">
                                        <FormLabel required>Brand Name</FormLabel>
                                        <NormalInput
                                            name={"brandName"}
                                            type={"text"}
                                            focused={false}
                                            label={("")}
                                            sizeval="medium"
                                            values={values.brandName}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            error={Boolean(touched.brandName) && errors.brandName}
                                            helperText={touched.brandName && errors.brandName}
                                        />
                                    </div>
                                    <div className="col-xxl-11 col-xl-11 col-lg-11 col-md-11 col-sm-11 col-12 mb-3">
                                        <FormLabel required>Brand Image</FormLabel>
                                        <FileUpload onChange={(e) => {
                                            handleChange({
                                                target: {
                                                    name: "brandImage",
                                                    value: e.target.files[0]
                                                }
                                            })
                                        }} fileName={values?.brandImage?.name} />
                                        {errors.brandImage && touched.brandImage && (
                                            <p
                                                className="error"

                                            >
                                                {`${errors?.brandImage}`}
                                            </p>
                                        )}


                                        {/* {values.logo?.name && <p>{values.logo?.name}</p>}
                                            {/* <Typography width={"200px"} >
                                                <ImageUpload p={0} handleFile={(event) => {
                                                    handleChange({
                                                        target: {
                                                            name: "brandImage",
                                                            value: event
                                                        }
                                                    })
                                                }} label="" fileUrl={state?.brandImage || ""} fileObject={values.brandImage} />
                                            </Typography>
                                            <small style={{fontSize:'11px', fontWeight:'400', color:'#6C7692', display:'inline-block', lineHeight:'18px'}}>Supported dimensions (pixels) are 180x180 and 300x300.</small>
                                            {errors.brandImage && touched.brandImage && (
                                                <p
                                                    className="error m-0"
                                                >
                                                    {`${errors?.brandImage}`}
                                                </p>
                                            )} */}
                                    </div>
                                    <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-4">
                                        <FieldArray
                                            name={`product`}
                                            render={(array) => (
                                                <>{values?.product?.map(
                                                    (post, index) => (
                                                        <>

                                                            <div className="row mb-3">
                                                                <div className="col-xxl-11 col-xl-11 col-lg-11 col-md-11 col-sm-11 col-11">
                                                                    <FormLabel required>Product Name. {index + 1}</FormLabel>
                                                                    <NormalInput
                                                                        name={`product.[${index}].name`}
                                                                        type={"text"}
                                                                        focused={false}
                                                                        label={("")}
                                                                        sizeval="medium"
                                                                        values={
                                                                            values.product[index].name
                                                                        }
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                    />

                                                                    <Field
                                                                        name={`product.[${index}].name`}
                                                                        render={({ form }) => {
                                                                            const error = getIn(
                                                                                form.errors,
                                                                                `product.[${index}].name`,
                                                                            );
                                                                            const touch = getIn(
                                                                                form.touched,
                                                                                `product.[${index}].name`,
                                                                            );
                                                                            return touch && error ? (
                                                                                <p className="error">
                                                                                    {error}
                                                                                </p>
                                                                            ) : null;
                                                                        }}
                                                                    />
                                                                </div>
                                                                <div className="col-xxl-1 col-xl-1 col-lg-1 col-md-1 col-sm-1 col-1 p-0">
                                                                    {index === values.product.length - 1 ? <img src="/plus.png" onClick={() => {
                                                                        array.push({
                                                                            name: ""
                                                                        });
                                                                    }} alt="plus" width={"24"} height={'24'} style={{ cursor: 'pointer', marginTop: '32px' }} /> : <img src="/minus.png"
                                                                        onClick={(e) => {
                                                                            e.preventDefault();
                                                                            array.remove(index);
                                                                        }} alt="minus" width={"24"} height={'24'} style={{ cursor: 'pointer', marginTop: '32px' }} />}
                                                                </div>
                                                            </div>
                                                        </>
                                                    ))}
                                                </>
                                            )}
                                        />
                                    </div>
                                    <div className="col-xxl-11 col-xl-11 col-lg-11 col-md-11 col-sm-12 col-12 d-flex flex-wrap justify-content-end gap-2">
                                        <button
                                            onClick={() => navigate("/company-brands")}
                                            className="btnSecondary"
                                        >
                                            Cancel
                                        </button>
                                        {id ?
                                            <button
                                                type="submit"
                                                className="btnPrimary"
                                            >
                                                Update
                                            </button> :
                                            <button
                                                type="submit"
                                                className="btnPrimary"
                                            >
                                                Create
                                            </button>
                                        }
                                    </div>
                                </div>
                            </div>
                        </Form>
                    );
                }}
            </Formik>
        </Box>
    </Box>
}
export default AddBrandForCompany;