import {
    Search
} from "@mui/icons-material";
import {
    Avatar,
    Box,
    Button,
    Checkbox,
    FormControl,
    InputAdornment,
    MenuItem,
    Select,
    Stack,
    Tooltip,
    Typography
} from "@mui/material";
import Pagination from "@mui/material/Pagination";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import { visuallyHidden } from "@mui/utils";
import { useRef, useState } from "react";

//import SelectPerPage from "../../components/select/paginationSelect";
import { useDispatch, useSelector } from "react-redux";
import useDebounce from "../../../hooks/use.debounce";

import { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import CommonModel from "../../../components/dialog/Common";
import SearchInput from "../../../components/inputs/searchInput";
import PaginationSelect from "../../../components/select/pagination";
import { FOLLOWERS_ARRAY, getFixedValue, SOCIAL_PLATFORM } from "../../../utils/constant";
import { addGroupMember, deleteCreator, getAllCreator } from "../../users/action";
import { updateUser } from "../../users/userSlice";
import { getAllSelectedInfluencers } from "../action";
import SelecetedUsers from "./selectedUsers";

function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort, listing, selectedRows } = props;
    const { selectedAllUserCheckbox, allcheckbox
    } = useSelector((state) => state.userSlice);
    const dispatch = useDispatch();
    const createSortHandler =
        (property) => (event) => {
            if (property === "date") {
                onRequestSort(event, property);
            }
            return;
        };
    const headCells = [
        {
            id: "name",
            numeric: false,
            disablePadding: true,
            label: "Name",
            type: "",
        },
        {
            id: "username",
            numeric: false,
            disablePadding: true,
            label: "Username",
            type: "",
        },
        {
            id: "Platform",
            numeric: false,
            disablePadding: true,
            label: "Platform",
            type: "",
        },
        {
            id: "niches",
            numeric: false,
            disablePadding: true,
            label: "Niches",
            type: "",
        },
        {
            id: "location",
            numeric: false,
            disablePadding: true,
            label: "location",
            type: "",
        },
        {
            id: "type",
            numeric: false,
            disablePadding: true,
            label: "Type",
            type: "",
        },
        {
            id: "action",
            numeric: false,
            disablePadding: true,
            label: "Action",
            type: "",
        },
    ];

    // headCell.type == '' || ([headCell.type].includes(subType)) ?
    return (
        <TableHead className="hideActionSort">
            <TableRow>
                <TableCell>
                    <Checkbox
                        //checked={selectedAllUserCheckbox.length === listing.filter((v) => !selectedRows.includes(v.id))?.length && selectedRows?.length !== listing?.length}
                        checked={allcheckbox}
                        //disabled={listing.length === selectedRows.length}
                        onChange={(e) => {
                            if (e.target.checked) {
                                dispatch(updateUser({ selectedAllUserCheckbox: listing.filter((v) => !selectedRows.includes(v.id)).map((v) => v.id), allcheckbox: true }))
                            } else {
                                dispatch(updateUser({ selectedAllUserCheckbox: [], allcheckbox: false }))
                            }

                        }}
                        dis
                        inputProps={{ 'aria-label': 'controlled' }}
                    />
                </TableCell>
                {headCells.map((headCell) => {
                    return (
                        <TableCell
                            key={headCell?.id}
                            align={"left"}
                            padding={headCell.disablePadding ? "none" : "normal"}
                            sortDirection={orderBy == headCell.id ? order : false}
                            onClick={createSortHandler(headCell.id)}
                        >
                            <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : "asc"}
                            // IconComponent={headCell.id === "date" ? ArrowDownwardIcon : null}
                            // onClick={createSortHandler(headCell.id)}
                            >
                                {headCell.label}
                                {orderBy === headCell.id ? (
                                    <Box component="span" sx={visuallyHidden}>
                                        {order === "desc"
                                            ? "sorted descending"
                                            : "sorted ascending"}
                                    </Box>
                                ) : null}
                            </TableSortLabel>
                        </TableCell>
                    );
                })}
            </TableRow>
        </TableHead>
    );
}


export default function InfluencersList({ details }) {
    const [order, setOrder] = useState("asc");

    const [listType, setListType] = useState(1);
    const { id } = useParams();

    const [orderBy, setOrderBy] = useState("date");
    const [deleteOpen, setDeleteOpen] = useState(0);
    const {
        niches,
    } = useSelector((state) => state.commonSlice);
    const [openTooltipIndexes, setOpenTooltipIndexes] = useState([]);
    const tooltipRefs = useRef([]);
    const handleTooltipToggle = (cellIndex) => {
        setOpenTooltipIndexes(openTooltipIndexes.includes(cellIndex) ? [] : [cellIndex]);
    };
    const handleDocumentClick = (event) => {
        const clickedOutsideAllTooltips = tooltipRefs.current.every(ref => ref && !ref.contains(event.target));
        if (clickedOutsideAllTooltips) {
            setOpenTooltipIndexes([]);
        }
    };
    useEffect(() => {
        document.addEventListener('click', handleDocumentClick);

        return () => {
            document.removeEventListener('click', handleDocumentClick);
        };
    }, []);
    const dispatch = useDispatch();
    const {
        limit,
        page,
        search,
        sort_key,
        totalCount,
        totalPages,
        listing,
        filters,
        sort_type,
        selectedAllUserCheckbox, selectedRows
    } = useSelector((state) => state.userSlice);

    const debouncedSearch = useDebounce(filters.search, 500);

    const handleChangePerPage = (event, value) => {
        dispatch(updateUser({ limit: event.target.value, page: 0 }));
        dispatch(getAllCreator());
    };

    const handleChange = (event, value) => {
        dispatch(updateUser({ page: value - 1 }));
        dispatch(getAllCreator());
    };

    const handleRequestSort = (
        event,
        property
    ) => {
        const isAsc = orderBy === property && order === "asc";

        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
        // if (property === "status") return;
        dispatch(
            updateUser({
                sort_type: isAsc ? "asc" : "desc",
                sort_key: property,
            }),
        );
        dispatch(getAllCreator());
    };

    useEffect(() => {
        if (debouncedSearch || filters.search == "")
            dispatch(getAllCreator());

    }, [debouncedSearch]);

    useEffect(() => {
        dispatch(getAllCreator());
        return () => dispatch(updateUser({ filters: {} }))
    }, []);

    const handleSearch = (event, value) => {
        // if (event.target.value)
        dispatch(updateUser({ page: 0, filters: { ...filters, search: event.target.value } }));
    };
    const handleSearchClear = (e) => {
        dispatch(updateUser({ filters: { ...filters, search: "" } }));
        dispatch(getAllCreator());
    };

    useEffect(() => {
        if (listing?.length > 0) {
            // dispatch(updateUser({ selectedRows: listing?.filter((v) => details?.influencerIds?.includes(v.id)) }));
        }
        dispatch(getAllSelectedInfluencers(id));
    }, [listing, dispatch, details]);




    return (
        <>
            <Stack direction={"column"} gap={2}>
                <Stack
                    className="mainTitle  flex-wrap"
                    direction={"row"}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                    sx={{
                        minHeight: '42px'
                    }}
                >
                    <Typography className="page-title" variant="h1">
                        Creator Listing
                    </Typography>
                    <Stack direction="row" gap={2} className="viewDetTabs mt-0 campaignDetails" bgcolor={"#F7F7F7"}>
                        <button className={listType == 1 ? "active" : ""} onClick={() => setListType(1)}>
                            All Users
                        </button>
                        <button className={(listType == 2 ? "active" : "") + " d-flex align-items-center"} onClick={() => setListType(2)}>
                            Selected Users {selectedRows.length > 0 && (listType === 1 ? <span>({selectedRows.length})</span> : <span>{selectedRows.length}</span>)}
                        </button>
                    </Stack>
                </Stack>
                <Box
                    sx={{
                        backgroundColor: '#fff',
                        transition: 'all .5s ease-in-out',
                        position: 'relative',
                        border: '1px solid rgba(170, 170, 170, 0.3)',
                        borderRadius: '12px',
                        boxShadow: '0px 5px 5px 0px rgba(82, 63, 105, 0.05)',
                        overflow: 'none',
                    }}>

                    {listType === 1 && <div className="myCmpny d-flex flex-wrap align-items-center justify-content-between searcFilter p-3">
                        {/* <PaginationSelect handleChange={handleChangePerPage} page={limit} /> */}
                        <div className='allFilter campMgmt'>
                            <FormControl className="sContrl" variant="outlined" sx={{ marginRight: 'auto' }}>
                                <SearchInput
                                    className="inputSearch p-0"
                                    value={filters.search}
                                    placeholder={"Search here..."}
                                    handleSearch={handleSearch}
                                    clearSearch={handleSearchClear}
                                    startAdornment={
                                        <InputAdornment className="overIcon " position="start"
                                            sx={{
                                                maxHeight: 'none',
                                                height: '38px',
                                                minWidth: '38px',
                                                marginRight: '0px'
                                            }}
                                        >
                                            <Search />
                                        </InputAdornment>
                                    }
                                />
                            </FormControl>

                        </div>
                        <div className='allFilter campMgmt'>
                            {selectedAllUserCheckbox.length > 1 &&
                                <Button variant="contained" onClick={() => {

                                    dispatch(updateUser({ allcheckbox: false, selectedRows: [...(selectedRows || []), ...listing.filter((v) => selectedAllUserCheckbox.includes(v.id))] }))
                                    dispatch(addGroupMember(id));
                                }}>Add Users</Button>}
                            <Select
                                value={filters.search || ''}
                                name="search"
                                onChange={(e) => {
                                    dispatch(updateUser({ filters: { ...filters, search: e.target.value }, page: 0 }));
                                    dispatch(getAllCreator());
                                }}
                                displayEmpty
                                defaultValue={""}
                            >
                                <MenuItem value="" disabled hidden>
                                    Niche
                                </MenuItem>
                                <MenuItem key="All" value=" ">
                                    All
                                </MenuItem>
                                {niches?.map((row) => (
                                    <MenuItem key={row} value={row}>
                                        {row}
                                    </MenuItem>
                                ))}
                            </Select>
                            <Select
                                value={filters.platform || ""}
                                name="platform"
                                onChange={(e) => {
                                    dispatch(updateUser({ filters: { ...filters, platform: e.target.value }, page: 0 }));
                                    dispatch(getAllCreator());
                                }}
                                displayEmpty
                            // defaultValue={""}
                            >
                                <MenuItem value="" disabled hidden>
                                    Platform
                                </MenuItem>
                                {SOCIAL_PLATFORM.map((item) => (
                                    <MenuItem key={item} value={item}>
                                        {item}
                                    </MenuItem>
                                ))}
                            </Select>
                            <Select
                                value={filters.followers || ''}
                                name="followers"
                                onChange={(e) => {
                                    dispatch(updateUser({ filters: { ...filters, followers: e.target.value }, page: 0 }));
                                    dispatch(getAllCreator());
                                }}
                                displayEmpty
                                defaultValue={""}
                            >
                                <MenuItem value="" disabled hidden>
                                    Followers
                                </MenuItem>
                                <MenuItem key="All" value=" ">
                                    All
                                </MenuItem>
                                {FOLLOWERS_ARRAY.map((row) => (
                                    <MenuItem key={row.value} value={row.value}>
                                        {row.label}
                                    </MenuItem>
                                ))}
                            </Select>




                            {/* <Tooltip title="Refresh listing">
                                <RotateLeftIcon toolTip="Refresh page" size="small" sx={{ cursor: "pointer", color: '#6C7692' }}
                                    onClick={() => {
                                        dispatch(updateUser({ filters: {} }))
                                        dispatch(getAllCreator());
                                    }} />
                            </Tooltip> */}
                        </div>

                    </div>}


                    {listType === 1 ? <TableContainer className="campListTables" sx={{ maxHeight: '60vh' }} stickyHeader >
                        <Table
                            stickyHeader aria-label="sticky table"
                        >
                            <EnhancedTableHead
                                // numSelected={selected.length}
                                listing={listing}
                                order={order}
                                orderBy={sort_key}
                                //onSelectAllClick={handleSelectAllClick}
                                onRequestSort={handleRequestSort}
                                //rowCount={rows.length}
                                selectedRows={selectedRows?.map((v) => v.id)}
                                numSelected={0}
                                onSelectAllClick={function (

                                ) {
                                    throw new Error("Function not implemented.");
                                }}
                            //order={"desc"}
                            />

                            <TableBody>
                                {listing && listing?.length ? (
                                    listing?.map((row, index) => {
                                        let username = row?.platforms?.map((v) => v?.username)?.filter((v) => v);
                                        let niches = row?.profileNiche ? JSON?.parse(row?.profileNiche)?.map((v) => v.option)?.toString() || "N/A" : "N/A";
                                        const cellTooltipIndex = index;
                                        const isOpen = openTooltipIndexes.includes(cellTooltipIndex);
                                        const rowsSelected = selectedRows.map((v) => v.id);
                                        return (
                                            <TableRow
                                                hover
                                                role="checkbox"
                                                aria-checked={selectedAllUserCheckbox?.includes(row.id)}
                                                tabIndex={-1}
                                                key={row.id}
                                                selected={selectedAllUserCheckbox?.includes(row.id)}

                                            >
                                                <TableCell>
                                                    <Checkbox
                                                        disabled={rowsSelected.includes(row.id)}
                                                        checked={selectedAllUserCheckbox?.includes(row.id)}
                                                        onChange={(e) => {
                                                            if (rowsSelected.includes(row.id)) return;
                                                            if (e.target.checked) {
                                                                dispatch(updateUser({ selectedAllUserCheckbox: [...selectedAllUserCheckbox, row.id] })) //selectedRows: [...(selectedRows || []), row]
                                                            } else {
                                                                dispatch(updateUser({ selectedAllUserCheckbox: selectedAllUserCheckbox.filter((v) => v !== row.id) })) //, selectedRows: selectedRows.filter((v) => v.id !== row.id)
                                                            }

                                                        }}
                                                        sx={{ cursor: rowsSelected.includes(row.id) ? 'not-allowed' : "" }}
                                                        inputProps={{ 'aria-label': 'controlled' }}
                                                    />

                                                </TableCell>

                                                <TableCell component="th" scope="row" padding="none">
                                                    <Stack direction={"row"} >
                                                        <Avatar
                                                            alt="Remy Sharp"
                                                            src={row.picture}
                                                            sx={{ height: '35px', width: '35px', borderRadius: "50%" }}
                                                        />
                                                        <Link

                                                            className="text-decoration-none text-capitalize"
                                                            style={{ fontSize: '13px', fontWeight: '500', letterSpacing: '0px', marginLeft: '8px', position: "relative", top: 5 }}
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                window.open(`/userManagement/details/${row.id}`)
                                                            }}
                                                        >
                                                            {row?.name || "N/A"}
                                                        </Link>
                                                    </Stack>
                                                </TableCell>

                                                <TableCell component="th" scope="row" padding="none">

                                                    {username?.length > 0 ? username?.toString() : "N/A"}
                                                </TableCell>
                                                <TableCell component="th" scope="row" padding="none">
                                                    {row?.platforms?.map((v) => parseInt(v?.followers))?.filter((v) => v)?.length > 0 ? getFixedValue(row?.platforms?.map((v) => parseInt(v?.followers))?.filter((v) => v)?.reduce((a, b) => Math?.max(a, b))) : "N/A"}
                                                    {/* {getFixedValue(maxValue(row?.platforms?.map((v) => parseInt(v?.followers))?.filter((v) => v))) || "N/A"} */}
                                                    {/* {row?.followers ? row?.followers + " (" + row?.influencerType + ")" : "N/A"} */}
                                                </TableCell>
                                                <TableCell align="left">
                                                    {niches != null && niches.length > 20 ? (
                                                        <div >
                                                            <Tooltip
                                                                ref={ref => tooltipRefs.current[cellTooltipIndex] = ref}
                                                                title={niches}
                                                                PopperProps={{
                                                                    disablePortal: true,
                                                                }}
                                                                arrow={true}
                                                                open={isOpen}
                                                                disableFocusListener
                                                                disableTouchListener
                                                                disableInteractive
                                                            >
                                                                <span
                                                                    className="showhandCursor"
                                                                    onClick={() => handleTooltipToggle(cellTooltipIndex)}
                                                                >
                                                                    {niches.slice(0, 20)}
                                                                </span>
                                                            </Tooltip>
                                                            {!isOpen && <Tooltip title={niches} arrow={true}>
                                                                <span className="showhandCursor">...</span>
                                                            </Tooltip>}
                                                        </div>
                                                    ) : (
                                                        <span>{niches}</span>
                                                    )}

                                                </TableCell >

                                                <TableCell component="th" scope="row" padding="none">
                                                    {(row?.state ? row?.state : "N/A")}
                                                </TableCell>
                                                <TableCell component="th" scope="row" padding="none">
                                                    {row?.platforms?.map((v) => parseInt(v?.followers))?.filter((v) => v)?.length > 0 ? getFixedValue(row?.platforms?.map((v) => parseInt(v?.followers))?.filter((v) => v)?.reduce((a, b) => Math?.max(a, b))) : "N/A"}

                                                </TableCell>
                                                <TableCell component="th" scope="row" padding="none">
                                                    <Button onClick={() => {
                                                        dispatch(updateUser({ selectedRows: [...(selectedRows || []), row], selectedAllUserCheckbox: selectedAllUserCheckbox.filter((v) => v !== row.id) }))
                                                        dispatch(addGroupMember(id));
                                                    }} variant="contained" disabled={rowsSelected.includes(row.id)} size="small"> Add</Button>
                                                </TableCell>

                                            </TableRow >
                                        );
                                    })
                                ) : (
                                    <TableRow colSpan={11}>
                                        <TableCell colSpan={11} style={{ textAlign: 'center', height: "250px" }}>

                                            <Typography
                                                color="inherit"
                                                variant="subtitle1"
                                                alignItems={"center"}
                                                component="h3"
                                            // sx={{ minHeight: 300 }}
                                            >
                                                No Data found!
                                            </Typography>

                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody >
                        </Table >
                    </TableContainer > : <SelecetedUsers />}





                    <hr />
                    {listType === 1 && <Stack
                        sx={{
                            background: 'transparent',
                        }}
                        direction="row"
                        alignItems={"center"}
                        justifyContent={"flex-end"}
                    >
                        <Box className="pagination-cover d-flex flex-wrap align-items-center justify-content-between py-4 px-4 w-100" gap={1.5}>
                            <Box className="d-flex align-items-center rowPerPage" gap={0}>
                                <span style={{ fontSize: '13px', color: 'rgba(0, 0, 0, 0.7)', fontWeight: '500' }}>Rows per page:</span>
                                <PaginationSelect handleChange={handleChangePerPage} page={limit} />
                            </Box>
                            <Box className="d-flex flex-wrap align-items-center pageSteps" gap={1}>
                                <Typography variant="subtitle2" style={{ fontSize: '13px', color: 'rgba(0, 0, 0, 0.7)', fontWeight: '500' }}>
                                    {listing && listing.length > 0
                                        ? page === 0
                                            ? `0 - ${listing.length}`
                                            : `${limit * (page - 0) + 1}  - ${limit * (page - 0) + listing.length
                                            } `
                                        : "0 - 0"}{" "}
                                    &nbsp;of {totalCount} items
                                </Typography>
                                <Pagination

                                    count={Math.ceil(totalPages / limit) || 0}
                                    variant="outlined"
                                    onChange={handleChange}
                                    defaultPage={1} siblingCount={0} boundaryCount={1}
                                />
                            </Box>
                        </Box>
                    </Stack >}
                </Box >
            </Stack >
            <CommonModel
                open={deleteOpen}
                handleDialogClose={() => setDeleteOpen(false)}
                dialogTitle={"Delete Creator"}
                className={"btnDanger"}
                dialogPara={`Are you sure you want to delete this creator? `}
                nvYes={"Delete"}
                nvCancel={"Cancel"}
                yesHandle={() => {
                    dispatch(deleteCreator(deleteOpen));
                    setDeleteOpen(false);
                }}
            />
        </>
    );
}
