import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import {
    Box,
    FormControl,
    MenuItem,
    Pagination,
    Select,
    Stack,
    Typography
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import { useEffect, useState } from "react";
//import SelectPerPage from "../../components/select/paginationSelect";
import { useDispatch, useSelector } from "react-redux";
import useDebounce from "../../../hooks/use.debounce";

import { useLocation, useNavigate, useParams } from "react-router-dom";
//import InfluencerBiddingDetails from '../../../components/dialog/InfluencerBiddingDetails';
import { io } from 'socket.io-client';
import DetailsButtons from '../../../components/buttons';
import BiddingDetails from '../../../components/dialog/BiddingDetails';
import Invite from '../../../components/dialog/invite';
import PaginationSelect from '../../../components/select/pagination';
import { capitalizeFirstLetter } from '../../../utils/common';
import { BASE_URL, SOCKET_EVENT } from "../../../utils/constant";
import { endpoints } from '../../../utils/endpoints';
import { getCampaignInfluencersList } from "../action";
import { updateCampaign } from "../campaignSlice";




function EnhancedTableHead(props) {

    const headCells = [
        {
            id: "influencername",
            numeric: false,
            disablePadding: true,
            label: "Influencer Name",
            type: "",
        },
        {
            id: "niches",
            numeric: false,
            disablePadding: true,
            label: "Niches",
            type: "",
        },
        {
            id: "followers",
            numeric: false,
            disablePadding: true,
            label: "Followers",
            type: "",
        },
        {
            id: "platform",
            numeric: false,
            disablePadding: true,
            label: "Platform",
            type: "",
        },

        {
            id: "brandsendorse",
            numeric: false,
            disablePadding: true,
            label: "Brands Endorse",
            type: "",
        },

        {
            id: "location",
            numeric: false,
            disablePadding: true,
            label: "Location",
            type: "",
        },
        {
            id: "bidddingStatus",
            numeric: false,
            disablePadding: true,
            label: "Bidding Status",
            type: "",
        },
        {
            id: "action",
            numeric: false,
            disablePadding: true,
            label: "Action",
            type: "",
        },
    ];

    // headCell.type == '' || ([headCell.type].includes(subType)) ?
    return (
        <TableHead className="hideActionSort">
            <TableRow>
                {headCells.map((headCell) => {
                    return (
                        <TableCell
                            key={headCell?.id}
                            align={"left"}
                            padding={headCell.disablePadding ? "none" : "normal"}

                        >
                            <TableSortLabel

                            >
                                {headCell.label}

                            </TableSortLabel>
                        </TableCell>
                    );
                })}
            </TableRow>
        </TableHead>
    );
}



export default function CampaignInfluencers() {

    const [openModal, setOpenmodel] = useState(false);
    const [inviteModal, setInviteModal] = useState(false);

    const { id } = useParams();
    const navigate = useNavigate();

    const state = useLocation();

    const dispatch = useDispatch();
    const {
        sort_key,
        campaignInfluencersList,
        limit,
        totalCount,
        filters,
        totalPages,
        page,
        formData
    } = useSelector((state) => state.campaignSlice);
    const debouncedSearch = useDebounce(filters.search, 500);
    const { plateforms, niches } = useSelector((state) => state.commonSlice);
    const socket = io(BASE_URL + endpoints.ims, {
        path: '/stream',
        transports: ['websocket']
    });

    const handleChangePerPage = (event, value) => {
        dispatch(updateCampaign({ limit: event.target.value, page: 0 }));
        dispatch(getCampaignInfluencersList(id));
    };


    const handleChange = (event, value) => {
        dispatch(updateCampaign({ page: value - 1 }));
        dispatch(getCampaignInfluencersList(id));
    };

    useEffect(() => {
        socket.on(SOCKET_EVENT.BID_UPDATED, (data) => {

            if (id == data?.id) {
                dispatch(getCampaignInfluencersList(id));
            }
        });
    }, [socket]);

    useEffect(() => {
        dispatch(updateCampaign({ filters: {} }))
        dispatch(getCampaignInfluencersList(id));
        //dispatch(updateCampaign({ filters: { ...filters, compaignId: id } }));
        return () => dispatch(updateCampaign({ campaignInfluencersList: {} }))
    }, [debouncedSearch, id]);

    return (
        <>

            <Stack className='mainTitleBtn d-flex flex-wrap flex-row justify-content-between gap-3' mb={2}>
                <Stack
                    direction={"row"}
                    alignItems={"center"}
                    justifyContent={"flex-start"}
                    sx={{
                        minHeight: '42px',
                    }}
                >
                    <Typography className="page-title capitalize-first-letter" variant="h1">{state?.state?.campaignName || formData?.name}</Typography>
                    <Stack
                        className='backText'
                        direction={"row"}
                        gap={1}
                        alignItems={"center"}
                        sx={{ cursor: "pointer" }}
                        onClick={() => navigate("/campaign/details/" + id)}
                    >
                        <svg
                            width="16"
                            height="12"
                            viewBox="0 0 16 12"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M8.5 7.66667L5.16667 4.33333L8.5 1M4.33333 7.66667L1 4.33333L4.33333 1"
                                stroke="#0077FF"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                            <path
                                d="M5.1665 4.33331H10.9998C11.8839 4.33331 12.7317 4.6845 13.3569 5.30962C13.982 5.93474 14.3332 6.78259 14.3332 7.66665C14.3332 8.5507 13.982 9.39855 13.3569 10.0237C12.7317 10.6488 11.8839 11 10.9998 11H10.1665"
                                stroke="#0077FF"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                        </svg>
                        <Typography variant="h5" color={"primary"}>
                            Back
                        </Typography>

                    </Stack>
                </Stack>
                <DetailsButtons />

            </Stack>
            <Box
                sx={{
                    backgroundColor: '#fff',
                    transition: 'all .5s ease-in-out',
                    position: 'relative',
                    border: '1px solid rgba(170, 170, 170, 0.3)',
                    borderRadius: '12px',
                    boxShadow: '0px 5px 5px 0px rgba(82, 63, 105, 0.05)',
                    overflow: 'hidden',
                }}
            >

                <div className="myCmpny d-flex flex-wrap align-items-center justify-content-between searcFilter p-3">
                    <FormControl variant="outlined" sx={{ marginRight: 'auto' }}>
                        <button className="btnPrimary" onClick={() => navigate("/campaign/send-more-influencer/" + id)}>Send More Invites</button>
                    </FormControl>
                    <div className='allFilter campMgmt'>
                        {/* <button className="btnSecondary" onClick={() => navigate("/campaign/send-more-influencer/" + id)}>Invite More Influencers</button> */}
                        <Select
                            value={filters?.platform || ""}
                            name="platform"
                            onChange={(e) => {
                                dispatch(updateCampaign({ filters: { ...filters, platform: e.target.value?.toLowerCase() } }));
                                dispatch(getCampaignInfluencersList(id));
                            }}
                            displayEmpty
                            defaultValue=""
                            className="upperCase"
                        >
                            <MenuItem disabled value="">
                                Platform
                            </MenuItem>
                            <MenuItem className="upperCase" value=" ">
                                All
                            </MenuItem>
                            {plateforms?.map((v) => (
                                <MenuItem className="upperCase" key={v} value={v?.toLowerCase()}>
                                    {v}
                                </MenuItem>
                            ))}
                        </Select>
                        <Select
                            value={filters?.niches || ""}
                            name="niches"
                            onChange={(e) => {
                                dispatch(updateCampaign({ filters: { ...filters, niches: e.target.value } }));
                                dispatch(getCampaignInfluencersList(id));
                            }}
                            displayEmpty
                            defaultValue=""
                            className="upperCase"
                        >
                            <MenuItem disabled value="">
                                Niches
                            </MenuItem>
                            <MenuItem className="upperCase" value=" ">
                                All
                            </MenuItem>
                            {niches?.map((v, i) => (
                                <MenuItem className="upperCase" key={i} value={v}>
                                    {v}
                                </MenuItem>
                            ))}
                        </Select>
                        <Select
                            value={filters?.status || ""}
                            name="type"
                            onChange={(e) => {
                                dispatch(updateCampaign({ filters: { ...filters, status: e.target.value } }))
                                dispatch(getCampaignInfluencersList(id));
                            }}
                            displayEmpty
                            defaultValue=""
                            className="upperCase"
                        >
                            <MenuItem disabled value="">
                                Bidding Status
                            </MenuItem>
                            <MenuItem className="upperCase" value=" ">
                                All
                            </MenuItem>
                            {["Accepted", "Rejected", "Rebid", "Submitted", "Negotiation", "Negotiated"].map((v) => (
                                <MenuItem className="upperCase" key={v} value={capitalizeFirstLetter(v)}>
                                    {capitalizeFirstLetter(v)}
                                </MenuItem>
                            ))}
                        </Select>

                        <RotateLeftIcon
                            toolTip="Refresh page"
                            size="small"
                            sx={{ cursor: "pointer", color: '#6C7692' }}
                            onClick={() => {
                                dispatch(updateCampaign({ filters: {} }))
                                dispatch(getCampaignInfluencersList(id));
                            }}
                        />

                    </div>
                </div>

                <TableContainer  >
                    <Table
                        //sx={{ maxHeight: 440 }}
                        aria-labelledby="tableTitle"
                    //size={dense ? "small" : "medium"}
                    >
                        <EnhancedTableHead
                            // numSelected={selected.length}

                            order={"asc"}
                            orderBy={sort_key}
                            //onSelectAllClick={handleSelectAllClick}
                            onRequestSort={() => { }}
                            //rowCount={rows.length}
                            numSelected={0}
                            onSelectAllClick={function (

                            ) {
                                throw new Error("Function not implemented.");
                            }}
                        //order={"desc"}
                        />

                        <TableBody>
                            {campaignInfluencersList && campaignInfluencersList?.length > 0 ? (
                                campaignInfluencersList?.map((row, index) => {
                                    return (
                                        row?.id && <TableRow
                                            hover
                                            role="checkbox"
                                            tabIndex={-1}
                                            key={row?.id}
                                            className={"tableRow"}
                                            style={{ userSelect: "text" }}
                                        >
                                            <TableCell component="th" scope="row" padding="none">
                                                {row?.influencer?.name || "N/A"}
                                            </TableCell>
                                            <TableCell component="th" scope="row" padding="none">
                                                {row?.influencer?.profileNiche?.map((v) => v.option)?.toString() || "N/A"}
                                            </TableCell>
                                            <TableCell component="th" scope="row" padding="none">
                                                {row?.influencer?.followers || "N/A"}
                                            </TableCell>

                                            <TableCell align="left" className="single-line">
                                                {row?.influencer?.platform?.length > 0 ? row?.influencer?.platform?.map((v) => v.platformType)?.toString() : "N/A"}
                                            </TableCell>

                                            <TableCell align="left">
                                                0
                                            </TableCell>

                                            <TableCell align="left">
                                                {row?.influencer?.city || row?.influencer?.state
                                                    ? `${row?.influencer?.city || ''}${row?.influencer?.city && row?.influencer?.state ? ', ' : ''}${row?.influencer?.state || ''}`.trim() || "N/A"
                                                    : "N/A"}

                                            </TableCell>
                                            <TableCell align="left">
                                                <button className={row.status?.toLowerCase() + " capitalize-first-letter"}>{row.status}</button>
                                            </TableCell>
                                            <TableCell>
                                                <div className='actBtnGrps'>
                                                    <span className='viewIcon' onClick={() => {
                                                        dispatch(updateCampaign({ influencersBidDetails: row, appliedInfluencerId: row.id }))
                                                        setOpenmodel(true)
                                                    }}>View</span>
                                                </div>

                                            </TableCell>
                                        </TableRow>
                                    );
                                })
                            ) : (
                                <TableRow colSpan={10}>
                                    <TableCell colSpan={10} style={{ textAlign: 'center' }}>

                                        <Typography
                                            color="inherit"
                                            variant="subtitle1"
                                            alignItems={"center"}
                                            component="h3"
                                        >
                                            No Data found!
                                        </Typography>

                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>

                </TableContainer>
                <hr />
                <Stack
                    sx={{
                        background: 'transparent',
                    }}
                    direction="row"
                    alignItems={"center"}
                    justifyContent={"flex-end"}
                >
                    <Box className="pagination-cover d-flex align-items-center justify-content-between py-4 px-4 w-100" gap={1.5}>
                        <Box className="d-flex align-items-center rowPerPage" gap={0}>
                            <span style={{ fontSize: '13px', color: 'rgba(0, 0, 0, 0.7)', fontWeight: '500' }}>Rows per page:</span>
                            <PaginationSelect handleChange={handleChangePerPage} page={limit} />
                        </Box>
                        <Box className="d-flex align-items-center pageSteps" gap={1}>
                            <Typography variant="subtitle2">
                                {campaignInfluencersList && campaignInfluencersList.length > 0
                                    ? page === 0
                                        ? `0 - ${campaignInfluencersList.length}`
                                        : `${limit * (page - 0) + 1}  - ${limit * (page - 0) + campaignInfluencersList.length
                                        } `
                                    : "0 - 0"}{" "}
                                &nbsp;of {totalCount} items
                            </Typography>
                            <Pagination
                                count={Math.ceil(totalPages / limit) || 0}
                                variant="outlined"
                                onChange={handleChange}
                            />
                        </Box>
                    </Box>

                </Stack>
            </Box>

            <Invite setInviteModal={setInviteModal} inviteModal={inviteModal} />

            <BiddingDetails setOpenmodel={setOpenmodel} openModal={openModal} />
            {/* <InfluencerBiddingDetails setOpenmodel={setOpenmodel} openModal={openModal} /> */}

        </>
    );
}
