import {
    Box,
    Checkbox,
    Grid,
    Pagination,
    Stack,
    Typography
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import { useEffect, useState } from "react";
//import SelectPerPage from "../../components/select/paginationSelect";
import { useDispatch, useSelector } from "react-redux";

import { useLocation, useNavigate, useParams } from "react-router-dom";
//import InfluencerBiddingDetails from '../../../components/dialog/InfluencerBiddingDetails';
import { io } from 'socket.io-client';
import DetailsButtons from '../../../components/buttons';
import ApprovedModel from "../../../components/dialog/approvedModel";
import BiddingDetails from '../../../components/dialog/BiddingDetails';
import Invite from '../../../components/dialog/invite';
import PaginationSelect from '../../../components/select/pagination';
import { formatPrice, getUserInfo, getUserType } from "../../../utils/common";
import { BASE_URL, COMPANY, getFixedValue, LIVE, NEGOTIATED, SOCKET_EVENT } from "../../../utils/constant";
import { endpoints } from '../../../utils/endpoints';
import { getCampaignInfluencersList, moveToApprovedInfluencers } from "../action";
import { updateCampaign } from "../campaignSlice";




function EnhancedTableHead(props) {
    const { listing, setIds, ids, setTotal, state } = props;
    const headCells = [
        {
            id: "influencername",
            numeric: false,
            disablePadding: true,
            label: "Influencer Name",
            type: "",
        },
        {
            id: "price",
            numeric: false,
            disablePadding: true,
            label: "Final Price",
            type: "",
        },
        {
            id: "followers",
            numeric: false,
            disablePadding: true,
            label: "Followers",
            type: "",
        },
        {
            id: "location",
            numeric: false,
            disablePadding: true,
            label: "Location",
            type: "",
        }
    ];

    // headCell.type == '' || ([headCell.type].includes(subType)) ?

    const type = state?.state?.type || "";
    return (
        <TableHead className="hideActionSort">
            <TableRow>
                {getUserType() == COMPANY &&
                    <TableCell>
                        <Checkbox
                            checked={listing?.length > 0 && listing?.length == ids.length}
                            onChange={(e) => {
                                if (e.target.checked) {
                                    setTotal(listing?.reduce((a, v) => a + v.amount, 0))
                                    setIds(listing.map((v) => v.influencer.id))
                                } else {
                                    setTotal(0)
                                    setIds([]);
                                }
                            }}
                            inputProps={{ 'aria-label': 'controlled' }}
                        />
                    </TableCell>}
                {headCells.filter((v) => {
                    // Show all columns except "price" unless type is "paid"

                    if (v.id === "price" && type !== "paid") {
                        return false;
                    }
                    return true; // Keep other columns
                }).map((headCell) => {
                    return (
                        <TableCell
                            key={headCell?.id}
                            align={"left"}
                            padding={headCell.disablePadding ? "none" : "normal"}

                        >
                            <TableSortLabel

                            >
                                {headCell.label}

                            </TableSortLabel>
                        </TableCell>
                    );
                })}
            </TableRow>
        </TableHead>
    );
}



export default function NegotiatedInfluencers() {

    const [openModal, setOpenmodel] = useState(false);
    const [inviteModal, setInviteModal] = useState(false);

    const [total, setTotal] = useState(0);

    const [open, setOpen] = useState(false);


    const { id } = useParams();
    const navigate = useNavigate();

    const state = useLocation();

    const dispatch = useDispatch();
    const {
        sort_key,
        campaignInfluencersList,
        limit,
        totalCount,
        filters,
        totalPages,
        page,
        formData
    } = useSelector((state) => state.campaignSlice);
    const [ids, setIds] = useState([]);
    const socket = io(BASE_URL + endpoints.ims, {
        path: '/stream',
        transports: ['websocket']
    });

    const handleChangePerPage = (event, value) => {
        dispatch(updateCampaign({ limit: event.target.value, page: 0 }));
        dispatch(getCampaignInfluencersList(id));
    };


    const handleChange = (event, value) => {
        dispatch(updateCampaign({ page: value - 1 }));
        dispatch(getCampaignInfluencersList(id));
    };

    useEffect(() => {
        socket.on(SOCKET_EVENT.BID_UPDATED, (data) => {

            if (id == data?.id) {
                dispatch(getCampaignInfluencersList(id));
            }
        });
    }, [socket]);



    useEffect(() => {
        dispatch(updateCampaign({ filters: { status: NEGOTIATED } }))
        dispatch(getCampaignInfluencersList(id));

        return () => dispatch(updateCampaign({ campaignInfluencersList: {}, filters: { status: "" } }))
    }, [dispatch, id]);

    return (
        <>

            <Stack className='mainTitleBtn d-flex flex-wrap flex-row justify-content-between gap-3' mb={2}>
                <Stack
                    direction={"row"}
                    alignItems={"center"}
                    justifyContent={"flex-start"}
                    sx={{
                        minHeight: '42px',
                    }}
                >
                    <Typography className="page-title capitalize-first-letter" variant="h1">{state?.state?.campaignName || formData?.name}</Typography>
                    <Stack
                        className='backText'
                        direction={"row"}
                        gap={1}
                        alignItems={"center"}
                        sx={{ cursor: "pointer" }}
                        onClick={() => navigate("/campaign/details/" + id)}
                    >
                        <svg
                            width="16"
                            height="12"
                            viewBox="0 0 16 12"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M8.5 7.66667L5.16667 4.33333L8.5 1M4.33333 7.66667L1 4.33333L4.33333 1"
                                stroke="#0077FF"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                            <path
                                d="M5.1665 4.33331H10.9998C11.8839 4.33331 12.7317 4.6845 13.3569 5.30962C13.982 5.93474 14.3332 6.78259 14.3332 7.66665C14.3332 8.5507 13.982 9.39855 13.3569 10.0237C12.7317 10.6488 11.8839 11 10.9998 11H10.1665"
                                stroke="#0077FF"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                        </svg>
                        <Typography variant="h5" color={"primary"}>
                            Back
                        </Typography>

                    </Stack>
                </Stack>
                <DetailsButtons />

            </Stack>
            <Box
                sx={{
                    backgroundColor: '#fff',
                    transition: 'all .5s ease-in-out',
                    position: 'relative',
                    border: '1px solid rgba(170, 170, 170, 0.3)',
                    borderRadius: '12px',
                    boxShadow: '0px 5px 5px 0px rgba(82, 63, 105, 0.05)',
                    overflow: 'hidden',
                }}
            >



                <TableContainer  >
                    <Table
                        sx={{ maxHeight: 440 }}
                        aria-labelledby="tableTitle"
                    //size={dense ? "small" : "medium"}
                    >
                        <EnhancedTableHead
                            // numSelected={selected.length}
                            state={state}
                            setTotal={setTotal}
                            setIds={setIds}
                            listing={campaignInfluencersList}
                            ids={ids}
                            order={"asc"}
                            orderBy={sort_key}
                            //onSelectAllClick={handleSelectAllClick}
                            onRequestSort={() => { }}
                            //rowCount={rows.length}
                            numSelected={0}
                            onSelectAllClick={function (

                            ) {
                                throw new Error("Function not implemented.");
                            }}
                        //order={"desc"}
                        />

                        <TableBody>
                            {campaignInfluencersList && campaignInfluencersList?.length > 0 ? (
                                campaignInfluencersList?.map((row, index) => {
                                    return (
                                        row?.id && <TableRow
                                            hover
                                            role="checkbox"
                                            tabIndex={-1}
                                            key={row?.id}
                                            className={"tableRow"}
                                            style={{ userSelect: "text" }}
                                        >
                                            {getUserType() == COMPANY &&
                                                <TableCell>
                                                    <Checkbox
                                                        // disabled={rowsSelected.includes(row.id)}
                                                        checked={ids.includes(row.influencer.id)}
                                                        onChange={(e) => {
                                                            if (e.target.checked) {
                                                                setTotal((prev) => prev + row.amount)
                                                                setIds((prev) => ([...prev, row.influencer.id]))
                                                            } else {
                                                                setTotal((prev) => prev - row.amount)
                                                                setIds(ids.filter((v) => v !== row.influencer.id))
                                                            }
                                                        }}
                                                        // sx={{ cursor: rowsSelected.includes(row.id) ? 'not-allowed' : "" }}
                                                        inputProps={{ 'aria-label': 'controlled' }}
                                                    />

                                                </TableCell>}
                                            <TableCell component="th" scope="row" padding="none">
                                                {row?.influencer?.name || "N/A"}

                                            </TableCell>
                                            {row.type === "paid" &&
                                                <TableCell component="th" scope="row" padding="none">
                                                    ₹{formatPrice(row?.amount)}
                                                </TableCell>
                                            }
                                            <TableCell component="th" scope="row" padding="none">
                                                {row?.platforms?.map((v) => parseInt(v?.followers))?.filter((v) => v)?.length > 0 ? getFixedValue(row?.platforms?.map((v) => parseInt(v?.followers))?.filter((v) => v)?.reduce((a, b) => Math?.max(a, b))) : "N/A"}
                                            </TableCell>
                                            <TableCell align="left">
                                                {row?.influencer?.city || row?.influencer?.state
                                                    ? `${row?.influencer?.city || ''}${row?.influencer?.city && row?.influencer?.state ? ', ' : ''}${row?.influencer?.state || ''}`.trim() || "N/A"
                                                    : "N/A"}

                                            </TableCell>

                                        </TableRow>
                                    );
                                })
                            ) : (
                                <TableRow colSpan={10}>
                                    <TableCell colSpan={10} style={{ textAlign: 'center' }}>

                                        <Typography
                                            color="inherit"
                                            variant="subtitle1"
                                            alignItems={"center"}
                                            component="h3"
                                        >
                                            No Data found!
                                        </Typography>

                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>

                </TableContainer>

                <hr />
                <Stack
                    sx={{
                        background: 'transparent',
                    }}
                    direction="row"
                    alignItems={"center"}
                    justifyContent={"flex-end"}
                >
                    <Box className="pagination-cover d-flex align-items-center justify-content-between py-4 px-4 w-100" gap={1.5}>
                        <Box className="d-flex align-items-center rowPerPage" gap={0}>
                            <span style={{ fontSize: '13px', color: 'rgba(0, 0, 0, 0.7)', fontWeight: '500' }}>Rows per page:</span>
                            <PaginationSelect handleChange={handleChangePerPage} page={limit} />
                        </Box>
                        <Box className="d-flex align-items-center pageSteps" gap={1}>
                            <Typography variant="subtitle2">
                                {campaignInfluencersList && campaignInfluencersList.length > 0
                                    ? page === 0
                                        ? `0 - ${campaignInfluencersList.length}`
                                        : `${limit * (page - 0) + 1}  - ${limit * (page - 0) + campaignInfluencersList.length
                                        } `
                                    : "0 - 0"}{" "}
                                &nbsp;of {totalCount} items
                            </Typography>
                            <Pagination
                                count={Math.ceil(totalPages / limit) || 0}
                                variant="outlined"
                                onChange={handleChange}
                            />
                        </Box>
                    </Box>

                </Stack>

                <Box sx={{
                    position: 'fixed',
                    bottom: 0,
                    left: "auto",
                    width: "calc(100% - 280px)",
                    right: 0,
                    backgroundColor: '#fff',
                    padding: 2,
                    pl: 2,
                    pr: 4,
                    borderTop: '1px solid #e0e0e0',

                }}>
                    {ids.length > 0 && campaignInfluencersList.length > 0 && campaignInfluencersList[0].type !== "barter" && campaignInfluencersList[0]?.campaignStatus == LIVE &&
                        <> <Grid container spacing={2} p={1} alignItems="center">
                            <Grid item md={8} lg={8} sm={12} xs={12} >
                                <Typography variant="body1">
                                    {ids.length} negotiated influencer(s) estimates ₹{formatPrice(total)}, move them to Approved Influencer(s) to start campaign with them.
                                </Typography>
                                <Box sx={{ marginTop: 1 }}>
                                    <Typography variant="body2" sx={{ marginBottom: 0.5 }}>
                                        • Total balance available in account : ₹{campaignInfluencersList.length > 0 ? formatPrice(campaignInfluencersList[0]['availableBalance']) : 0}
                                    </Typography>
                                    <Typography variant="body2">
                                        • Balance need to be added in wallet : ₹{campaignInfluencersList.length > 0 ? parseFloat(total) > parseFloat(campaignInfluencersList[0]['availableBalance']) ? formatPrice(parseFloat(total) - parseFloat(campaignInfluencersList[0]['availableBalance'])) : 0 : 0}
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item md={4} lg={4} sm={12} xs={12} textAlign="right">
                                <Typography variant="body2" color="textSecondary">
                                    Selected Final Amount
                                </Typography>
                                <Typography variant="h6" color="primary">
                                    ₹{formatPrice(total)}
                                </Typography>
                            </Grid>
                        </Grid>
                            {ids.length > 0 && campaignInfluencersList.length > 0 && campaignInfluencersList[0].type === "paid" &&
                                <Box sx={{ textAlign: 'right', marginTop: 2 }}>
                                    <button className="btnPrimary" onClick={() => {
                                        dispatch(moveToApprovedInfluencers({ influencerIds: ids, campaignId: id, companyId: getUserInfo(), totalAmount: total }, navigate, setOpen, (campaignInfluencersList.length > 0 ? campaignInfluencersList[0].type : "")))
                                        setIds([]);
                                    }}>Move to Approved Influencers</button>

                                </Box>
                            }
                        </>
                    }

                    {ids.length > 0 && campaignInfluencersList.length > 0 && campaignInfluencersList[0].type !== "paid" && <Grid container spacing={2} p={1} alignItems="center">
                        <Grid item md={8} lg={8} sm={12} xs={12} >
                            <Typography variant="body1">
                                {ids.length} influencers selected, move them to Approved Influencer(s) to start campaign with them.
                            </Typography>
                        </Grid>
                        <Grid item md={4} lg={4} sm={12} xs={12} textAlign="right">
                            <button className="btnPrimary" onClick={() => {
                                dispatch(moveToApprovedInfluencers({ influencerIds: ids, campaignId: id, companyId: getUserInfo(), totalAmount: 0 }, navigate, setOpen, (campaignInfluencersList.length > 0 ? campaignInfluencersList[0].type : "")))
                                setIds([]);
                            }}>Move to Approved Influencers</button>
                        </Grid>
                    </Grid>
                    }


                </Box>
                {/* {ids.length > 0 && campaignInfluencersList.length > 0 && campaignInfluencersList[0].type !== "paid" && <Box sx={{
                    position: 'fixed',
                    bottom: 0,

                    left: "auto",
                    width: "calc(100% - 280px)",
                    right: 0,
                    backgroundColor: '#fff',
                    padding: 2,
                    pl: 2,
                    pr: 4,
                    borderTop: '1px solid #e0e0e0',

                }}>
                    <Grid container spacing={2} p={1} alignItems="center">
                        <Grid item md={8} lg={8} sm={12} xs={12} >
                            <Typography variant="body1">
                                {ids.length} influencers selected, move them to Approved Influencer(s) to start campaign with them.
                            </Typography>
                        </Grid>
                        <Grid item md={4} lg={4} sm={12} xs={12} textAlign="right">
                            <button className="btnPrimary" onClick={() => {
                                dispatch(moveToApprovedInfluencers({ influencerIds: ids, campaignId: id, companyId: getUserInfo(), totalAmount: 0 }, navigate, setOpen))
                                setIds([]);
                            }}>Move to Approved Influencers</button>
                        </Grid>
                    </Grid>
                </Box>} */}


            </Box>

            <ApprovedModel />

            <Invite setInviteModal={setInviteModal} inviteModal={inviteModal} />

            <BiddingDetails setOpenmodel={setOpenmodel} openModal={openModal} />
            {/* <InfluencerBiddingDetails setOpenmodel={setOpenmodel} openModal={openModal} /> */}

        </>
    );
}
