import { Box, Card, CardContent, Grid, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import CommonModel from '../../../components/dialog/Common';
import { dateForamt, formatPrice, getCompanyName, getCostPrice, getUserType } from '../../../utils/common';
import { ADMIN, CAMPAIGN_TYPES, RUPEES_SYMBOL } from '../../../utils/constant';
import { getBrandLogoById, updateCampaignStatus } from '../action';
import { updateCampaign } from "../campaignSlice";
const Preview = () => {
    const [openNew, setOpennew] = useState(false);
    const { id } = useParams()
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { formData } = useSelector((state) => state.campaignSlice);
    useEffect(() => {
        dispatch(getBrandLogoById(formData.campaignId));
        window.onbeforeunload = () => true;

        return () => {
            window.onbeforeunload = null;
        };

    }, [])



    return <>

        <Box
            p={3}
            sx={{
                backgroundColor: '#fff',
                transition: 'all .5s ease-in-out',
                position: 'relative',
                border: '1px solid rgba(170, 170, 170, 0.3)',
                borderRadius: '12px',
                boxShadow: '0px 5px 5px 0px rgba(82, 63, 105, 0.05)',
                overflow: 'hidden',
            }}
        >

            <Grid container >
                <div className='w-100'>
                    <div className='row flex-xxl-row flex-xl-row flex-lg-column-reverse flex-md-column-reverse flex-sm-column-reverse flex-column-reverse'>
                        <div className='col-xxl-7 col-xl-7 col-lg-12 col-md-12 col-sm-12 col-12'>
                            <div className='d-flex flex-wrap gap-4 mb-4'>
                                <img src={formData?.brandLogo || formData?.brand?.brandImage} alt="Brand logo" style={{ width: "auto", height: "100px", borderRadius: "10px", border: '1px solid rgba(170, 170, 170, 0.3)' }} />
                                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                    <Typography variant="subtitle1" component="p" gutterBottom>
                                        <span className="label ">Campaign Name : </span>  <span className=" text-capitalize">{formData?.name}</span>
                                    </Typography>
                                    <Typography variant="subtitle1" component="p" gutterBottom>
                                        <span className="label">Brand Name : </span>   {formData?.brandName || "-"}
                                    </Typography>
                                    <Typography variant="subtitle1" component="p">
                                        <span className="label">Followers: </span> 50K, 100K, 300K, 1M
                                    </Typography>
                                </Box>
                            </div>
                            <hr />
                            <div className='campDetailPrev mt-4'>
                                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                    <Typography className='mb-0' component="p" gutterBottom>
                                        {formData?.brief && <span dangerouslySetInnerHTML={{ __html: formData?.brief }} ></span>}
                                    </Typography>
                                    <br />
                                    <hr />
                                    <br />
                                    <Typography variant="subtitle1" gutterBottom>
                                        <span className="label">Campaign Type: </span>  <span className="capitalize-first-letter">{formData?.type || "N/A"}</span>
                                    </Typography>
                                    {
                                        getUserType() == ADMIN &&
                                        <Typography variant="subtitle1" gutterBottom>
                                            <span className="label">Goal of the Campaign:</span> <span style={{ textTransform: "capitalize" }}>{formData?.demand || ""}</span>
                                        </Typography>
                                    }

                                    <Typography variant="subtitle1" gutterBottom>
                                        <span className="label">Company Name: </span>  <span>{formData?.brand?.companyName || formData?.companyName || getCompanyName() || ""}</span>
                                    </Typography>


                                    {formData.type !== CAMPAIGN_TYPES.AFFILIATE &&
                                        <Typography className='d-flex align-items-center' variant="subtitle1" gutterBottom>
                                            <span className="label">Platform: </span>
                                            <span>
                                                <Stack direction="row" spacing={1}>
                                                    {formData?.platform}{" "}({formData?.selectedDeliverables?.map((v, i) => {
                                                        return v?.post + "-" + v?.qty + (!(i === formData?.selectedDeliverables?.length - 1) ? " , " : "")
                                                    })})
                                                </Stack >
                                            </span>
                                        </Typography>
                                    }
                                    {[CAMPAIGN_TYPES.AFFILIATE, CAMPAIGN_TYPES.PERFORMANCE].includes(formData.type) && <>
                                        <Typography variant="subtitle1" gutterBottom>

                                            <span className="label">Measure by KPI's  per <br></br> creator : </span>  <span>{formData?.kpiPerCreator == 1 ? "Yes" : "No"}</span>

                                        </Typography>
                                        {formData?.kpiPerCreator &&
                                            <Typography variant="subtitle1" gutterBottom>
                                                <span className="label">Tracking Expiry date: </span>  <span>{dateForamt(formData?.endDate)}</span>
                                            </Typography>}
                                        <Typography variant="subtitle1" gutterBottom>
                                            <span className="label"> Apply by date: </span>  <span>{formData?.applyBydate ? dateForamt(formData?.applyBydate) : "N/A"}</span>
                                        </Typography>
                                        {[CAMPAIGN_TYPES.PERFORMANCE].includes(formData.type) ?
                                            <Typography variant="subtitle1" gutterBottom>
                                                <span className="label"> KPI: </span>  <span>{formData?.costPer} - {RUPEES_SYMBOL + formatPrice(getCostPrice(formData.costPer, formData))}</span>
                                            </Typography> : <Typography variant="subtitle1" gutterBottom>
                                                <span className="label">Event Program 1: </span>  <span>{RUPEES_SYMBOL + formatPrice(formData?.eventProgramPrice)}</span>
                                            </Typography>}
                                    </>}

                                    <Typography variant="subtitle1" gutterBottom>
                                        <span className="label">Products: </span> <span >{formData?.products?.length > 0 ? formData?.products?.map((v, index) => {
                                            return <>{v.name}{"  "}(<a style={{ wordBreak: "break-word", fontSize: '13px', lineHeight: 'normal', display: 'inline-block' }} href={v?.link} target='_blank' rel="noreferrer" >{v.link}</a>){!(index === formData?.products?.length - 1) && " , "}</>
                                        }) : "N/A"}</span>
                                    </Typography>

                                    <Typography variant="subtitle1" gutterBottom>
                                        <span className="label">Start Date: </span> <span>{dateForamt(formData?.startDate) || 'N/A'}</span>
                                    </Typography>
                                    <Typography variant="subtitle1" gutterBottom>
                                        <span className="label">End Date: </span> <span>{dateForamt(formData?.endDate) || 'N/A'}</span>
                                    </Typography>
                                    <Typography variant="subtitle1" gutterBottom>
                                        <span className="label">Apply Date: </span> <span>{formData?.applyBydate ? dateForamt(formData?.applyBydate) : 'N/A'}</span>
                                    </Typography>
                                    {![CAMPAIGN_TYPES.AFFILIATE].includes(formData.type) && <>
                                        <Typography variant="subtitle1" gutterBottom>
                                            <span className="label">Script Date: </span> <span>{dateForamt(formData?.conceptScriptDate) || ''}</span>
                                        </Typography>
                                        <Typography variant="subtitle1" gutterBottom>
                                            <span className="label">Content Date: </span> <span>{dateForamt(formData?.contentSubmissionDate) || ''}</span>
                                        </Typography>
                                        <Typography variant="subtitle1" gutterBottom>
                                            <span className="label">Publish Date: </span> <span>{dateForamt(formData?.contentPublishDate) || ''}</span>
                                        </Typography>
                                    </>}
                                    {
                                        formData.type !== CAMPAIGN_TYPES.BARTER && <>
                                            <Typography variant="subtitle1" gutterBottom>
                                                <span className="label">Budget: </span> <span>{RUPEES_SYMBOL + formatPrice(formData?.budget) || "N/A"}</span>
                                            </Typography></>
                                    }
                                    <Typography variant="subtitle1" gutterBottom>
                                        <span className="label">Budget per influencer: </span> <span>{RUPEES_SYMBOL + formatPrice(formData?.budgetPerInfluencer) || "N/A"}</span>
                                    </Typography>
                                    <Typography variant="subtitle1" gutterBottom>
                                        <span className="label">No. of Creators: </span> <span>{formData?.noOfCreators || "N/A"}</span>
                                    </Typography>
                                    <Typography variant="subtitle1" gutterBottom>
                                        <span className="label">Account Mentions
                                        </span>   <span>{formData?.accountMentions || "N/A"}</span>
                                    </Typography>
                                    <Typography variant="subtitle1" gutterBottom>
                                        <span className="label">Campaign Hashtag
                                        </span>   <span>{formData?.campaignHashtag || "N/A"}</span>
                                    </Typography>
                                    <Typography variant="subtitle1" gutterBottom>
                                        <span className="label"> Reference Content
                                        </span>
                                        {formData?.referenceContent ? <span dangerouslySetInnerHTML={{ __html: formData?.referenceContent }}></span> : "N/A"}
                                    </Typography>

                                    <Typography variant="subtitle1" gutterBottom>
                                        <span className="label">Contact Details: </span> <span>{formData?.contactDetails || 'N/A'}</span>
                                    </Typography>

                                    <Typography variant="subtitle1" gutterBottom>
                                        <span className="label">Influencer Type: </span> <span>{formData?.influncerType?.toString() || formData?.creator?.influncerType?.toString() || "Any"}</span>
                                    </Typography>

                                    <Typography variant="subtitle1" gutterBottom>
                                        <span className="label">Gender: </span> <span>{formData?.gender || formData?.creator?.createrGender || 'Any'}</span>
                                    </Typography>
                                    <Typography variant="subtitle1" gutterBottom>
                                        <span className="label">Location: </span> <span>{formData?.creatorLocation?.toString() || formData?.creator?.creatorLocation?.toString() || "Any"}</span>
                                    </Typography>
                                    <Typography variant="subtitle1" gutterBottom>
                                        <span className="label">Categories: </span> <span>{formData?.creator?.selectedCategories?.toString() || formData?.selectedCategories?.toString() || "Any"}</span>
                                    </Typography>
                                </Box >
                            </div >
                        </div >
                        <div className='col-xxl-5 col-xl-5 col-lg-12 col-md-12 col-sm-12 col-12'>
                            <div className='mb-xxl-0 mb-xl-0 mb-lg-5 mb-md-5 mb-sm-5 mb-5 ps-xxl-4 ps-xl-4 ps-lg-0 ps-md-0 ps-sm-0 ps-0'>
                                <img alt="logoimage" src={id !== undefined ? formData?.bannerImage?.toString() : typeof formData.bannerImage === "object" ? URL.createObjectURL(formData?.bannerImage) : formData?.bannerImage} style={{ width: '100%', height: "auto", borderRadius: "16px" }} />
                            </div>
                            {formData.type === CAMPAIGN_TYPES.PAID &&
                                <Card sx={{ maxWidth: 500, padding: 2, borderRadius: 2, bgcolor: "#F7F7F7", ml: 4, mt: 4 }}>
                                    <CardContent>
                                        <Typography variant="h6" gutterBottom>
                                            Milestone Payments Details
                                        </Typography>
                                        <Grid container mt={1} spacing={2}>
                                            <Grid item xs={8}>
                                                <Typography variant="body1">Advance Payment</Typography>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <Typography variant="body1" align="right">{formData?.advancePayment || "0"}%</Typography>
                                            </Grid>

                                            <Grid item xs={8}>
                                                <Typography variant="body1">Script Approval</Typography>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <Typography variant="body1" align="right">{formData?.scriptApproval || "0"}%</Typography>
                                            </Grid>

                                            <Grid item xs={8}>
                                                <Typography variant="body1">Content Approval</Typography>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <Typography variant="body1" align="right">{formData?.contentApproval || "0"}%</Typography>
                                            </Grid>

                                            <Grid item xs={8}>
                                                <Typography variant="body1">Publish Link Approval</Typography>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <Typography variant="body1" align="right">{formData?.linkApproval || "0"}%</Typography>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            }
                        </div>
                    </div >
                </div >



                {
                    !id &&
                    <>
                        <hr />
                        <Grid item xs={12} lg={12} md={12} sm={12} p={3} container justifyContent="flex-end">
                            <Stack direction={"row"} spacing={1}>
                                <button
                                    className='btnSecondary'
                                    onClick={() => {
                                        dispatch(updateCampaign({ step: 6 }));
                                        navigate("/campaign/profile-screener")
                                    }}
                                >
                                    Back
                                </button>
                                <button
                                    className='btnPrimary'
                                    onClick={() => {
                                        let postData = { campaignId: String(formData.campaignId), status: "PENDING" };
                                        dispatch(updateCampaignStatus(postData));
                                        // dispatch(updateCommonData({
                                        //     message: formData?.mode === "edit" ? "Campaign successfully updated" : "Campaign successfully created",
                                        //     title: "Success",
                                        //     img: "/success.png",
                                        //     status: true
                                        // }));
                                        navigate("/campaign/submit-review")
                                        //setOpennew(true);
                                        //navigate("/campaign/submit-review")
                                    }}
                                >
                                    Create Campaign
                                </button>
                            </Stack>

                        </Grid>
                    </>
                }


            </Grid >
            <CommonModel
                open={openNew}
                handleDialogClose={() => setOpennew(false)}
                dialogTitle={"Create Campaign"}
                dialogPara={`Are you sure you want to create this campaign? `}
                nvYes={"Submit"}
                nvCancel={"Cancel"}
                yesHandle={() => {
                    dispatch(updateCampaignStatus({ campaignId: formData?.id, status: "PENDING" }));
                    setOpennew(false);
                    navigate("/campaign/submit-review")
                }}
            />
        </Box >

    </>
}
export default Preview;