import {
  FormControlLabel,
  MenuItem,
  Select,
  Stack,
  Switch,
  Typography
} from "@mui/material";
import Box from "@mui/material/Box";
import React from "react";
import CustomTable from "../components/customTable";
import { ConfirmationModal } from "../components/modal";

import { useNavigate } from "react-router-dom";

import { useSelector } from "react-redux";
import CustomizedSnackbars from "../components/snackbar";
import { useGetAdminUsersQuery, useGetRolesQuery, useUpdateAdminMutation } from "../redux/api";
import { ROUTES } from "../utils/routes";



const AdminUser = () => {
  const navigate = useNavigate();
  const states = useSelector((state) => state.app);
  const [pagination, setPagination] = React.useState({ rows: 10, page: 0 });

  const [deleteModalState, setDeleteModalState] = React.useState({
    open: false,
    id: null,
    message: null,
  });
  const [row, setRow] = React.useState({})
  const [count, setCount] = React.useState(0);
  const [updateAdmin] = useUpdateAdminMutation();
  const [snackbar, setSnackbar] = React.useState({
    message: "",
    severity: "success",
    open: false,
  });

  const handlePageChange = (data) => {
    setPagination(data);
  };

  const Action = (row) => {

    let { id, isActive, roleId } = row

    isActive = isActive == 1 ? false : true
    let data = {
      id,
      isActive,
      roleId
    }
    const handleIsActive = (row) => {
      let message, severity
      updateAdmin(data).then((res) => {
        if (res.error) {
          message = res.error.data.message
          severity = "error"
        } else {
          message = "Success"
          severity = "success"
        }
        setSnackbar({
          open: true,
          message,
          severity
        });
      });
    };



    return (
      <>
        <CustomizedSnackbars msgData={snackbar} setMsgData={setSnackbar} />
        <Stack direction={"row"} sx={{ alignItems: 'center' }} gap={1}>
          {states?.permissions?.adminUsers?.edit === 1 &&
            <div className="actBtnGrps">
              <span className="editIcon" onClick={() => navigate(`${ROUTES.ADMIN_USERS}${ROUTES.EDIT}/${id}`)}>Edit</span>
            </div>
          }
          {/* <Edit className="editIcon" size="small" onClick={() =>
                navigate(`${ROUTES.ADMIN_USERS}${ROUTES.EDIT}/${id}`)
              }
          /> */}

          <FormControlLabel
            className="toogleBtn"
            sx={{
              display: "block",
            }}
            control={
              <Switch
                checked={!isActive}
                onClick={() => {
                  setDeleteModalState({
                    id,
                    open: true,
                    message: `Are you sure you want to ${!isActive ? "Inactive" : "active"} this user ?`, //${row.name}
                  });
                  setRow({
                    id, isActive, roleId
                  })
                }}
                color="success"
              />
            }
          />
        </Stack>
      </>
    );
  };


  const handleDeleteInfluencer = (id) => {
    updateAdmin(row).then((res) => {
      setDeleteModalState({ ...deleteModalState, open: false });
      setSnackbar({
        open: true,
        message: res.data.message,
        severity: res.status == "FAILURE" ? "error" : "success",
      });
    });
  };

  const [filter, setFilter] = React.useState({});
  const filterOptions = {
    roles: ["Super Admin", "Sub Admin"],
  };

  const handleChange = (e) => {

    if (e.target.value == "All") {
      setFilter({});
    } else {
      setFilter({ ...filter, [e.target.name]: e.target.value });
    }
  };

  const [adminData, setAdminData] = React.useState([]);

  const { isSuccess, data } = useGetAdminUsersQuery({
    offset: pagination.page * pagination.rows,
    limit: pagination.rows,
    filter: JSON.stringify(filter),
  });

  const { data: rolesData, isLoading: roleLoading } = useGetRolesQuery();
  const [roleDetails, setRoleDetails] = React.useState(data?.result.data || []);


  React.useEffect(() => {
    if (isSuccess && data && data.status === "SUCCESS") {
      setAdminData(data.result.data);
      setCount(data?.result.count);
    }
  }, [isSuccess, data]);

  React.useEffect(() => {
    if (rolesData && rolesData.status === "SUCCESS") {
      const userData = rolesData?.result.data;
      setRoleDetails(userData)
    }
  }, [roleLoading, data])

  const cols = ["name", "username", "role", "last_activity"];
  const tableData = [];

  if (adminData.length > 0) {
    for (const key of adminData) {
      key.email && tableData.push({
        name: key.adminName,
        id: key.id,
        username: key.email,
        role: key.roleName,
        last_activity: key.updatedDate,
        isActive: key.isActive,
        roleId: key.roleId
      });
    }
  }

  const [open, setOpen] = React.useState(false);
  const approveFn = (data) => {
    console.log(data);
  };
  return (
    <Stack direction={"column"} gap={2}>
      <Stack
        className="mainTitle flex-wrap"
        direction={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
        sx={{
          minHeight: '42px',
        }}
      >
        <Typography className="page-title" variant="h1">Admin User</Typography>
        <button className="btnPrimary" disabled={!states?.permissions?.adminUsers?.create}
          onClick={() => navigate(`${ROUTES.ADMIN_USERS}${ROUTES.ADD}`)}
        >
          Add User
        </button>

      </Stack>
      <Box
        sx={{
          backgroundColor: '#fff',
          transition: 'all .5s ease-in-out',
          position: 'relative',
          border: '1px solid rgba(170, 170, 170, 0.3)',
          borderRadius: '12px',
          boxShadow: '0px 5px 5px 0px rgba(82, 63, 105, 0.05)',
          overflow: 'hidden',
        }}>
        <div className="myCmpny d-flex flex-wrap align-items-center justify-content-xxl-end justify-content-xl-end justify-content-lg-end justify-content-md-end justify-content-sm-start justify-content-start searcFilter p-3">

          <div className='allFilter adminUser'>
            <Select
              sx={{ minWidth: '120px' }}
              value={filter.roleId}
              name="roleId"
              onChange={handleChange}
              displayEmpty
              defaultValue={""}
            >
              <MenuItem value="" disabled hidden>
                Roles
              </MenuItem>
              <MenuItem key="All" value="All" >
                All
              </MenuItem>
              {roleDetails &&
                roleDetails?.length > 0 && roleDetails.map(
                  (role) =>
                    role && (
                      <MenuItem key={role.roleName} value={role.roleId}>
                        {role.roleName}
                        {roleDetails[role.roleName]}
                      </MenuItem>
                    )
                )}
            </Select>
          </div>
        </div>

        <ConfirmationModal
          open={deleteModalState.open}
          onClose={() =>
            setDeleteModalState({ ...deleteModalState, open: false })
          }
          message={deleteModalState.message}
          onConfirm={() => handleDeleteInfluencer(deleteModalState.id)}
        />
        <CustomTable
          onPageChange={handlePageChange}
          cols={cols}
          count={count}
          data={tableData}
          isCheckbox={false}
          actionComp={Action}
        />
      </Box>
    </Stack>
  );
};

export default AdminUser;
