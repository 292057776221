import React from "react";
import Box from "@mui/material/Box";
import { OutlinedInput, Stack, Typography } from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";
import { useGetRoleByIdQuery } from "../redux/api";
import {
  FormLabel
} from "@mui/material";

const RoleView = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { data, isSuccess } = useGetRoleByIdQuery(id);
  const [formData, setFormData] = React.useState({
    roleName: "",
    description: "",
    permissions: {},
  });

  React.useEffect(() => {
    if (data?.status == "SUCCESS") {
      setFormData({
        roleName: data.result[0].roleName,
        description: data.result[0].description,
        permissions: data.result[0].permissions,
      });
    }
  }, [isSuccess]);
  return (
    <>
      <Stack direction="row" alignItems="center" spacing={3} mb={2}>
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
          sx={{
            minHeight: '42px'
          }}
        >
          <Typography variant="h1" className="page-title">
            View Role
          </Typography>
          <Stack
          className="backText"
          direction="row"
          gap={1}
          alignItems="center"
          sx={{ cursor: "pointer" }}
          onClick={() => navigate(-1)}
        >
          <svg
            width="16"
            height="12"
            viewBox="0 0 16 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8.5 7.66667L5.16667 4.33333L8.5 1M4.33333 7.66667L1 4.33333L4.33333 1"
              stroke="#0077FF"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M5.1665 4.33331H10.9998C11.8839 4.33331 12.7317 4.6845 13.3569 5.30962C13.982 5.93474 14.3332 6.78259 14.3332 7.66665C14.3332 8.5507 13.982 9.39855 13.3569 10.0237C12.7317 10.6488 11.8839 11 10.9998 11H10.1665"
              stroke="#0077FF"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <Typography variant="h5" color="primary">
            Back
          </Typography>
        </Stack>
        </Stack>
      </Stack>
      <Box
        sx={{
          backgroundColor: '#fff',
          transition: 'all .5s ease-in-out',
          position: 'relative',
          border: '1px solid rgba(170, 170, 170, 0.3)',
          borderRadius: '12px',
          boxShadow: '0px 5px 5px 0px rgba(82, 63, 105, 0.05)',
          overflow: 'hidden',
        }}
      >
          <div className="p-4">
            <div className="row flex-column gap-3">
              <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
                  <FormLabel required>Role Name</FormLabel>
                  <OutlinedInput
                    className="w-100"
                    type="text"
                    name="roleName"
                    value={formData.roleName}
                    readOnly
                  />
              </div>
              <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
                  <FormLabel required>Role Description</FormLabel>
                  <OutlinedInput
                    className="w-100"
                    multiline={true}
                    style={{ padding: '0px', minHeight: '70px' }}
                    type="text"
                    name="roleName"
                    value={formData.description}
                    readOnly
                  />
              </div>
            </div>
          </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography variant="h4" className="subtitle2 px-4 py-4" style={{background:'#f5f5f5'}}>
              Assign Rights
            </Typography>
            <div className="scrollRoles">
              <div className="assRight">
                {Object.entries(formData.permissions).map(([title, rights]) => (
                  <RightsSection key={title} title={title} rights={rights} />
                ))}
              </div>  
            </div>
        </div>
      </Box>
    </>
  );
};

const RightsSection = ({ title, rights }) => {
  return (
    <>
      <div style={{ display: "flex", alignItems: "center" }}>
        <Typography variant="body1" style={{ minWidth: "200px" }}>
          {title.capitalize()}
        </Typography>
        <div style={{ display: "flex", gap: "30px" }}>
          {Object.entries(rights).map(([right, value]) => (
            <div key={right} className="form-check">
              <label className="form-check-label">{right.capitalize()}</label>
              <input
                className="form-check-input"
                type="checkbox"
                checked={value === 1}
                readOnly
              />
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default RoleView;
