import { Box, Checkbox, Pagination, Stack, TableFooter, TablePagination, Typography } from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Tooltip from '@mui/material/Tooltip';
import * as React from "react";
import PaginationSelect from "./select/pagination";

export default function CustomTable({
  cols,
  onPageChange,
  data,
  isCheckbox = false,
  actionComp,
  count,
  handleCheckbox,
  checkboxData = [],
  removeAllChecked,
}) {

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [openTooltipIndexes, setOpenTooltipIndexes] = React.useState([]);
  const tooltipRefs = React.useRef([]);
  const tableRef = React.useRef();
  const handleTooltipToggle = (cellIndex) => {
    setOpenTooltipIndexes(openTooltipIndexes.includes(cellIndex) ? [] : [cellIndex]);
  };

  const handleDocumentClick = (event) => {
    const clickedOutsideAllTooltips = tooltipRefs.current.every(ref => ref && !ref.contains(event.target));
    if (clickedOutsideAllTooltips) {
      setOpenTooltipIndexes([]);
    }
  };

  React.useEffect(() => {
    document.addEventListener('click', handleDocumentClick);

    return () => {
      document.removeEventListener('click', handleDocumentClick);
    };
  }, []);


  const handleChangePage = (event, newPage) => {
    setPage((page) => {
      onPageChange({ rows: rowsPerPage, page: newPage - 1 });
      return newPage;
    });
  };


  React.useEffect(() => {
    document.addEventListener('click', handleDocumentClick);

    return () => {
      document.removeEventListener('click', handleDocumentClick);
    };
  }, []);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage((page) => {
      onPageChange({ rows: event.target.value, page: 0 });
      return event.target.value;
    });
    setPage(0);
  };
  const rows = data.map((item) => {
    return [
      // first element of the array is to show data in the table rows
      cols.map((data) => item[data]),
      // second element is for passing entire object which we get from get API i.e. data in the props
      { ...item },
    ];
  });


  React.useEffect(() => {
    const tableContainer = tableRef.current;
    const handleScroll = () => {
      if (tableContainer && false) {
        const { scrollTop, clientHeight, scrollHeight } = tableContainer;
        if (Math.ceil(scrollTop + clientHeight) >= scrollHeight - 1) {
          setRowsPerPage((page) => {
            onPageChange({ rows: page + 10, page: 0 });
            return page + 10;
          });

        }
      }
    };
    tableContainer.addEventListener('scroll', handleScroll);
    return () => {
      tableContainer.removeEventListener('scroll', handleScroll);
    };
  });

  return (
    <Box>
      <TableContainer component={Paper} sx={{ boxShadow: "0", maxHeight: 440 }} stickyHeader ref={tableRef}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {isCheckbox && data?.filter((v) => v.status === "PENDING")?.length > 0 && false && (
                <TableCell padding={"checkbox"}>
                  <Checkbox
                    color="primary"
                    checked={checkboxData.length > 0}
                    onChange={removeAllChecked}
                    inputProps={{
                      "aria-label": "select all",
                    }}
                  ></Checkbox>
                </TableCell>
              )}
              {cols.map((column) => (
                <TableCell
                  align="left"
                  key={column}

                >
                  {column?.split("_")?.join(" ")?.capitalize()}
                </TableCell>
              ))}
              {actionComp && (
                <TableCell
                  align="left"
                >
                  Action
                </TableCell>
              )}
            </TableRow>
          </TableHead>

          <TableBody>
            {rows?.length > 0 ? rows?.map((row, rowIndex) => (
              <TableRow key={rowIndex}>
                {/* {isCheckbox && data?.filter((v) => v.status === "PENDING")?.length > 0 && (row[1]?.status == "PENDING" && false ? (
                  <TableCell padding="checkbox" align="left">
                    <Checkbox
                      color="primary"
                      checked={checkboxData.includes(row[1]?.id)}
                      onChange={() => handleCheckbox(row[1]?.id)}
                    />
                  </TableCell>
                ) : (
                  // <TableCell />
                ))} */}
                {row[0].map((item, cellIndex) => {
                  const cellTooltipIndex = rowIndex * row[0].length + cellIndex;
                  const isOpen = openTooltipIndexes.includes(cellTooltipIndex);
                  return (
                    <TableCell align="left" key={cellIndex}>
                      {item != null && item.length > 20 ? (
                        <div >
                          <Tooltip
                            ref={ref => tooltipRefs.current[cellTooltipIndex] = ref}
                            title={item}
                            PopperProps={{
                              disablePortal: true,
                            }}
                            arrow={true}
                            open={isOpen}
                            disableFocusListener
                            disableTouchListener
                            disableInteractive
                          >
                            <span
                              className="showhandCursor"
                              // onMouseEnter={() => handleTooltipToggle(cellTooltipIndex)}
                              // onMouseLeave={() => setOpenTooltipIndexes([])}
                              onClick={() => handleTooltipToggle(cellTooltipIndex)}
                            >
                              {item.slice(0, 20)}
                            </span>
                          </Tooltip>
                          {!isOpen && <Tooltip title={item} arrow={true}>
                            <span className="showhandCursor">...</span>
                          </Tooltip>}
                        </div>
                      ) : (
                        <span>{item}</span>
                      )}
                    </TableCell>
                  );
                })}
                {actionComp && (
                  <TableCell align="left" sx={{ minWidth: 150 }} width={150}>
                    {actionComp(row[1])}
                  </TableCell>
                )}
              </TableRow>
            )) : (
              <TableRow>
                <TableCell align="center" colSpan={10}>
                  No Data Found!
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <hr />
      <Stack
        sx={{
          background: 'transparent',
        }}
        direction="row"
        alignItems={"center"}
        justifyContent={"center"}
      >
        <Box className="pagination-cover d-flex flex-wrap align-items-center justify-content-between py-4 px-4 w-100" gap={1.5}>
          <Box className="d-flex align-items-center rowPerPage" gap={0}>
            <span style={{ fontSize: '13px', color: 'rgba(0, 0, 0, 0.7)', fontWeight: '500' }}>Rows per page:</span>
            <PaginationSelect handleChange={handleChangeRowsPerPage} page={rowsPerPage} />
          </Box>
          <Box className="d-flex flex-wrap align-items-center pageSteps" gap={1}>
            <Typography variant="subtitle2" style={{ fontSize: '13px', color: 'rgba(0, 0, 0, 0.7)', fontWeight: '500' }}>
              {data && data.length > 0
                ? page === 0
                  ? `0 - ${data?.length}`
                  : `${rowsPerPage * (page - 1) + 1}  - ${rowsPerPage * (page - 1) + data?.length
                  } `
                : "0 - 0"}{" "}
              &nbsp;of {count} items
            </Typography>
            <Pagination
              count={Math.ceil(count / rowsPerPage) || 0}
              variant="outlined"
              onChange={handleChangePage}
              defaultPage={1} siblingCount={0} boundaryCount={1}
            />
          </Box>
        </Box>

      </Stack>
      {false && <Stack
        className="paginationSec py-3 px-4"
        direction="row"
        alignItems={"center"}
        justifyContent={"flex-end"}
      >
        <Table>
          <TableFooter>
            <TableRow>
              <TablePagination

                rowsPerPageOptions={[5, 10, 25, 100]}
                sx={{
                  "& .MuiTablePagination-selectLabel": {
                    mb: 0.5,
                  },
                  "& .MuiTablePagination-displayedRows": {
                    mb: 0.5,
                  },
                  border: 0,
                  padding: '16px 24px'
                }}

                count={count ?? data?.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </Stack>}
    </Box>
  );
}
