import { Search } from "@mui/icons-material";
import {
  InputAdornment,
  OutlinedInput,
  Stack,
  Typography
} from "@mui/material";
import Box from "@mui/material/Box";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CustomTable from "../components/customTable";
import { useGetRolesQuery } from "../redux/api";
import { ROUTES } from "../utils/routes";


const RolesAndRights = () => {
  const navigate = useNavigate();
  const states = useSelector((state) => state.app);
  const [rolesData, setRolesData] = React.useState([]);
  const [filter, setFilter] = React.useState({});
  const [pagination, setPagination] = React.useState({ rows: 10, page: 0 });
  const { isSuccess, data } = useGetRolesQuery({
    offset: pagination.page * pagination.rows,
    limit: pagination.rows,
    filter: JSON.stringify(filter),
  });
  const [count, setCount] = React.useState(data?.result.count || 0);
  const [checkboxData, setCheckboxData] = React.useState([]);

  // for table checkbox -------------------------------------------------------------------------------
  const handleChangeCheckbox = (data) => {
    checkboxData.includes(data)
      ? setCheckboxData((prev) => prev.filter((item) => item !== data))
      : setCheckboxData([...checkboxData, data]);
  };
  const handleRemoveAllChecked = () => {
    checkboxData.length > 0
      ? setCheckboxData([])
      : setCheckboxData(
        tableData.map((item) => item.status == "PENDING" && item.id)
      );
  };

  const handlePageChange = (data) => {
    setPagination(data);
  };

  const handleSearch = (e) => {
    if (e.target.value) {
      setFilter({ ...filter, search: e.target.value });
    } else {
      setFilter({ ...filter, search: "" });
    }
  };

  const Action = (row) => {
    const { id } = row
    return (
      <>
        <div className="actBtnGrps">
          {states?.permissions?.roleAndRights?.edit === 1 && <span className="editIcon" onClick={() => navigate(`${ROUTES.ROLES_AND_RIGHTS}${ROUTES.EDIT}/${id}`)}>Edit</span>}

          <span className="viewIcon" onClick={() => navigate(`${ROUTES.ROLES_AND_RIGHTS}${ROUTES.VIEW}/${id}`)}>View</span>
          {/* <Edit className="editIcon" size="small"  onClick={() => navigate(`${ROUTES.ROLES_AND_RIGHTS}${ROUTES.EDIT}/${id}`)} /> */}
          {/* <Visibility className="viewIcon" size="small" sx={{ml:1}} onClick={() => navigate(`${ROUTES.ROLES_AND_RIGHTS}${ROUTES.VIEW}/${id}`)}/> */}
        </div>
      </>
    );
  };
  React.useEffect(() => {
    if (isSuccess && data && data.status === "SUCCESS") {
      setRolesData(data.result.data);
      setCount(data.result.count)
    }
  }, [isSuccess, data]);
  const [searchValue, setSearchValue] = React.useState();

  const cols = ["name", "description"];
  const tableData = [];
  if (rolesData?.length > 0) {
    for (const key of rolesData) {
      tableData.push({
        name: key.roleName,
        description: key.description,
        id: key.roleId
      });
    }
  }
  const [open, setOpen] = React.useState(false);
  const approveFn = (data) => {
    console.log(data);
  };
  return (
    <Stack direction={"column"} gap={2}>
      <Stack
        className="mainTitle flex-wrap"
        direction={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Typography variant="h1" className="page-title">Roles And Rights</Typography>
        <button className="btnPrimary" disabled={!states?.permissions?.roleAndRights?.create} onClick={() => navigate(`${ROUTES.ROLES_AND_RIGHTS}${ROUTES.ADD}`)}>Add Role</button>
      </Stack>
      <Box
        sx={{
          backgroundColor: '#fff',
          transition: 'all .5s ease-in-out',
          position: 'relative',
          border: '1px solid rgba(170, 170, 170, 0.3)',
          borderRadius: '12px',
          boxShadow: '0px 5px 5px 0px rgba(82, 63, 105, 0.05)',
          overflow: 'hidden',
        }}>

        <div className="myCmpny d-flex flex-wrap align-items-center justify-content-between searcFilter p-3">
          <OutlinedInput
            className="inputSearch sContrl p-0"
            placeholder="Search Role"
            name="search"
            onChange={handleSearch}
            startAdornment={
              <InputAdornment className="overIcon rolesSearch" position="start"
                sx={{
                  maxHeight: 'none',
                  height: '38px',
                  minWidth: '38px',
                }}
              >
                <Search />
              </InputAdornment>
            }
          />
        </div>

        <CustomTable
          cols={cols}
          onPageChange={handlePageChange}
          data={tableData}
          isCheckbox={false}
          actionComp={Action}
          count={count}
          handleCheckbox={handleChangeCheckbox}
          checkboxData={checkboxData}
          removeAllChecked={handleRemoveAllChecked}
        />
      </Box>
    </Stack>
  );
};

export default RolesAndRights;
