import { useEffect, useState } from "react";
import { BASE_URL } from "../../utils/constant";
import { endpoints } from "../../utils/endpoints";

const Terms = () => {
    const [data, setData] = useState();
    const getTerms = () => {
        const url = BASE_URL.replace("/v1/", "/");
        return fetch(url + endpoints.terms)
            .then((res) => res.text())
            .then((res) => setData(res))
            .catch((err) => console.log("eeeeeee", err))
    }

    console.log("data", data)

    useEffect(() => {
        getTerms()
    }, [])
    return <p style={{ padding: 5 }}>
        {data}
    </p>
}

export default Terms;