import { Button, Dialog, DialogContent, Grid, Typography } from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { requestPaymentProcess } from '../../pages/campaigns/action';
import { formatPrice, getFormatedDate, getUserType } from '../../utils/common';
import { CAMPAIGN_TYPES, COMPANY, PENDING, RUPEES_SYMBOL } from '../../utils/constant';
import RequestedPaymentHistory from './RequestedPaymentHistory';
const MilestoneHistory = (props) => {
    const { historyOpen, setHistoryOpen, milestoneData, campaignInfluencersList } = props;
    const handleClose = () => setHistoryOpen(false);
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const [history, setHistory] = useState([]);


    return (
        <>
            <Dialog maxWidth={"lg"} fullWidth open={historyOpen} onClose={handleClose}>
                <DialogContent className='p-0'>
                    <Grid container >
                        <Grid item p={3} xs={12} lg={12} md={12} sm={12}>
                            <Typography variant='h4' className='capitalize-first-letter' gutterBottom>
                                {milestoneData.name}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} lg={12} md={12} sm={12}>
                            <TableContainer component={Paper}>
                                <Table aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Milestone Name</TableCell>
                                            <TableCell >Promised Payment</TableCell>
                                            <TableCell >Release Date-time</TableCell>
                                            <TableCell >Status</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {milestoneData?.milestones?.milestoneWalletDetails != null && milestoneData?.milestones?.milestoneWalletDetails?.length > 0 ? milestoneData?.milestones?.milestoneWalletDetails?.map((row, i) => (
                                            <TableRow key={row.id}>
                                                <TableCell>{row?.milestone}{row?.kpiCount ? "(" + row?.kpiCount + ")" : ""}</TableCell>
                                                <TableCell>{RUPEES_SYMBOL}{formatPrice(row?.amount)}</TableCell>
                                                <TableCell>{row?.releaseDate ? getFormatedDate(row?.releaseDate, "DD-MM-YYYY HH:mm:ss") : "N/A"}</TableCell>
                                                <TableCell> <button className={row.status?.toLowerCase() + " capitalize-first-letter"}>{row.status}</button>

                                                    {i == milestoneData?.milestones?.milestoneWalletDetails.length - 1 && campaignInfluencersList.type !== CAMPAIGN_TYPES.AFFILIATE && milestoneData.paymentStatus == PENDING && row.status === "APPROVED" && row.isCampaign == 0 && getUserType() == COMPANY ? <><Button variant='contained' onClick={() => {
                                                        dispatch(requestPaymentProcess(milestoneData.postData, setHistoryOpen));
                                                    }}  >Release Payment</Button>
                                                    </> : ""}

                                                    {i == milestoneData?.milestones?.milestoneWalletDetails.length - 1 && campaignInfluencersList.type !== CAMPAIGN_TYPES.AFFILIATE && row?.isCampaign && <a href='/' style={{ textDecoration: "none" }} onClick={(e) => {
                                                        e.preventDefault();
                                                        setOpen(true);
                                                        setHistory(milestoneData?.paymentRequest?.payment_request_json);
                                                    }}  > &nbsp;“Requested Payment” History</a>}
                                                </TableCell>
                                            </TableRow>
                                        )) : <TableRow><TableCell colSpan={4} align='center'  >No History Found</TableCell></TableRow>}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions className='px-4 pt-3 pb-4'>
                    <button
                        className='btnSecondary'
                        onClick={() => setHistoryOpen(false)}
                    >
                        Close
                    </button>
                </DialogActions>

            </Dialog >
            <RequestedPaymentHistory open={open} setOpen={setOpen} history={history} />
        </>
    );
};

export default MilestoneHistory;