import { Box, Grid, MenuItem, Stack, Typography } from "@mui/material";
import { Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import AutoSuggestion from "../../components/autocomplete";
import CustomAuto from "../../components/autocomplete/CustomAuto";
import CompanyQandAModals from "../../components/dialog/CompanyModals";
import BasicSelect from "../../components/select";
import { getCompanyFlowStatus, getUserType, setSearchdata } from "../../utils/common";
import { COMPANY, USER_STATUS } from "../../utils/constant";
import { checkCreditOnDashboard } from "./action";
import { updateDashboard } from "./dashboardSlice";
const SearchData = () => {
    const { plateforms, niches } = useSelector((state) => state.commonSlice);

    const navigate = useNavigate();
    const dispatch = useDispatch();


    return <>
        <Formik
            initialValues={{ platform: "Instagram", categories: [] }}
            enableReinitialize={true}
            validateOnChange={true}
            //validationSchema={Schema.Step4}
            onSubmit={(values, { setSubmitting }) => {
                values.categories = values?.categories?.map((v) => v.title);
                const jsonString = JSON.stringify(values);
                setSearchdata(jsonString);
                dispatch(
                    updateDashboard({
                        filters: { platform: values.platform, status: USER_STATUS.APPROVED }
                    }),
                );
                dispatch(checkCreditOnDashboard(navigate))
                // navigate("/dashboard/listing");
            }}
        >
            {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values,
                setFieldValue,
                isValid,
                dirty,
            }) => {
                return (
                    <Form
                        onSubmit={(e) => {
                            e.preventDefault();
                            handleSubmit(e);
                        }}
                    >

                        <Grid className="campSerchInflu" pt={2} container mb={5}>
                            <Grid item className='mx-auto' xs={12} xl={8} lg={8} md={11} sm={12} pt={5}>
                                <Typography className='fs-2' variant="h4" align="center" component="h4" mb={0}>Inflyx is powered by <span>AI</span></Typography>
                                <Grid className="mx-auto" item xs={12} xl={12} lg={12} md={12} sm={12}>
                                    <Stack className="searchBlockInflu" direction="row">
                                        <Box width="18%">
                                            <BasicSelect
                                                className="searchPlatform"
                                                required={false}
                                                name="platform"
                                                label=""
                                                values={values.platform}
                                                handleChange={handleChange}
                                                options={plateforms.map((item) => (
                                                    <MenuItem value={item} key={item}>
                                                        {item}
                                                    </MenuItem>
                                                ))}
                                                style={{}}
                                            />
                                        </Box>

                                        <Box className="searchText" style={{ width: 'calc(100% - 235px)', paddingLeft: '10px', fontSize: '15px' }}>
                                            {/* <i>Search name, categories, topics, bio keywords or give prompt ✨</i> */}
                                            <CustomAuto type="dashboard" handleChange={(e, newValue) => {
                                                handleChange({
                                                    target: { name: "categories", value: newValue },
                                                });
                                            }} values={values} />
                                            {false && <AutoSuggestion
                                                multiple={true}
                                                limitTags={2}
                                                disableClearable={true}
                                                value={values.categories}
                                                className="auto-chips-cover"
                                                label={""}
                                                onChange={(_e, newValue) => {
                                                    handleChange({
                                                        target: { name: "categories", value: newValue },
                                                    });
                                                }}
                                                // onInputChange={(e) => {
                                                //     //setInfluencerNiche(() => nicha)
                                                //     if (e.target.value) {
                                                //         dispatch(updateCampaign({ nicha: influencerNiche }))
                                                //     } else {
                                                //         dispatch(updateCampaign({ nicha: influencerNiche?.filter((v) => ["Art & Creativity", "Parenting & Family", "Travel & Adventure", "Sports & Fitness", "Education & Learning"].includes(v)) }))
                                                //     }
                                                // }}


                                                id="multiple-limit-tags"
                                                size="small"
                                                name="search"
                                                placeholder={values?.categories?.length > 0 ? "Add More.." : "Categories, topics, bio keywords or give prompt #anything"}
                                                options={niches.filter((v) => !values?.categories?.map((v) => v.title).includes(v.title))}
                                                isOptionEqualToValue={(option, value) => {
                                                    if (
                                                        value === "" ||
                                                        option === "" ||
                                                        value === option
                                                    )
                                                        return option;
                                                }}
                                                getOptionLabel={(option) =>
                                                    option || "no_option_found"
                                                }

                                            // renderOption={(props, option) => {
                                            //     return (
                                            //         < >
                                            //             {props.id === "search-option-0" && <Grid item xs={12} lg={12} md={12} sm={12} mb={2}>
                                            //                 <Typography component="h4" variant="h4" display="block" p={2} pt={0}>
                                            //                     Search name, categories, topics, bio keywords or give prompt ✨
                                            //                 </Typography>
                                            //                 <hr />
                                            //             </Grid>}

                                            //             <Grid sx={{ display: "flex", flexWrap: 'wrap' }} {...props}>
                                            //                 <Chip

                                            //                     avatar={<Avatar alt={option} src="/avtar.jpg" />}
                                            //                     label={option}
                                            //                     color="default"
                                            //                 />


                                            //             </Grid>

                                            //         </>
                                            //     );
                                            // }}
                                            // renderTags={(value, getTagProps) => (
                                            //     <Grid>
                                            //         {value.slice(0, 2).map((option, index) => (
                                            //             <Grid item key={option}>
                                            //                 <Chip
                                            //                     label={option}
                                            //                     {...getTagProps({ index })}
                                            //                     avatar={<Avatar alt={option} src="/avtar.jpg" />}
                                            //                 />
                                            //             </Grid>
                                            //         ))}
                                            //         {value.length > 2 && (
                                            //             <Grid item>
                                            //                 <Typography>+ {value.length - 2} more</Typography>
                                            //             </Grid>
                                            //         )}
                                            //     </Grid>
                                            // )}
                                            />}

                                        </Box>
                                        <Box>
                                            <button
                                                className="searchBtn"
                                                type="submit"
                                            >
                                                Generate ✨

                                            </button>
                                        </Box>
                                    </Stack>
                                </Grid>

                            </Grid>
                        </Grid>
                    </Form>
                );
            }}
        </Formik>
        {getUserType() === COMPANY && getCompanyFlowStatus() == 1 && <CompanyQandAModals />}
    </>
}
export default SearchData;