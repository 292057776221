import {
  Search
} from "@mui/icons-material";
import {
  Box,
  InputAdornment,
  Pagination,
  Paper,
  Stack,
  Typography
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import { visuallyHidden } from "@mui/utils";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CommonModel from "../../../components/dialog/Common";
import SearchInput from "../../../components/inputs/searchInput";
import PaginationSelect from "../../../components/select/pagination";
import useDebounce from "../../../hooks/use.debounce";
import { resetSubscription } from "../../subscriptions/subscriptionSlice";
import { deleteNotification, getNotificationList } from "../action";
import { updateNotification } from "../notificationSlice";

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort, isSticky } = props;
  console.log("props", props);
  const createSortHandler =
    (property) => (event) => {
      if (["type", "status", "nextScheduleDate", "frequency"].includes(property)) {
        onRequestSort(event, property);
      } else {
        return;
      }
    };

  const headCells = [
    {
      id: "name",
      numeric: false,
      disablePadding: true,
      label: "Group Name",
      type: "",
    },
    {
      id: "type",
      numeric: false,
      disablePadding: true,
      label: "Type",
      type: "",
    },
    {
      id: "status",
      numeric: false,
      disablePadding: true,
      label: "Status",
      type: "",
    },
    {
      id: "nextScheduleDate",
      numeric: false,
      disablePadding: true,
      label: "Date & Time",
      type: "",
    },
    {
      id: "frequency",
      numeric: false,
      disablePadding: true,
      label: "Is Scheduled",
      type: "",
    },
    {
      id: "messageTitle",
      numeric: false,
      disablePadding: true,
      label: "Message Title",
      type: "",
    },
    {
      id: "messageBody",
      numeric: false,
      disablePadding: true,
      label: "Message Body",
      type: "",
    },
    {
      id: "action",
      numeric: false,
      disablePadding: true,
      label: "Actions",
      type: "",
    },
  ];

  // headCell.type == '' || ([headCell.type].includes(subType)) ?
  return (
    <TableHead className={`hideActionSort ${isSticky} `}>
      <TableRow>

        {headCells.map((headCell) => {
          return (
            <TableCell
              key={headCell?.id}
              align={headCell.numeric ? "right" : "left"}
              padding={headCell.disablePadding ? "none" : "normal"}
              sortDirection={orderBy === headCell.id ? order : false}
              sx={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                // IconComponent={null}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
}


export default function NotificationHistory() {
  const navigate = useNavigate();
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("id");
  const [open, setOpen] = useState(false);
  const [ids, setIds] = useState([]);
  const [sticky, setSticky] = useState("");
  const dispatch = useDispatch();
  const {
    nLimit,
    nPage,
    sortKey,
    totalCount,
    totalPages,
    nFilters,
    notificationListing,
  } = useSelector((state) => state.notificationSlice);
  const debouncedSearch = useDebounce(nFilters.search, 500);
  const handleRequestSort = (
    event,
    property
  ) => {
    const isAsc = orderBy === property && order === "asc";

    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    // if (property === "status") return;
    dispatch(
      updateNotification({
        sortType: isAsc ? "asc" : "desc",
        sortKey: property,
      }),
    );
    dispatch(getNotificationList())
  };

  useEffect(() => {
    if (debouncedSearch || nFilters.search == "")
      dispatch(getNotificationList())
  }, [debouncedSearch]);

  useEffect(() => {
    dispatch(
      updateNotification({
        sortKey: "nextScheduleDate"
      }),
    );
    dispatch(getNotificationList())
    return () => dispatch(resetSubscription())
  }, []);

  const handleSearch = (event) => {
    //if (event.target.value)
    dispatch(updateNotification({ nFilters: { ...nFilters, search: event.target.value }, nPage: 0 }));
  };

  const handleSearchClear = () => {
    dispatch(updateNotification({ nFilters: { ...nFilters, search: "" } }));
    dispatch(getNotificationList());
  };
  const handleChangePerPage = (event, value) => {
    dispatch(updateNotification({ nLimit: event.target.value, nPage: 0 }));
    dispatch(getNotificationList());
  };

  const handleChange = (event, value) => {
    dispatch(updateNotification({ nPage: value - 1 }));
    dispatch(getNotificationList());
  };

  return (
    <>
      <Stack direction={"column"} gap={2}>
        <Stack
          className="mainTitle flex-wrap"
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
          sx={{
            minHeight: '42px'
          }}
        >
          <Typography className="page-title" variant="h1">Notification History</Typography>

        </Stack>
        <Box
          sx={{
            backgroundColor: '#fff',
            transition: 'all .5s ease-in-out',
            position: 'relative',
            border: '1px solid rgba(170, 170, 170, 0.3)',
            borderRadius: '12px',
            boxShadow: '0px 5px 5px 0px rgba(82, 63, 105, 0.05)',
            // overflow: 'hidden',
          }}>
          <div className="myCmpny d-flex flex-wrap align-items-center justify-content-between searcFilter p-3">
            <div className='allFilter cmpyMgmt '>
              <div className="d-flex align-items-center gap-2" style={{ marginRight: 0 }}>

              </div>
            </div>
            <SearchInput
              className="inputSearch sContrl p-0"
              value={nFilters.search}
              placeholder={"Search here..."}
              handleSearch={handleSearch}
              clearSearch={handleSearchClear}
              startAdornment={
                <InputAdornment className="overIcon" position="start"
                  sx={{
                    maxHeight: 'none',
                    height: '38px',
                    minWidth: '38px',
                    marginRight: '0px'
                  }}
                >
                  <Search />
                </InputAdornment>
              }
            />

          </div>

          <Paper sx={{ width: '100%', height: "100%", }}>
            <TableContainer sx={{ maxHeight: 550 }} >
              <Table
                stickyHeader aria-label="sticky table"
              >
                <EnhancedTableHead
                  // numSelected={selected.length}
                  isSticky={sticky}
                  order={order}
                  orderBy={sortKey}
                  notificationListing={notificationListing}
                  setIds={setIds}
                  ids={ids}
                  //onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  //rowCount={rows.length}
                  numSelected={0}
                  onSelectAllClick={function (

                  ) {
                    throw new Error("Function not implemented.");
                  }}
                //order={"desc"}
                />
                <TableBody>
                  {notificationListing && notificationListing?.length ? (
                    notificationListing?.map((row, index) => {
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={row.id}
                          className={"tableRow"}
                          style={{ userSelect: "text" }}
                        >
                          <TableCell component="td" scope="row">
                            {row?.groupNames || "N/A"}
                          </TableCell>
                          <TableCell component="td" scope="row">
                            {row.type}
                          </TableCell>
                          <TableCell component="td" scope="row">
                            <button className={row.status?.toLowerCase() + " capitalize-first-letter"}>{row.status}</button>
                          </TableCell>

                          <TableCell component="td" scope="row">
                            {row?.nextScheduleDate || "N/A"}
                          </TableCell>
                          <TableCell component="td" scope="row">
                            {row.frequency == "oneTime" ? "No" : "Yes"}
                          </TableCell>
                          <TableCell align="left">
                            {row?.title || "N/A"}
                          </TableCell>
                          <TableCell align="left">
                            {row?.message || "N/A"}
                          </TableCell>
                          <TableCell sx={{ cursor: "pointer" }}>
                            <Stack direction={"row"} gap={2}>
                              {row.status == "Pending" ? <><span className="editIcon" onClick={() => navigate(`/notification/${row.id}/edit`, { state: row })}>Edit</span>

                                {/* <span className="viewIcon" onClick={() => navigate("/notification/details/" + row.id)}>View</span> */}
                                <span className="viewIcon" style={{ color: "red" }} onClick={() => {
                                  setOpen(row.id)
                                }}>
                                  Delete
                                </span></> : <>-</>}
                            </Stack>
                          </TableCell>
                        </TableRow>
                      );
                    })
                  ) : (
                    <TableRow colSpan={10}>
                      <TableCell colSpan={10} style={{ textAlign: 'center' }}>

                        <Typography
                          color="inherit"
                          variant="subtitle1"
                          alignItems={"center"}
                          component="h3"
                        >
                          No Data found!
                        </Typography>

                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>

            </TableContainer>
            <hr />
            <Stack
              sx={{
                background: 'transparent',
              }}
              direction="row"
              alignItems={"center"}
              justifyContent={"flex-end"}
            >
              <Box className="pagination-cover d-flex flex-wrap align-items-center justify-content-between py-4 px-4 w-100" gap={1.5}>
                <Box className="d-flex align-items-center rowPerPage" gap={0}>
                  <span style={{ fontSize: '13px', color: 'rgba(0, 0, 0, 0.7)', fontWeight: '500' }}>Rows per page:</span>
                  <PaginationSelect handleChange={handleChangePerPage} page={nLimit} />
                </Box>
                <Box className="d-flex flex-wrap align-items-center pageSteps" gap={1}>
                  <Typography variant="subtitle2" style={{ fontSize: '13px', color: 'rgba(0, 0, 0, 0.7)', fontWeight: '500' }}>
                    {notificationListing && notificationListing.length > 0
                      ? nPage === 0
                        ? `0 - ${notificationListing.length}`
                        : `${nLimit * (nPage - 0) + 1}  - ${nLimit * (nPage - 0) + notificationListing.length
                        } `
                      : "0 - 0"}{" "}
                    &nbsp;of {totalCount} items
                  </Typography>
                  <Pagination
                    count={Math.ceil(totalPages / nLimit) || 0}
                    variant="outlined"
                    onChange={handleChange}
                    defaultPage={1} siblingCount={0} boundaryCount={1}
                  />
                </Box>
              </Box>
            </Stack >
          </Paper>
          <CommonModel
            open={open}
            handleDialogClose={() => setOpen(false)}
            dialogTitle={"Delete Notification"}
            dialogPara={`Are you sure, you want to delete this notification?`}
            nvYes={"Yes"}
            className={"btnDanger"}
            nvCancel={"Cancel"}
            yesHandle={() => {
              dispatch(deleteNotification(open, setOpen));
              // setOpen(false);
            }}
          />
        </Box>




      </Stack>

    </>
  );
}
