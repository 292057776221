import { Box, FormLabel, Grid, Stack, Typography } from "@mui/material";
import { Form, Formik } from "formik";
import { useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from "react-router-dom";
import CommonModel from "../../../components/dialog/Common";
import NormalInput from "../../../components/inputs/normalInput";
import NumberInput from "../../../components/inputs/normalInput/number";
import Schema from "../../../schema";
import { getCompanyid } from "../../../utils/common";
import { companyUserAdd, updateCompanyUser } from "../action";
import { updateCompany } from "../companySlice";

const AddCompanyUser = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { state } = useLocation();
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const {
        modalOpen, modalMessage
    } = useSelector((state) => state.companySlice);
    const initialValues = {
        phoneNumber: state?.phoneNumber || "",
        contactPersonName: state?.contactPersonName || "",
        email: state?.email || "",
        password: "",
        confirmPassword: "",
        phoneCountryCode: "+91",
        companyId: getCompanyid(),
        id: state?.id || ""
    }
    return (
        <Box>
            <Stack direction={"row"} alignItems={"center"} spacing={3} mb={2}>
                <Stack
                    direction={"row"}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                    sx={{
                        minHeight: '42px'
                    }}
                >

                    <Typography variant="h1" className="page-title">
                        {state?.id ? "Edit" : "Create"} User
                    </Typography>

                    <Stack
                        className="backText"
                        direction={"row"}
                        gap={1}
                        alignItems={"center"}
                        sx={{ cursor: "pointer" }}
                        onClick={() => { }}
                    >
                        <svg
                            width="16"
                            height="12"
                            viewBox="0 0 16 12"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M8.5 7.66667L5.16667 4.33333L8.5 1M4.33333 7.66667L1 4.33333L4.33333 1"
                                stroke="#0077FF"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                            <path
                                d="M5.1665 4.33331H10.9998C11.8839 4.33331 12.7317 4.6845 13.3569 5.30962C13.982 5.93474 14.3332 6.78259 14.3332 7.66665C14.3332 8.5507 13.982 9.39855 13.3569 10.0237C12.7317 10.6488 11.8839 11 10.9998 11H10.1665"
                                stroke="#0077FF"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                        </svg>
                        <Typography variant="h5" color={"primary"} onClick={() => navigate("/company/users")}>
                            Back
                        </Typography>
                    </Stack>
                </Stack>
            </Stack>

            <Box
                sx={{
                    backgroundColor: '#fff',
                    transition: 'all .5s ease-in-out',
                    position: 'relative',
                    border: '1px solid rgba(170, 170, 170, 0.3)',
                    borderRadius: '12px',
                    boxShadow: '0px 5px 5px 0px rgba(82, 63, 105, 0.05)',
                    overflow: 'hidden',
                }}
            >
                <Formik
                    initialValues={initialValues}
                    validationSchema={Schema.addCompanyUser}
                    enableReinitialize={true}
                    onSubmit={(values, { resetForm }) => {

                        if (state?.id) {
                            values.userId = values.id;
                            dispatch(updateCompanyUser(values, navigate))
                        } else {
                            dispatch(companyUserAdd(values, navigate))
                        }


                    }}
                >
                    {({
                        errors,
                        handleBlur,
                        handleChange,
                        handleSubmit,
                        isSubmitting,
                        touched,
                        values,
                        setFieldValue,
                        isValid,
                        setFieldError,
                        dirty,
                    }) => {
                        console.log("valuess", values)
                        return (
                            <Form>
                                <Grid container spacing={2} p={3} ml={1} bgcolor={"white"}>
                                    <Grid item xs={12} lg={4} md={4} sm={12}>
                                        <FormLabel required focused={false}>Name</FormLabel>
                                        <NormalInput
                                            name={"contactPersonName"}
                                            type={"text"}
                                            focused={false}
                                            label={("")}
                                            sizeval="medium"
                                            values={values.contactPersonName}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            error={Boolean(touched.contactPersonName) && errors.contactPersonName}
                                            helperText={touched.contactPersonName && errors.contactPersonName}
                                        />
                                    </Grid>
                                    <Grid item xs={12} lg={8} md={8} sm={12}></Grid>
                                    <Grid item xs={12} lg={4} md={4} sm={12}>
                                        <FormLabel required focused={false}>Email</FormLabel>
                                        <NormalInput
                                            name={"email"}
                                            type={"text"}
                                            focused={false}
                                            label={("")}
                                            sizeval="medium"
                                            values={values.email}

                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            error={Boolean(touched.email) && errors.email}
                                            helperText={touched.email && errors.email}
                                        />
                                    </Grid>
                                    <Grid item xs={12} lg={8} md={8} sm={12}></Grid>
                                    <Grid item xs={12} lg={4} md={4} sm={12}>
                                        <FormLabel required focused={false}>Phone Number</FormLabel>
                                        <NumberInput
                                            name={"phoneNumber"}
                                            type={"number"}

                                            focused={false}
                                            label={("")}
                                            sizeval="medium"
                                            values={values.phoneNumber}
                                            onChange={(e) => {
                                                if (e.target.value.length <= 10) {
                                                    handleChange(e);
                                                } else {
                                                    e.preventDefault();
                                                }
                                            }}
                                            onBlur={handleBlur}
                                            error={Boolean(touched.phoneNumber) && errors.phoneNumber}
                                            helperText={touched.phoneNumber && errors.phoneNumber}
                                        />
                                    </Grid>
                                    <Grid item xs={12} lg={8} md={8} sm={12}></Grid>

                                    <Grid item xs={12} lg={4} md={4} sm={12}>
                                        <FormLabel required focused={false}>Password</FormLabel>
                                        <NormalInput
                                            name={"password"}
                                            type={showPassword ? "text" : "password"}
                                            focused={false}

                                            label={("")}
                                            sizeval="medium"
                                            values={values.password}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            error={Boolean(touched.password) && errors.password}
                                            helperText={touched.password && errors.password}
                                            handleViewPassword={() => setShowPassword((prev) => !prev)}
                                            isEndAdornment={true}
                                            showPassword={showPassword}
                                        />
                                    </Grid>
                                    <Grid item xs={12} lg={8} md={8} sm={12}></Grid>
                                    <Grid item xs={12} lg={4} md={4} sm={12}>
                                        <FormLabel required focused={false}>Confirm Password</FormLabel>
                                        <NormalInput
                                            name={"confirmPassword"}
                                            type={showConfirmPassword ? "text" : "password"}
                                            focused={false}

                                            label={("")}
                                            sizeval="medium"
                                            values={values.confirmPassword}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            error={Boolean(touched.confirmPassword) && errors.confirmPassword}
                                            helperText={touched.confirmPassword && errors.confirmPassword}
                                            isEndAdornment={true}
                                            handleViewPassword={() => setShowConfirmPassword((prev) => !prev)}
                                            showPassword={showConfirmPassword}
                                        />
                                    </Grid>
                                    <Grid item xs={12} lg={8} md={8} sm={12}></Grid>
                                    <Grid item xs={12} lg={4} md={4} sm={12} className="pt-0" container justifyContent="flex-end">
                                        <Stack direction={"row"} spacing={1} mt={2}>
                                            <button className="btnSecondary" onClick={() => navigate("/company/users")}>Cancel</button>
                                            <button
                                                className="btnPrimary"
                                                type="submit"
                                            // disabled={!(isValid && dirty)}
                                            >
                                                {state?.userId ? "Update" : "Submit"}
                                            </button>
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={12} lg={8} md={8} sm={12}></Grid>
                                </Grid>
                            </Form>
                        );
                    }}
                </Formik>
            </Box >
            <CommonModel
                open={modalOpen}
                handleDialogClose={() => dispatch(updateCompany({
                    modalOpen: false,
                }))}
                dialogTitle={""}
                dialogPara={<span dangerouslySetInnerHTML={{ __html: modalMessage }}></span>}
                nvYes={""}
                nvCancel={"Close"}
                yesHandle={() => {
                    dispatch(updateCompany({
                        modalOpen: false,
                    }))

                }}
            />
        </Box >

    );

}
export default AddCompanyUser;