import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import {
  Box,
  FormControl,
  MenuItem,
  Pagination,
  Paper,
  Select,
  Stack,
  Tooltip,
  Typography
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import { visuallyHidden } from "@mui/utils";
import { useEffect, useRef, useState } from "react";
//import SelectPerPage from "../../components/select/paginationSelect";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import CommonModel from "../../components/dialog/Common";
import PaginationSelect from "../../components/select/pagination";
import { getSearchdata } from '../../utils/common';
import { CREATOR_CATEGORY, CREATOR_GENDER, FOLLOWERS_ARRAY, INSTAGRAM_TYPE_WITH_CAPS, YOUTUBE_TYPE_WITH_CAPS, citiesInIndia, getFixedValue } from "../../utils/constant";
import { getInfluencerListFordashboard } from './action';
import { updateDashboard } from './dashboardSlice';
import SearchBox from './searchBox';

function EnhancedTableHead(props) {
  const { order, orderBy } = props;
  const {
    filters
  } = useSelector((state) => state.dashboardSlice);

  const headCells = [
    {
      id: "profile",
      numeric: false,
      disablePadding: true,
      label: "Profiles",
      type: "",
    },
    {
      id: "followers",
      numeric: false,
      disablePadding: true,
      label: filters?.platform === INSTAGRAM_TYPE_WITH_CAPS ? "Followers" : "Subscribers",
      type: "",
    },
    {
      id: "avg_likes",
      numeric: false,
      disablePadding: true,
      label: "Avg Likes",
      type: "",
    },
    {
      id: "avg_reel",
      numeric: false,
      disablePadding: true,
      label: filters?.platform === INSTAGRAM_TYPE_WITH_CAPS ? "Avg reel views" : "Avg views",
      type: "",
    },
    {
      id: "avg_comment",
      numeric: false,
      disablePadding: true,
      label: "Avg Comment",
      type: YOUTUBE_TYPE_WITH_CAPS,
    },
    {
      id: "ER",
      numeric: false,
      disablePadding: true,
      label: "ER",
      type: INSTAGRAM_TYPE_WITH_CAPS,
    },
    {
      id: "location",
      numeric: false,
      disablePadding: true,
      label: "Location",
      type: "",
    },

    {
      id: "categories",
      numeric: false,
      disablePadding: true,
      label: "Categories",
      type: "",
    }
  ];

  // headCell.type == '' || ([headCell.type].includes(subType)) ?
  return (
    <TableHead className="hideActionSort">
      <TableRow>
        {headCells.filter((headCell) => {
          return headCell.type === "" || headCell.type.toLowerCase() === filters?.platform?.toLowerCase();
        }).map((headCell) => {
          return (
            <TableCell
              key={headCell?.id}
              align={headCell.numeric ? "right" : "left"}
              padding={headCell.disablePadding ? "none" : "normal"}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                IconComponent={() => null}
              //onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
}



export default function InfluencersListing() {


  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("name");
  const [open, setOpen] = useState(false);
  const [openTooltipIndexes, setOpenTooltipIndexes] = useState([]);
  const tooltipRefs = useRef([]);

  const handleTooltipToggle = (cellIndex) => {
    setOpenTooltipIndexes(openTooltipIndexes.includes(cellIndex) ? [] : [cellIndex]);
  };

  const handleDocumentClick = (event) => {
    const clickedOutsideAllTooltips = tooltipRefs.current.every(ref => ref && !ref.contains(event.target));
    if (clickedOutsideAllTooltips) {
      setOpenTooltipIndexes([]);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleDocumentClick);

    return () => {
      document.removeEventListener('click', handleDocumentClick);
    };
  }, []);
  const dispatch = useDispatch();
  const {
    limit,
    page,
    sort_key,
    totalCount,
    totalPages,
    filters,
    list,
  } = useSelector((state) => state.dashboardSlice);


  const handleChangePerPage = (event, value) => {
    dispatch(updateDashboard({ limit: event.target.value, page: 0 }));
    dispatch(getInfluencerListFordashboard());
  };
  const handleChange = (event, value) => {

    dispatch(updateDashboard({ page: value - 1 }));
    dispatch(getInfluencerListFordashboard());
  };

  const handleRequestSort = (
    event,
    property
  ) => {
    const isAsc = orderBy === property && order === "asc";

    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    // if (property === "status") return;
    dispatch(
      updateDashboard({
        sort_type: isAsc ? "asc" : "desc",
        sort_key: property,
      }),
    );

  };

  useEffect(() => {
    dispatch(
      updateDashboard({
        filters: getSearchdata()
      }),
    );
    // if (list.length == 0)
    //   dispatch(getInfluencerListFordashboard());
  }, [])



  return (
    <>
      <Stack direction={"column"} gap={2}>
        <SearchBox />
        <Box
          sx={{
            backgroundColor: '#fff',
            transition: 'all .5s ease-in-out',
            position: 'relative',
            border: '1px solid rgba(170, 170, 170, 0.3)',
            borderRadius: '12px',
            boxShadow: '0px 5px 5px 0px rgba(82, 63, 105, 0.05)',
            overflow: 'hidden',
          }}>
          <div className="myCmpny d-flex flex-wrap align-items-center justify-content-between searcFilter p-3">
            {/* <PaginationSelect handleChange={handleChangePerPage} page={limit} /> */}
            <FormControl variant="outlined" className='sContrl' sx={{ marginRight: 'auto' }}>
              {/* <SearchInput
                className="inputSearch p-0"
                value={filters.search}
                placeholder={"Search here..."}
                handleSearch={handleSearch}
                clearSearch={handleSearchClear}
                startAdornment={
                  <InputAdornment className="overIcon" position="start"
                    sx={{
                      maxHeight: 'none',
                      height: '38px',
                      minWidth: '38px',
                      marginRight: '0px'
                    }}
                  >

                  </InputAdornment>
                }
              /> */}
            </FormControl>
            <div className='allFilter campMgmt'>

              <Select
                value={filters?.location || ''}
                name="location"
                onChange={(e) => {
                  dispatch(updateDashboard({ filters: { ...filters, location: e.target.value } }));
                  dispatch(getInfluencerListFordashboard());
                }} displayEmpty
                defaultValue={""}
                justifyContent={"flex-end"}
                className="upperCase"

              >
                <MenuItem disabled value="">
                  Location
                </MenuItem>
                <MenuItem className="upperCase" value=" ">
                  All
                </MenuItem>
                {citiesInIndia.filter((v) => v !== " Pan India").map((v) => <MenuItem className="upperCase" key={v} value={v} >
                  {v}
                </MenuItem>)}
              </Select>
              <Select
                value={filters?.gender || ""}
                name="gender"
                onChange={(e) => {
                  dispatch(updateDashboard({ filters: { ...filters, gender: e.target.value } }))
                  dispatch(getInfluencerListFordashboard());
                }}
                displayEmpty
                defaultValue={""}
                justifyContent={"flex-end"}
                className="upperCase"
              >
                <MenuItem disabled value="">
                  Gender
                </MenuItem >
                <MenuItem className="upperCase" value=" ">
                  All
                </MenuItem>
                {CREATOR_GENDER.map((v) => <MenuItem className="upperCase" key={v} value={v} >
                  {v}
                </MenuItem>)}
              </Select>
              <Select
                value={filters?.influencerType || ''}
                name="influencerType"
                onChange={(e) => {
                  dispatch(updateDashboard({ filters: { ...filters, influencerType: e.target.value } }));
                  dispatch(getInfluencerListFordashboard());
                }} displayEmpty
                defaultValue={""}
                justifyContent={"flex-end"}
                className="upperCase"
              >
                <MenuItem disabled value="">
                  Followers
                </MenuItem>
                <MenuItem className="upperCase" value=" ">
                  All
                </MenuItem>
                {FOLLOWERS_ARRAY.map((v) => <MenuItem className="upperCase" key={v.value} value={v.value} >
                  {v.label}
                </MenuItem>)}
              </Select>
              <Select
                value={filters.creatorCategory || ''}
                name="creatorCategory"
                onChange={(e) => {
                  dispatch(updateDashboard({ filters: { ...filters, creatorCategory: e.target.value } }));
                  dispatch(getInfluencerListFordashboard());
                }}
                displayEmpty
                defaultValue={""}
              >
                <MenuItem value="" disabled hidden>
                  Category
                </MenuItem>
                <MenuItem key="All" value=" ">
                  All
                </MenuItem>
                {CREATOR_CATEGORY.map((name) => (
                  <MenuItem key={name} value={name}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
              <Tooltip title="Refresh listing">
                <RotateLeftIcon toolTip="Refresh page" size="small" sx={{ cursor: "pointer", color: '#6C7692' }}
                  onClick={() => {
                    dispatch(updateDashboard({ filters: { platform: filters.platform } }))
                    dispatch(getInfluencerListFordashboard());
                  }} />
              </Tooltip>
            </div>
          </div>
          <Paper sx={{ width: '100%', height: "100%", }}>
            <TableContainer sx={{ maxHeight: 400 }}>
              <Table
                stickyHeader aria-label="sticky table"
              >
                <EnhancedTableHead
                  // numSelected={selected.length}

                  order={order}
                  orderBy={sort_key}
                  //onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  //rowCount={rows.length}
                  numSelected={0}
                  onSelectAllClick={function (

                  ) {
                    throw new Error("Function not implemented.");
                  }}
                //order={"desc"}
                />
                <TableBody>
                  {list?.length > 0 ? (
                    list?.map((row, index) => {
                      let categrories = JSON.parse(row?.profileNiche || [])?.length > 0 ? JSON.parse(row?.profileNiche)?.map((v) => v?.option)?.toString() : "";
                      const cellTooltipIndex = index;
                      const isOpen = openTooltipIndexes.includes(cellTooltipIndex);

                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={row?.name}
                          className={"tableRow"}
                          style={{ userSelect: "text" }}
                        >
                          <TableCell component="td" scope="row" >
                            <Stack direction={"row"} spacing={2}><div class="MuiAvatar-root MuiAvatar-circular css-1nn861f-MuiAvatar-root"><img alt="" style={{ height: '35px', width: '35px', borderRadius: "100px" }} src={row.picture ? row.picture : "/profile.jpg"} className="MuiAvatar-img" /></div>
                              <Link className="text-decoration-none" target='_blank' style={{ fontSize: '13px', paddingTop: 7, fontWeight: '500', letterSpacing: '0px' }} onClick={(e) => {
                                e.preventDefault();
                                window.open("/userManagement/details/" + row.id, '_blank');
                                // dispatch(getUserSocialDetailsById(row.id, (status) => {
                                //   if (status) {
                                //     window.open("/userManagement/details/" + row.id, '_blank');
                                //   }
                                // }))

                                //navigate("/userManagement/details/" + row.id)
                              }} >{row?.name}</Link>
                            </Stack>

                          </TableCell>
                          <TableCell component="td" scope="row">
                            {row?.followers || "N/A"}

                          </TableCell>
                          <TableCell component="td" scope="row">
                            {getFixedValue(row?.Engagement?.posts?.avg_likes) || "N/A"}
                          </TableCell>

                          <TableCell align="left">
                            {filters?.platform === INSTAGRAM_TYPE_WITH_CAPS ? getFixedValue(row?.Engagement?.reels?.avg_views) : getFixedValue(row?.Engagement?.posts?.avg_views) || "N/A"}
                          </TableCell>
                          {filters?.platform === INSTAGRAM_TYPE_WITH_CAPS && <TableCell align="left">

                            {getFixedValue(row?.Engagement?.posts?.engagement_rate) || "N/A"}
                          </TableCell>}

                          {filters?.platform === YOUTUBE_TYPE_WITH_CAPS && <TableCell align="left">

                            {getFixedValue(row?.Engagement?.posts?.avg_comments)}
                          </TableCell>}
                          <TableCell align="left">
                            {row?.location ? row?.location : "N/A"}
                          </TableCell>
                          <TableCell align="left">
                            {categrories?.length > 20 ? (
                              <div >
                                <Tooltip
                                  ref={ref => tooltipRefs.current[cellTooltipIndex] = ref}
                                  title={categrories}
                                  PopperProps={{
                                    disablePortal: true,
                                  }}
                                  arrow={true}
                                  open={isOpen}
                                  disableFocusListener
                                  disableTouchListener
                                  disableInteractive
                                >
                                  <span
                                    className="showhandCursor"
                                    // onMouseEnter={() => handleTooltipToggle(cellTooltipIndex)}
                                    // onMouseLeave={() => setOpenTooltipIndexes([])}
                                    onClick={() => handleTooltipToggle(cellTooltipIndex)}
                                  >
                                    {categrories.slice(0, 20)}
                                  </span>
                                </Tooltip>
                                {!isOpen && <Tooltip title={categrories} arrow={true}>
                                  <span className="showhandCursor">...</span>
                                </Tooltip>}
                              </div>
                            ) : (
                              <span>{categrories || "N/A"}</span>
                            )}
                            {/* {JSON.parse(row?.profileNiche || [])?.length > 0 ? JSON.parse(row?.profileNiche)?.map((v) => v?.option)?.toString() : "N/A"} */}
                          </TableCell>


                        </TableRow>
                      );
                    })
                  ) : (
                    <TableRow colSpan={10}>
                      <TableCell colSpan={10} style={{ textAlign: 'center' }}>

                        <Typography
                          color="inherit"
                          variant="subtitle1"
                          alignItems={"center"}
                          component="h3"
                        >
                          No Data found!
                        </Typography>

                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>

            </TableContainer>
            <hr />
            <Stack
              sx={{
                background: 'transparent',
              }}
              direction="row"
              alignItems={"center"}
              justifyContent={"center"}
            >
              <Box className="pagination-cover d-flex flex-wrap align-items-center justify-content-between py-4 px-4 w-100" gap={1.5}>
                <Box className="d-flex align-items-center rowPerPage" gap={0}>
                  <span style={{ fontSize: '13px', color: 'rgba(0, 0, 0, 0.7)', fontWeight: '500' }}>Rows per page:</span>
                  <PaginationSelect handleChange={handleChangePerPage} page={limit} />
                </Box>
                <Box className="d-flex flex-wrap align-items-center pageSteps" gap={1}>
                  <Typography variant="subtitle2" style={{ fontSize: '13px', color: 'rgba(0, 0, 0, 0.7)', fontWeight: '500' }}>

                    {list && list.length > 0
                      ? page === 0
                        ? `0 - ${list.length}`
                        : `${limit * (page - 0) + 1}  - ${limit * (page - 0) + list.length
                        } `
                      : "0 - 0"}{" "}
                    &nbsp;of {totalCount} items
                  </Typography>
                  <Pagination
                    count={Math.ceil(totalPages / limit) || 0}
                    variant="outlined"
                    onChange={handleChange}
                    defaultPage={1} siblingCount={0} boundaryCount={1}
                  />
                </Box>
              </Box>

            </Stack>
          </Paper>
          <CommonModel
            open={open}
            handleDialogClose={() => setOpen(false)}
            dialogTitle={"Remove Company"}
            dialogPara={`Are you sure you want to remove this company? `}
            nvYes={"Submit"}
            nvCancel={"Cancel"}
            yesHandle={() => {
              //dispatch(deleteCompany(id));
              setOpen(false);
            }}
          />
        </Box>




      </Stack>

    </>
  );
}
