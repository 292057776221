import { Box, Grid, MenuItem, Stack } from "@mui/material";
import { makeStyles } from '@mui/styles';
import { Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CustomAuto from "../../components/autocomplete/CustomAuto";
import CommonModel from "../../components/dialog/Common";
import BasicSelect from "../../components/select";
import { getSearchdata, setSearchdata } from "../../utils/common";
import { USER_STATUS } from "../../utils/constant";
import { getInfluencerListFordashboard } from "./action";
import { updateDashboard } from "./dashboardSlice";
const useStyles = makeStyles({
    autocompleteListbox: {
        display: 'flex',
        flexWrap: 'wrap',
        padding: '15px !important',
        gap: '8px',
    },
    autocompleteOption: {
        padding: '0px !important',
        background: 'transparent !important',
    },
    scrollbar: {
        '&::-webkit-scrollbar': {
            width: '5px',
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#ccc',
            borderRadius: '5px',
        },
    },
    gridRoot: {
        display: 'flex',
        flexWrap: 'wrap',
    },
});

const SearchBox = () => {
    const { plateforms } = useSelector((state) => state.commonSlice);
    const { exceedMsg } = useSelector((state) => state.dashboardSlice);
    const [value, setValue] = useState({ platform: "Instagram", categories: [] });

    const navigate = useNavigate();
    const dispatch = useDispatch();


    useEffect(() => {
        let data = getSearchdata();
        let cat = data?.categories ? data?.categories?.map((v) => {
            return { title: v };
        }) : []
        setValue({ platform: data?.platform || "Instagram", categories: cat });
    }, []);
    console.log("value", value)
    return <>
        <Formik
            initialValues={value ? value : { platform: "Instagram", categories: [] }}
            enableReinitialize={true}
            validateOnChange={true}
            //validationSchema={Schema.Step4}
            onSubmit={(values, { setSubmitting }) => {

                let categories = values?.categories?.map((v) => v.title) || values.categories
                const jsonString = JSON.stringify({ platform: values.platform, categories: categories });
                setSearchdata(jsonString);
                //alues.categories = values?.categories?.map((v) => v.title) || values.categories;
                dispatch(
                    updateDashboard({
                        filters: { platform: values.platform, categories: categories, status: USER_STATUS.APPROVED },
                        search: "",
                        list: []
                    }),
                );
                dispatch(getInfluencerListFordashboard());
                navigate("/dashboard/listing");
            }}
        >
            {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values,
                setFieldValue,
                isValid,
                dirty,
            }) => {

                return (
                    <Form
                        onSubmit={(e) => {
                            e.preventDefault();
                            handleSubmit(e);
                        }}
                    >

                        <Grid container mb={6}>
                            <Grid item className='mx-auto' xs={12} xl={8} lg={8} md={12} sm={12} >


                                <Stack className="searchBlockInflu" direction="row">
                                    <Box width="18%">
                                        <BasicSelect
                                            className="searchPlatform"
                                            required={false}
                                            name="platform"
                                            label=""
                                            defaultValue={values.platform}
                                            values={values.platform}
                                            handleChange={handleChange}
                                            options={plateforms.map((item) => (
                                                <MenuItem value={item} key={item}>
                                                    {item}
                                                </MenuItem>
                                            ))}
                                            style={{}}
                                        />
                                    </Box>

                                    <Box className="searchText" style={{ width: 'calc(100% - 235px)', paddingLeft: '10px', fontSize: '15px' }}>
                                        {/* <i>Search name, categories, topics, bio keywords or give prompt ✨</i> */}
                                        <CustomAuto type="list" handleChange={(e, newValue) => {
                                            handleChange({
                                                target: { name: "categories", value: newValue },
                                            });
                                        }} values={values} />


                                    </Box>
                                    <Box>
                                        <button
                                            className="searchBtn"
                                            type="submit"
                                        >
                                            Generate ✨

                                        </button>
                                    </Box>
                                </Stack>


                            </Grid>
                        </Grid>
                    </Form>
                );
            }}
        </Formik>
        <CommonModel
            open={exceedMsg !== ""}
            handleDialogClose={() => dispatch(updateDashboard({ exceedMsg: "" }))}
            dialogTitle={"Insufficient Credits"}
            dialogPara={exceedMsg}
            nvYes={"Close"}
            yesHandle={() => dispatch(updateDashboard({ exceedMsg: "" }))}
        />
    </>
}
export default SearchBox;