import { useEffect, useState } from "react";
import { BASE_URL } from "../../utils/constant";
import { endpoints } from "../../utils/endpoints";

const Privacy = () => {
    const [data, setData] = useState();
    const getPrivacy = () => {
        const url = BASE_URL.replace("/v1/", "/");
        fetch(url + endpoints.privacyPolicy)
            .then((res) => res.text())
            .then((res) => setData(res))
            .catch((err) => console.log("eeeeeee", err))
    }


    useEffect(() => {
        getPrivacy()
    }, [])

    return <p style={{ padding: 5 }}>
        {data}
    </p>
}

export default Privacy;