import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import {
  Box,
  InputAdornment,
  MenuItem,
  Pagination,
  Select,
  Stack,
  Typography
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import { visuallyHidden } from "@mui/utils";
import * as React from "react";
import DateRangePickerComponent from "../../../components/date/DateRangePickerComponent";
//import SelectPerPage from "../../components/select/paginationSelect";
import {
  Search
} from "@mui/icons-material";
import moment from "moment/moment";
import { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { io } from 'socket.io-client';
import CommonModel from '../../../components/dialog/Common';
import NormalInput from "../../../components/inputs/normalInput";
import SearchInput from "../../../components/inputs/searchInput";
import PaginationSelect from "../../../components/select/pagination";
import useDebounce from '../../../hooks/use.debounce';
import { DELIVERABLES_DATA, formatDate, getCompanyid, getFormatedDate, SELECTED_DELI, SELECTED_DELI_FOR_YOUTUBE } from '../../../utils/common';
import { BASE_URL, CAMPAIGN_STATUS, CAMPAIGN_TYPES, capitalizeFirstLetter, DRAFT, GENDER, PENDING, REJECTED, SOCKET_EVENT } from '../../../utils/constant';
import { endpoints } from '../../../utils/endpoints';
import { getAllBrandForDropDown } from '../../brands/action';
import { getCampaignByIdForDetailsPage, getMyCampaigns } from "../action";
import { updateCampaign } from "../campaignSlice";



function EnhancedTableHead(props) {
  const { order, orderBy } = props;

  const headCells = [
    {
      id: "name",
      numeric: false,
      disablePadding: true,
      label: "Name",
      type: "",
    },
    {
      id: "brand",
      numeric: false,
      disablePadding: true,
      label: "Brand",
      type: "",
    },
    {
      id: "type",
      numeric: false,
      disablePadding: true,
      label: "Type",
      type: "",
    },
    {
      id: "publishDuration",
      numeric: false,
      disablePadding: true,
      label: "Duration",
      type: "",
    },
    {
      id: "product",
      numeric: false,
      disablePadding: true,
      label: "Product Name",
      type: "",
    },
    {
      id: "deliverables",
      numeric: false,
      disablePadding: true,
      label: "Posted On",
      type: "",
    },


    {
      id: "Gender",
      numeric: false,
      disablePadding: true,
      label: "Gender",
      type: "",
    },

    {
      id: "follower",
      numeric: false,
      disablePadding: true,
      label: "Followers",
      type: "",
    },
    {
      id: "status",
      numeric: false,
      disablePadding: true,
      label: "Status",
      type: "",
    },
    {
      id: "action",
      numeric: false,
      disablePadding: true,
      label: "Action",
      type: "",
    },
  ];

  // headCell.type == '' || ([headCell.type].includes(subType)) ?
  return (
    <TableHead className="hideActionSort">
      <TableRow>
        {headCells.map((headCell) => {
          return (
            <TableCell
              key={headCell?.id}
              align={"left"}
              padding={headCell.disablePadding ? "none" : "normal"}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                IconComponent={() => null}
              //onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
}

// const socket = io(BASE_URL + endpoints.ims, {
//   path: '/stream',
//   transports: ['websocket']
// });

export default function MyCampaigns() {
  const navigate = useNavigate();
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("name");
  const [modelOpen, setModelOpen] = React.useState(false);
  const dispatch = useDispatch();
  const tableRef = React.useRef();
  const {
    limit,
    page,
    sort_key,
    totalCount,
    totalPages,
    myCampaigns,
    myCampaignsFilters
  } = useSelector((state) => state.campaignSlice);

  const {
    brandsDropdown
  } = useSelector((state) => state.brandSlice);

  const {
    plateforms
  } = useSelector((state) => state.commonSlice);
  const socket = io(BASE_URL + endpoints.ims, {
    path: '/stream',
    transports: ['websocket']
  });

  const debouncedSearch = useDebounce(myCampaignsFilters?.search, 500);

  useEffect(() => {
    dispatch(updateCampaign({ page: 0, limit: 10 }));
    dispatch(getMyCampaigns());
    dispatch(getAllBrandForDropDown(getCompanyid()))
    // dispatch(getAllCompanyforDropdown())
    //return () => dispatch(updateCampaign({ myCampaignsFilters: {} }))
  }, []);

  const handleSearch = (event, value) => {
    // if (event.target.value)
    dispatch(updateCampaign({ myCampaignsFilters: { ...myCampaignsFilters, search: event.target.value } }));

  };



  useEffect(() => {
    const handleCampaignCreated = (data) => {
      return dispatch(getMyCampaigns());
    };

    const handleCampaignApproved = (data) => {
      return dispatch(getMyCampaigns());
    };

    socket.on(SOCKET_EVENT.CAMPAIGN_CREATED, handleCampaignCreated);
    socket.on(SOCKET_EVENT.CAMPAIGN_APPROVED, handleCampaignApproved);

    // Cleanup the event listeners on component unmount
    return () => {
      socket.off(SOCKET_EVENT.CAMPAIGN_CREATED, handleCampaignCreated);
      socket.off(SOCKET_EVENT.CAMPAIGN_APPROVED, handleCampaignApproved);
    };
  }, [socket]);

  useEffect(() => {
    if (myCampaignsFilters?.search || myCampaignsFilters?.search == '')
      dispatch(getMyCampaigns());
    //dispatch(resetCampaign());
  }, [debouncedSearch]);
  const handleSearchClear = (e) => {
    dispatch(updateCampaign({ myCampaignsFilters: { ...myCampaignsFilters, search: "" } }));
    dispatch(getMyCampaigns());
  };

  useEffect(() => {
    const tableContainer = tableRef.current;
    const handleScroll = () => {
      if (tableContainer) {
        const { scrollTop, clientHeight, scrollHeight } = tableContainer;
        console.log("scrollTop", Math.ceil(scrollTop + clientHeight), scrollHeight - 1)
        if (Math.ceil(scrollTop + clientHeight) >= scrollHeight - 1 && totalCount != myCampaigns.length) {
          //dispatch(updateCampaign({ limit: limit + 10, page: 0 }));
          //dispatch(getMyCampaigns())
        }
      }
    };
    tableContainer.addEventListener('scroll', handleScroll);
    return () => {
      tableContainer.removeEventListener('scroll', handleScroll);
    };
  });

  const updateFormData = (row) => {
    dispatch(getCampaignByIdForDetailsPage(row.id, (data) => {
      delete data.campaignContent;
      delete data.applied;
      //  delete row.campaignContent;
      delete row.applied;
      let rowData = data;
      rowData.campaignId = row.id;
      const selectedDeliverables = rowData?.deliverables?.length > 0 ? (rowData?.deliverables[0]?.plateform == "youtube" ? SELECTED_DELI_FOR_YOUTUBE : SELECTED_DELI).map((vv) => {
        let val = data?.deliverables?.map((v) => v.deliverables)[0]?.find((v) => v?.type == vv?.type);
        if (val) {
          return { ...val };
        } else {
          return vv;
        }
      }) : [];
      // deliverables
      // deliverables
      rowData.platform = rowData?.deliverables?.length > 0 ? rowData?.deliverables[0]?.plateform : "";
      rowData.selectedDeliverables = rowData?.deliverables?.length > 0 ? selectedDeliverables : [];
      rowData.post = rowData?.deliverables?.length > 0 ? data?.deliverables[0]?.post : [];
      rowData.deliverables = rowData?.deliverables?.length > 0 ? DELIVERABLES_DATA.find((v) => v[`${rowData.deliverables[0].plateform}`])[`${rowData.deliverables[0].plateform}`] : [];



      rowData.brandId = row?.brandId || '';
      //rowData.bannerImage = rowData?.bannerImage.toString();
      // rowData.editStartDate = rowData.startDate ? formatDate(rowData.startDate) : null;
      rowData.additionalRequirement = rowData.additionalRequirement !== "null" ? rowData.additionalRequirement : "";

      rowData.startDate = rowData.startDate ? formatDate(rowData.startDate) : null; //formatDate(rowData.startDate, 'DD-MM-YYYY');
      rowData.endDate = rowData.endDate ? formatDate(rowData.endDate) : null;
      rowData.budgetbudgetPerInfluencer = rowData.budgetPerInfluencer;


      rowData.gender = rowData?.creator?.gender || "";
      rowData.creatorLocation = rowData?.creator?.creatorLocation || '';
      rowData.influncerType = rowData?.creator?.influncerType || "";
      rowData.selectedCategories = rowData?.creator?.selectedCategories || [];
      rowData.campaignApplyingPreferences = rowData?.creator?.campaignApplyingPreferences ? ['campaignApplyingPreferences'] : [];

      rowData.noOfCreators = String(rowData?.noOfCreators || "");
      rowData.bannerImage = row?.bannerImage?.toString() || '';

      // Audionce preference
      // rowData.audioPrefGender = rowData?.audience?.audioPrefGender || "";
      // rowData.ageGroup = rowData?.audience?.ageGroup || "";
      // rowData.location = rowData?.audience?.location || [];
      rowData.shipmentDetails = rowData.shipmentDetails ? ['shipmentDetails'] : []

      /// Dates
      rowData.productShipmentDate = rowData.productShipmentDate ? formatDate(rowData.productShipmentDate) : "";
      rowData.conceptScriptDate = rowData?.conceptScriptDate ? formatDate(rowData?.conceptScriptDate) : null;
      rowData.contentSubmissionDate = rowData?.contentSubmissionDate ? formatDate(rowData?.contentSubmissionDate) : null
      rowData.contentPublishDate = rowData?.contentPublishDate ? formatDate(rowData?.contentPublishDate) : null;
      rowData.mode = "edit";
      rowData.productDropdown = brandsDropdown?.find((v) => v.id === rowData.brandId)?.product?.map((v) => v?.name) || [];
      dispatch(updateCampaign({ formData: rowData }));
      navigate(`/campaign/info`)
    }));


  }
  const handleChangePerPage = (event, value) => {
    dispatch(updateCampaign({ limit: event.target.value, page: 0 }));
    dispatch(getMyCampaigns());
  };

  const handleChange = (event, value) => {
    dispatch(updateCampaign({ page: value - 1 }));
    dispatch(getMyCampaigns());
  };

  return (
    <>
      <Stack direction={"column"} gap={2}>

        <Stack
          className="mainTitle flex-wrap"
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
          sx={{
            minHeight: '42px'
          }}
        >
          <Typography className="page-title" variant="h1">My Campaigns</Typography>
          <button className="btnPrimary"
            onClick={() => {
              if (brandsDropdown.length === 0) {
                setModelOpen(true);
              } else {
                dispatch(updateCampaign({ formData: {}, deliverablesSection: 0, addtionalSection: 0, detailsSection: 0 }))
                navigate("/campaign/type");
              }

            }}
          >
            Create Campaign
          </button>

        </Stack>
        <CommonModel
          open={modelOpen}
          handleDialogClose={() => setModelOpen(false)}
          dialogTitle={""}
          dialogPara={`This company is not affiliated with any brands.`}
          nvCancel={""}
          yesHandle={() => {
            setModelOpen(false)
          }}
        />
        <Box
          sx={{
            backgroundColor: '#fff',
            transition: 'all .5s ease-in-out',
            position: 'relative',
            border: '1px solid rgba(170, 170, 170, 0.3)',
            borderRadius: '10px',
            boxShadow: '0px 5px 5px 0px rgba(82, 63, 105, 0.05)',
            overflow: 'none',
          }}
        >

          <div className="myCmpny d-flex flex-wrap align-items-center justify-content-between searcFilter p-3">
            {/* <PaginationSelect handleChange={handleChangePerPage} page={limit} /> */}
            <SearchInput
              className="inputSearch sContrl p-0"
              value={myCampaignsFilters?.search}
              placeholder={"Search here..."}
              handleSearch={handleSearch}
              clearSearch={handleSearchClear}
              startAdornment={
                <InputAdornment className="overIcon" position="start"
                  sx={{
                    maxHeight: 'none',
                    height: '38px',
                    minWidth: '38px',
                    marginRight: '0px'
                  }}
                >
                  <Search />
                </InputAdornment>
              }
            />
            <div className='allFilter deepak  cFilter'>
              <DateRangePickerComponent text="Date Range" getSelectedDate={(dates) => {

                if (dates?.filter((v) => v)?.length === 2) {
                  dispatch(updateCampaign({ myCampaignsFilters: { ...myCampaignsFilters, startDate: getFormatedDate(dates[0]), endDate: getFormatedDate(dates[1]) } }));
                  dispatch(getMyCampaigns());
                }

              }} dateReset={myCampaignsFilters} startDate1={myCampaignsFilters.startDate} endDate1={myCampaignsFilters.endDate}
              />
              <Select
                value={myCampaignsFilters?.brandId || ''}
                name="status"
                onChange={(e) => {
                  dispatch(updateCampaign({ myCampaignsFilters: { ...myCampaignsFilters, brandId: e.target.value } }));
                  dispatch(getMyCampaigns());
                }} displayEmpty
                defaultValue={""}
                justifyContent={"flex-end"}
                className="upperCase"
              >
                <MenuItem disabled value="">
                  Brands
                </MenuItem>
                <MenuItem className="upperCase" value=" ">
                  All
                </MenuItem>
                {brandsDropdown?.map((v) => <MenuItem className="upperCase" key={v.id} value={v.id} >
                  {capitalizeFirstLetter(v.brandName)}
                </MenuItem>)}
              </Select>
              <Select
                value={myCampaignsFilters?.status || ''}
                name="status"
                onChange={(e) => {
                  dispatch(updateCampaign({ myCampaignsFilters: { ...myCampaignsFilters, status: e.target.value }, page: 0 }));
                  dispatch(getMyCampaigns());
                }} displayEmpty
                defaultValue={""}
                justifyContent={"flex-end"}
                className="upperCase"
              >
                <MenuItem disabled value="">
                  Status
                </MenuItem>
                <MenuItem className="upperCase" value=" ">
                  All
                </MenuItem>
                {CAMPAIGN_STATUS.map((v) => <MenuItem className="upperCase" key={v} value={v} >
                  {v}
                </MenuItem>)}
              </Select>
              <Select
                value={myCampaignsFilters?.deliverables || ""}
                name="deliverables"
                onChange={(e) => {

                  dispatch(updateCampaign({ myCampaignsFilters: { ...myCampaignsFilters, deliverables: e.target.value || "" }, page: 0 }));
                  dispatch(getMyCampaigns());
                }}
                displayEmpty
                defaultValue={""}
                justifyContent={"flex-end"}
                className="upperCase"
              >
                <MenuItem disabled value="">
                  Posted On
                </MenuItem>
                <MenuItem className="upperCase" value=" ">
                  All
                </MenuItem>
                {plateforms.map((v) => <MenuItem className="upperCase" key={v} value={v} >
                  {v}
                </MenuItem>)}
              </Select>
              <Select
                value={myCampaignsFilters?.type || ""}
                name="type"
                onChange={(e) => {
                  dispatch(updateCampaign({ myCampaignsFilters: { ...myCampaignsFilters, type: e.target.value }, page: 0 }))
                  dispatch(getMyCampaigns());
                }}
                displayEmpty
                defaultValue={""}
                justifyContent={"flex-end"}
                className="upperCase"
              >
                <MenuItem disabled value="">
                  Type
                </MenuItem >
                <MenuItem className="upperCase" value=" ">
                  All
                </MenuItem>
                {Object.values(CAMPAIGN_TYPES).map((v) => <MenuItem className="upperCase" key={v} value={capitalizeFirstLetter(v)} >
                  {capitalizeFirstLetter(v)}
                </MenuItem>)}
              </Select>
              <Select
                value={myCampaignsFilters?.gender || ""}
                name="gender"
                onChange={(e) => {
                  dispatch(updateCampaign({ myCampaignsFilters: { ...myCampaignsFilters, gender: e.target.value }, page: 0 }))
                  dispatch(getMyCampaigns());
                }}
                displayEmpty
                defaultValue={""}
                justifyContent={"flex-end"}
                className="upperCase"
              >
                <MenuItem disabled value="">
                  Gender
                </MenuItem >
                <MenuItem className="upperCase" value=" ">
                  All
                </MenuItem>
                {GENDER.map((v) => <MenuItem className="upperCase" key={v} value={v} >
                  {v}
                </MenuItem>)}
              </Select>
              {false && <><NormalInput
                name={"startDate"}
                type={"date"}
                focused={false}
                label={"Duration(Start Date)"}
                sizeval="medium"
                values={myCampaignsFilters?.startDate}
                placeholder={"From Date"}
                onChange={(e) => {
                  dispatch(updateCampaign({ myCampaignsFilters: { ...myCampaignsFilters, startDate: e.target.value } })); //endDate: e.target.value
                  dispatch(getMyCampaigns());
                  if (myCampaignsFilters.endDate) {
                    const startDate = new Date(e.target.value);
                    const endDate = new Date(myCampaignsFilters.endDate);
                    if (endDate >= startDate === false) {

                      dispatch(updateCampaign({ myCampaignsFilters: { ...myCampaignsFilters, startDate: e.target.value, endDate: e.target.value } }));
                    }

                  }
                }}
                style={{ width: "150px" }}
                isShrink={true}
              />
                <NormalInput
                  name={"endDate"}
                  type={"date"}
                  focused={false}
                  label={"Duration(End Date)"}
                  sizeval="medium"
                  style={{ width: "150px" }}
                  min={myCampaignsFilters?.startDate}
                  values={myCampaignsFilters?.endDate}
                  placeholder={"End Date"}
                  onChange={(e) => {
                    dispatch(updateCampaign({ myCampaignsFilters: { ...myCampaignsFilters, endDate: e.target.value } }))
                    dispatch(getMyCampaigns());
                  }}
                  isShrink={true}
                /></>}

              <RotateLeftIcon toolTip="Refresh page" size="small" sx={{ cursor: "pointer", color: '#6C7692' }}
                onClick={() => {
                  dispatch(updateCampaign({ myCampaignsFilters: {} }))
                  dispatch(getMyCampaigns());
                }} />

            </div>
          </div>


          <TableContainer sx={{ maxHeight: 400 }} stickyHeader ref={tableRef}>
            <Table
              stickyHeader aria-label="sticky table"
            >
              <EnhancedTableHead
                // numSelected={selected.length}

                order={order}
                orderBy={sort_key}
                //onSelectAllClick={handleSelectAllClick}
                onRequestSort={() => { }}
                //rowCount={rows.length}
                numSelected={0}
                onSelectAllClick={function (

                ) {
                  throw new Error("Function not implemented.");
                }}
              //order={"desc"}
              />
              <TableBody>
                {myCampaigns && myCampaigns?.length ? (
                  myCampaigns?.map((row, index) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row?.id}
                        className={"tableRow"}
                        style={{ userSelect: "text" }}
                      >
                        <TableCell component="th" scope="row" padding="none">
                          <Link className="text-decoration-none" style={{ fontSize: '14px', fontWeight: '500', letterSpacing: '0px' }} onClick={(e) => {
                            e.preventDefault();
                            navigate(`/campaign/partial/details/${row.id}`, { state: row });
                          }} >{row?.name || "N/A"}</Link>
                        </TableCell>
                        <TableCell component="th" scope="row" padding="none" sx={{ textTransform: "uppercase" }}>
                          {row?.brandName || "N/A"}
                        </TableCell>
                        <TableCell component="th" scope="row" padding="none" className='capitalize-first-letter'>
                          {row?.type || "N/A"}
                        </TableCell>
                        <TableCell component="th" scope="row" padding="none">
                          {moment(row?.startDate).format("DD MMM")}{" - "}{moment(row?.endDate).format("DD MMM")}
                        </TableCell>
                        <TableCell component="th" scope="row" padding="none">
                          {row?.products?.map((v) => v.name)?.toString() || "N/A"}
                        </TableCell>

                        <TableCell component="th" scope="row" padding="none">
                          {row?.deliverables?.length > 0 ? row?.deliverables?.map((item) => item.plateform)?.toString() : "N/A"}
                        </TableCell>
                        <TableCell align="left" className="single-line">
                          {row?.creator?.gender || "N/A"}
                        </TableCell>
                        <TableCell align="left" className="single-line">
                          {row?.creator?.influncerType?.toString() || "N/A"}
                        </TableCell>
                        <TableCell align="left">

                          <button className={row.status?.toLowerCase() + " capitalize-first-letter"}>{row.status}</button>
                        </TableCell>
                        <TableCell>
                          <Stack direction={"row"} style={{ gap: '8px' }}>

                            {[DRAFT, PENDING, REJECTED].includes(row.status) &&
                              <div className='actBtnGrps'>
                                <span className='editIcon' onClick={() => updateFormData(row)}>Edit</span>

                              </div>
                            }
                            <span className='viewIcon' onClick={() => navigate(`/campaign/partial/details/${row.id}`)}>View</span>
                            {/* //   <ModeEditIcon className='editIcon' size="small" onClick={() => updateFormData(row)} />}
                            // <RemoveRedEyeIcon className='viewIcon' onClick={() => navigate(`/my-campaigns/influencer/${row.id}`)} size="small" /> */}
                            {/* <PageviewIcon className='viewIcon' size="small" onClick={() => {
                              navigate(`/campaign/details/${row.id}`, { state: row });
                            }} /> */}
                          </Stack>
                        </TableCell>
                      </TableRow >
                    );
                  })
                ) : (
                  <TableRow colSpan={10}>
                    <TableCell colSpan={10} style={{ textAlign: 'center' }}>

                      <Typography
                        color="inherit"
                        variant="subtitle1"
                        alignItems={"center"}
                        component="h3"
                      >
                        No Data found!
                      </Typography>

                    </TableCell>
                  </TableRow>
                )}
              </TableBody >
            </Table >

            {/* <Stack
              direction="row"
              alignItems={"center"}
              justifyContent={"flex-end"}
            >
              <Box className="pagination-cover">

                <Typography variant="subtitle2">

                  {myCampaigns && myCampaigns.length > 0
                    ? page === 0
                      ? `0 - ${myCampaigns.length}`
                      : `${limit * (page - 0) + 1}  - ${limit * (page - 0) + myCampaigns.length
                      } `
                    : "0 - 0"}{" "}
                  &nbsp;of {totalCount} items
                </Typography>
                <PaginationSelect handleChange={handleChangePerPage} page={limit} />
                <Pagination
                  count={Math.ceil(totalPages / limit) || 0}
                  variant="outlined"
                  onChange={handleChange}
                />
              </Box>

            </Stack> */}
          </TableContainer >
          <Stack
            sx={{
              background: 'transparent',
            }}
            direction="row"
            alignItems={"center"}
            justifyContent={"flex-end"}
          >
            <Box className="pagination-cover d-flex flex-wrap align-items-center justify-content-between py-4 px-4 w-100" gap={1.5}>
              <Box className="d-flex align-items-center" gap={2}>
                Rows per page: <PaginationSelect handleChange={handleChangePerPage} page={limit} />
              </Box>
              <Box className="d-flex flex-wrap align-items-center" gap={2}>
                <Typography variant="subtitle2">
                  {myCampaigns && myCampaigns.length > 0
                    ? page === 0
                      ? `0 - ${myCampaigns.length}`
                      : `${limit * (page - 0) + 1}  - ${limit * (page - 0) + myCampaigns.length
                      } `
                    : "0 - 0"}{" "}
                  &nbsp;of {totalCount} items
                </Typography>
                <Pagination
                  count={Math.ceil(totalPages / limit) || 0}
                  variant="outlined"
                  onChange={handleChange}
                />
              </Box>
            </Box>
          </Stack>
        </Box >
      </Stack >

    </>
  );
}