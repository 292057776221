import { useEffect, useRef, useState } from 'react';

import AddCircleIcon from '@mui/icons-material/AddCircle';
import CheckIcon from '@mui/icons-material/Check';
import EmailIcon from '@mui/icons-material/Email';
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import FmdGoodOutlinedIcon from '@mui/icons-material/FmdGoodOutlined';
import FolderCopyOutlinedIcon from '@mui/icons-material/FolderCopyOutlined';
import InfoIcon from '@mui/icons-material/Info';
import InstagramIcon from '@mui/icons-material/Instagram';
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import PhoneEnabledIcon from '@mui/icons-material/PhoneEnabled';
import PhotoSizeSelectActualOutlinedIcon from '@mui/icons-material/PhotoSizeSelectActualOutlined';
import SellOutlinedIcon from '@mui/icons-material/SellOutlined';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingUpOutlinedIcon from '@mui/icons-material/TrendingUpOutlined';
import VideoLibraryOutlinedIcon from '@mui/icons-material/VideoLibraryOutlined';
import YouTubeIcon from '@mui/icons-material/YouTube';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { Button, Chip, Stack, Table, TableCell, TableRow } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LinearProgress from '@mui/material/LinearProgress';
import Tab from '@mui/material/Tab';
import TableBody from '@mui/material/TableBody';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import Badge from 'react-bootstrap/Badge';
import Image from 'react-bootstrap/Image';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import ReactWordcloud from 'react-wordcloud';
import { updateGlobalLoader } from '../../components/backdrop/backdropSlice';
import { CustomModal } from '../../components/modal';
import UserDetailsTooltip from '../../components/tootlip/phone';
import { useUpdateUserStatusMutation } from '../../redux/api';
import { formatAgeString, getGender, getLink, getMaximumKey, getTop10Records, getUserType, sumOfAll } from '../../utils/common';
import { ADMIN, COUNTRY_JSON, PENDING, USER_STATUS, YOUTUBE_TYPE, getDecimal, getFixedValue, gettwoDigitFixedValue } from '../../utils/constant';
import Brands from './Brands';
import Posts from './Posts';
import ProfileSummary from './ProfileSummary';
import { getEmail, getPhoneNumber, getUserDetailsById, getUserSocialDetailsById } from './action';
import { resetUser } from './userSlice';
function LinearProgressWithLabel(props) {
    return (
        <Box className="barListItem">
            <Typography variant="body2" component="p" className='m-0' color="text.secondary">{props.country}</Typography>
            <Stack className='d-flex align-items-center' style={{ flexDirection: 'row' }}>
                <LinearProgress className='progLinebar w-100' variant="determinate" {...props} />
                &nbsp;<Typography variant="body2" color="text.secondary" className='text-end' style={{ minWidth: '35px', }}> {`  ${getDecimal(props.value)
                    }%`}</Typography>
            </Stack>
        </Box>
    );
}

const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: theme.palette.common.black
    },
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.black,
        fontSize: "14px"
    },
}));

const smoothScrollTo = (target, duration) => {
    const targetPosition = target.getBoundingClientRect().top + window.pageYOffset;
    const startPosition = window.pageYOffset;
    const distance = targetPosition - startPosition;
    let startTime = null;

    const animation = (currentTime) => {
        if (startTime === null) startTime = currentTime;
        const timeElapsed = currentTime - startTime;
        const ease = easeInOutQuad(timeElapsed, startPosition, distance, duration);
        window.scrollTo(0, ease);
        if (timeElapsed < duration) requestAnimationFrame(animation);
    };

    const easeInOutQuad = (t, b, c, d) => {
        t /= d / 2;
        if (t < 1) return c / 2 * t * t + b;
        t--;
        return -c / 2 * (t * (t - 2) - 1) + b;
    };

    requestAnimationFrame(animation);
};
const UserSocialDetails = () => {
    const step1Ref = useRef(null);
    const step2Ref = useRef(null);
    const step3Ref = useRef(null);
    const step4Ref = useRef(null);
    const step5Ref = useRef(null);
    const step6Ref = useRef(null);

    const scrollToStep = (stepRef) => {
        smoothScrollTo(stepRef.current, 800); // Adjust duration as needed
    };


    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [open1, setOpen1] = useState(false);
    const [open2, setOpen2] = useState(false);
    const [loading, setLoading] = useState(true);
    const [modal, setModal] = useState({
        heading: "",
        submitButton: "",
        isError: false,
    });
    const { id } = useParams();
    const [value, setValue] = useState('1');
    const [data, setData] = useState([]);
    const [toottip, setToottip] = useState(false);
    const [snackbar, setSnackbar] = useState({
        message: "",
        severity: "success",
        open: false,
    });
    const { details, userDetails, detailLoader, emailDisplay, phoneDisplay } = useSelector((state) => state.userSlice);

    const handleChange = (event, newValue) => {
        setValue(newValue);
        setData(details.find((v) => v.handleName === newValue));
    };
    const [updateUser] = useUpdateUserStatusMutation();
    useEffect(() => {
        if (id) {
            dispatch(getUserSocialDetailsById(id, (status) => {
                if (status) {
                    dispatch(getUserDetailsById(id));
                }
            }));

            if (details?.length > 0) {
                setData(details[0]);
            }
        }
        let tm = setTimeout(() => {
            setLoading(false);
        }, 3000)
        return () => {
            clearTimeout(tm)
            dispatch(resetUser({ details: {} }));
        }
    }, [id]);



    const approveFunction = () => {
        setOpen(true);
        setModal({
            heading: "Approve User",
            submitButton: "Approve",
        });
    };
    const rejectFunction = () => {
        setOpen(true);
        setModal({
            heading: "Reject User",
            submitButton: "Reject",
            creating: false,
        });
    };
    const handleSubmit = (updateData) => {
        dispatch(updateGlobalLoader(true));
        updateUser({
            userId: [+id],
            status: updateData.status,
            description: updateData.description,
        }).then((res) => {
            dispatch(updateGlobalLoader(false));
            if (res.data.status == "SUCCESS") {
                setSnackbar({
                    open: true,
                    message: res.data.message,
                    severity: "success",
                });
            } else {
                setSnackbar({
                    open: true,
                    message: res.data.message,
                    severity: "error",
                });
            }
            setTimeout(() => {
                navigate("/userManagement/details/" + id);
                dispatch(getUserDetailsById(id));
            }, 500);
        });
    };
    const options = {
        rotations: 2,
        rotationAngles: [-90, 0],
        fontSizes: [25, 60],
    };
    return (
        <>
            <Stack direction="column" gap={3}>
                <Stack direction={"row"} alignItems={"center"} className='justify-content-between' spacing={3}>
                    <Stack
                        direction={"row"}
                        alignItems={"center"}
                        justifyContent={"space-between"}
                        sx={{
                            minHeight: '42px'
                        }}
                    >
                        <Typography variant="h1" className="page-title">
                            View Details
                        </Typography>
                        {getUserType() == ADMIN && <Stack
                            className="backText"
                            direction={"row"}
                            gap={1}
                            alignItems={"center"}
                            sx={{ cursor: "pointer" }}
                            onClick={() => navigate("/userManagement")}
                        >
                            <svg
                                width="16"
                                height="12"
                                viewBox="0 0 16 12"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M8.5 7.66667L5.16667 4.33333L8.5 1M4.33333 7.66667L1 4.33333L4.33333 1"
                                    stroke="#0077FF"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                                <path
                                    d="M5.1665 4.33331H10.9998C11.8839 4.33331 12.7317 4.6845 13.3569 5.30962C13.982 5.93474 14.3332 6.78259 14.3332 7.66665C14.3332 8.5507 13.982 9.39855 13.3569 10.0237C12.7317 10.6488 11.8839 11 10.9998 11H10.1665"
                                    stroke="#0077FF"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                            </svg>
                            <Typography variant="h5" color={"primary"}>
                                Back
                            </Typography>

                        </Stack>}
                    </Stack>
                    <div className='appnrejbtn'>
                        {userDetails?.status === PENDING && getUserType() == ADMIN &&
                            <>
                                <button className='btnPrimary' onClick={() => approveFunction()}>Approve</button>
                                <button className='btnDanger' onClick={() => rejectFunction()}>Reject</button>
                            </>
                        }
                    </div>
                </Stack>

                <Box
                    sx={{
                        backgroundColor: '#fff',
                        transition: 'all .5s ease-in-out',
                        position: 'relative',
                        border: '1px solid rgba(170, 170, 170, 0.3)',
                        borderRadius: '12px',
                        boxShadow: '0px 5px 5px 0px rgba(82, 63, 105, 0.05)',
                        overflow: 'hidden',
                        position: 'sticky',
                        top: '24px'
                    }}
                >
                    <div className="row p-4 mb-4 d-flex align-items-center justify-content-between position-relative">
                        <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-8 col-sm-6 col-6">
                            <div className="nameLocation d-flex flex-wrap align-items-flex-start">
                                <div className="influImg">
                                    <Image src={userDetails?.picture ? userDetails?.picture : "/profile1.jpg"} circle className='img-fluid' />
                                </div>
                                <div className="influDet">
                                    <Typography variant="h1" component="h1" className='d-flex ' gap={2}>
                                        <span className='fCapital'>{userDetails?.name}</span> {userDetails?.status && <button className={`btnSmall hoverOff ${userDetails?.status === USER_STATUS.BLACKLISTED ? "btnDanger" : "btnPrimary"} `}>
                                            {userDetails?.status === USER_STATUS.APPROVED ? <><BootstrapTooltip arrow title="Get the quickest responses
& the most updated costs
with registered profile." open={getUserType() !== ADMIN ? toottip : false} onClose={() => setToottip(false)} onOpen={() => setToottip(true)} placement="top">
                                                <CheckIcon fontSize='small' />Verified</BootstrapTooltip></> : userDetails?.status}</button>}
                                    </Typography>
                                    <div className="badges my-3">
                                        {userDetails?.profileNiche?.map((v) => <Badge bg="secondary">{v.option}</Badge>)}

                                    </div>
                                    <div className='influLocation d-flex align-items-center '>

                                        {userDetails?.city !== undefined && <FmdGoodOutlinedIcon />}

                                        {userDetails?.city ? userDetails?.city?.toString() + ", " : ""}{userDetails.gender}
                                        {/* {getUserType() === ADMIN && userDetails.email && " , " + userDetails.email}{getUserType() === ADMIN && userDetails.phoneNumber && " , " + userDetails.phoneCountryCode + "-" + userDetails.phoneNumber} */}
                                    </div>

                                </div>

                            </div>
                        </div>
                        {getUserType() === ADMIN ? <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-4 col-4" >
                            <div className='influDetphEm'>
                                <div className='influDetphEmItem mb-3'>
                                    <label>Phone:</label>
                                    <span>{userDetails?.phoneCountryCode + "-" + userDetails?.phoneNumber}</span>
                                </div>
                                <div className='influDetphEmItem'>
                                    <label>Email:</label>
                                    <span>{userDetails?.email || "N/A"}</span>
                                </div>
                            </div>
                        </div>
                            :
                            <>

                                <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-4 col-4 d-flex align-items-center justify-content-end" >
                                    <div className="nameLocation d-flex flex-wrap align-items-flex-end">
                                        <Stack direction={"row"} gap={1} >
                                            <Button
                                                sx={{
                                                    border: '1px solid #aaa',
                                                    borderRadius: '4px',
                                                    height: '40px',
                                                    padding: '12px 15px',
                                                    fontSize: '16',
                                                    color: '#000',
                                                    fontWeight: '400'
                                                }}
                                                className='detCnt'
                                                variant="outlined"
                                                onClick={() => {
                                                    dispatch(getPhoneNumber(id, setOpen1));
                                                }}
                                                startIcon={
                                                    <PhoneEnabledIcon />
                                                }
                                                disabled={open1 || open2}>
                                                Contact
                                            </Button>
                                            {open1 && <UserDetailsTooltip setOpen={setOpen1} setOpen1={setOpen2} open={open1} text={phoneDisplay || "Phone number not found"} />}
                                            <Button

                                                sx={{
                                                    border: '1px solid #aaa',
                                                    borderRadius: '4px',
                                                    height: '40px',
                                                    padding: '12px 15px',
                                                    fontSize: '16',
                                                    color: '#000',
                                                    fontWeight: '400'
                                                }}
                                                className='detCnt'
                                                variant="outlined"
                                                disabled={open1 || open2}
                                                onClick={() => {
                                                    //setOpen2(true);
                                                    dispatch(getEmail(id, setOpen2));
                                                }}
                                                startIcon={
                                                    <EmailIcon />
                                                }>
                                                Mail
                                            </Button>
                                            {open2 && <UserDetailsTooltip setOpen={setOpen2} open={open2} text={emailDisplay || "Email not found"} />}
                                        </Stack>

                                    </div>
                                </div>
                            </>
                        }



                    </div>
                    {details?.length > 0 ? <div className='tabsLayout'>
                        <Box sx={{ width: '100%', typography: 'body1' }}>
                            <TabContext value={value}>
                                <Box className="mainTabs">

                                    <TabList className='mainTabs px-4' onChange={handleChange} variant="scrollable"
                                        scrollButtons={details.length > 4}
                                        allowScrollButtonsMobile
                                        aria-label="scrollable force tabs example">
                                        <Tab iconPosition="start" sx={{
                                            border: value === "1" ? "1px solid #0077FF" : "",

                                        }} icon={<img src="/profile.svg" alt="instagram" width={35} />} label="Profile Summary" value="1" />

                                        {details?.map((v, i) => <Tab sx={{
                                            border: value === v.handleName ? "1px solid #0077FF" : ""
                                        }} key={i} iconPosition="start" icon={v.platform === "instagram" ? <img src="/instagram.png" alt="instagram" width={35} /> : <img src="/youtube.svg" alt="youtube" width={35} />} label={v.handleName} value={v.handleName} />)}
                                    </TabList>

                                </Box>

                                <TabPanel value="1" className='p-0'>
                                    <ProfileSummary details={details} userDetails={userDetails} />
                                </TabPanel>

                                <TabPanel value={data?.handleName} className='p-0'>
                                    <div className='tabInnLayout'>
                                        <div className='row' style={{ padding: '0px 12px' }}>
                                            <div className='col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12 p-0  borderRight'>
                                                <div className="scrollableTabs mb-xxl-0 mb-xl-0 mb-lg-0 mb-lg-0 mb-md-5 mb-sm-5 mb-5">
                                                    <div className='sumOptions p-4 d-flex flex-column'>
                                                        <a href="#/" onClick={(e) => {
                                                            e.preventDefault();
                                                            scrollToStep(step1Ref)
                                                        }}><FolderCopyOutlinedIcon /> Overview</a>
                                                        <a href="#/" onClick={(e) => {
                                                            e.preventDefault();
                                                            scrollToStep(step2Ref)
                                                        }}><FavoriteBorderOutlinedIcon /> Engagement</a>
                                                        <a href="#/" onClick={(e) => {
                                                            e.preventDefault();
                                                            scrollToStep(step3Ref)
                                                        }}><PhotoSizeSelectActualOutlinedIcon /> Content</a>
                                                        <a href="#/" onClick={(e) => {
                                                            e.preventDefault();
                                                            scrollToStep(step4Ref)
                                                        }}><PeopleAltOutlinedIcon /> Audience</a>
                                                        <a href="#/" onClick={(e) => {
                                                            e.preventDefault();
                                                            scrollToStep(step5Ref)
                                                        }}><TrendingUpOutlinedIcon /> Growth</a>
                                                        <a href="#/" onClick={(e) => {
                                                            e.preventDefault();
                                                            scrollToStep(step6Ref)
                                                        }}><SellOutlinedIcon /> Brands</a>
                                                        {/* <a href="#/"><FileDownloadOutlinedIcon /> Download PDF</a> */}

                                                        <a href="#/" onClick={(e) => { e.preventDefault(); window.open(getLink(data.platformType, data.handleName), "_blank"); }} target='_blank'><OpenInNewOutlinedIcon /> Go To Instagram</a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-xxl-9 col-xl-9 col-lg-9 col-md-12 col-sm-12 col-12 p-0'>
                                                <div className='sumdetPage'>
                                                    <div ref={step1Ref}>
                                                        <div className='p-xxl-5 p-xl-5 p-lg-5 p-md-3 p-sm-3 p-3'>
                                                            <Grid container spacing={2}>
                                                                <Grid item xs={4} className="borderRight pt-0">
                                                                    <div className='followItems d-block'>
                                                                        <label className='text-uppercase'>{data?.platformType === YOUTUBE_TYPE ? "SUBSCRIBERS" : "Followers"}</label>
                                                                        <span>{getFixedValue(data?.followers) || "N/A"}</span>
                                                                    </div>
                                                                </Grid>
                                                                <Grid item xs={4} className="borderRight pt-0">
                                                                    <div className='followItems d-block'>
                                                                        <label>{data?.platformType === YOUTUBE_TYPE ? "AVG. VIEWS" : "ENGAGEMENT RATE"}&nbsp;{data?.platformType !== YOUTUBE_TYPE && <BootstrapTooltip arrow title="Engagement rate shows the percent of the audience who engages with the content posted by this influencer." placement="top"><InfoIcon fontSize='small' color="secondary" /></BootstrapTooltip>}</label>
                                                                        <span>{data?.platformType === YOUTUBE_TYPE ? getFixedValue(data?.Engagement?.posts?.avg_views) : gettwoDigitFixedValue(data?.overview?.engagement_rate) + "%" || "N/A"}</span>
                                                                    </div>
                                                                </Grid>
                                                                <Grid item xs={4} className='pt-0'>
                                                                    <div className='followItems d-block'>
                                                                        <label className='upper-case-heading'>{data?.platformType === YOUTUBE_TYPE ? "Total Videos" : "Estimated Reach"}</label>
                                                                        <span>{data?.platformType === YOUTUBE_TYPE ? (data?.content?.post?.length || "N/A") : (parseInt(data?.Audience?.reach) ? getFixedValue(data?.Audience?.reach) : "N/A")}</span>
                                                                    </div>
                                                                </Grid>
                                                            </Grid>
                                                        </div>
                                                        <hr />
                                                        <div className='newSec p-xxl-5 p-xl-5 p-lg-5 p-md-3 p-sm-3 p-3'>
                                                            <Grid container spacing={2} className='mt-0'>
                                                                <Grid item xs={4} className="borderRight pt-0" >
                                                                    <div className='followItems d-block'>
                                                                        <label className='upper-case-heading'>Inflyx Score&nbsp;<BootstrapTooltip arrow title="The Inflyx Score is a measure of an influencer's performance and influence on their social media platforms.

We consider factors like followers, engagement rate, and content quality to help brands evaluate the potential value of working with an influencer." placement="top"><InfoIcon fontSize='small' color="secondary" /></BootstrapTooltip></label>
                                                                        <span>{data?.overview?.score ? data?.overview?.score + "%" : "Coming Soon"}</span>
                                                                    </div>
                                                                </Grid>
                                                                <Grid item xs={8} className='pt-0'>
                                                                    <div className='followItems d-block'>
                                                                        <label>INSIGHTS FOR YOU</label>

                                                                        {data?.insights_for_you?.length > 0 ? <Grid container rowGap={4} mt={2}>
                                                                            {data?.insights_for_you?.map((v, i) =>
                                                                                <Grid key={i} item xs={12} md={6} sm={6} lg={6} >
                                                                                    <Stack direction={"row"}>
                                                                                        <AddCircleIcon size="small" color="success" />
                                                                                        <div style={{ marginLeft: "8px" }}>
                                                                                            <Typography variant="body2" gutterBottom>
                                                                                                {v.header}
                                                                                            </Typography>

                                                                                            <Typography variant="caption" gutterBottom>
                                                                                                {v.text}
                                                                                            </Typography>
                                                                                        </div>
                                                                                    </Stack>
                                                                                </Grid>)}

                                                                        </Grid> : <span>Coming Soon</span>}
                                                                    </div>
                                                                </Grid>
                                                            </Grid>
                                                        </div>
                                                    </div>


                                                    <hr ref={step2Ref} />
                                                    <div className='newSec p-xxl-5 p-xl-5 p-lg-5 p-md-3 p-sm-3 p-3'>
                                                        <h4 className='text-uppercase d-flex align-items-center' style={{ gap: '7px' }}>
                                                            <FavoriteBorderOutlinedIcon /> Engagements & Views</h4>
                                                        <div className='secItem mb-3'>
                                                            <div className='followItems'>
                                                                <div className='item bgOne'>
                                                                    <ul>
                                                                        <li className='smallLabel'>
                                                                            <label className='text-center'>{data?.platformType === YOUTUBE_TYPE ? <YouTubeIcon color='error' /> : <InstagramIcon color="error" />}</label>
                                                                            <span>{data?.platform === "youtube" ? "Video" : "Posts"}</span>
                                                                        </li>

                                                                        {data?.platformType === YOUTUBE_TYPE && <li>
                                                                            <label className='text-uppercase'>Avg. Views</label>
                                                                            <span>{getFixedValue(data?.Engagement?.posts?.avg_views) || "N/A"}</span>
                                                                        </li>}
                                                                        <li>
                                                                            <label className='text-uppercase'>Avg. Likes</label>
                                                                            <span>{getFixedValue(data?.Engagement?.posts?.avg_likes) || "N/A"}</span>
                                                                        </li>
                                                                        <li>
                                                                            <label className='text-uppercase'>Avg. Comments</label>
                                                                            <span>{getFixedValue(data?.Engagement?.posts?.avg_comments) || "N/A"}</span>
                                                                        </li>
                                                                        {data?.platformType !== YOUTUBE_TYPE && <>
                                                                            <li>
                                                                                <label className='text-uppercase'>Engagement Rate&nbsp;<BootstrapTooltip arrow title="Engagement rate shows the percent of the audience who engages with the content posted by this influencer.

It is calculated as average engagement (likes + comments) divided by follower count, and then multiply by 100 to give you a percentage." className='showHandCursor' placement="top"><InfoIcon fontSize='small' color="secondary" /></BootstrapTooltip></label>
                                                                                <span>{data?.Engagement?.posts?.engagement_rate > 0 ? data?.Engagement?.posts?.engagement_rate?.toFixed(2) + "%" : "N/A"}</span>
                                                                            </li>
                                                                            <li>
                                                                                <label className='text-uppercase'>Estimated Reach</label>
                                                                                <span>{data?.Engagement?.posts?.estimated_reach > 0 ? getFixedValue(data?.Engagement?.posts?.estimated_reach) : "N/A"}</span>
                                                                            </li>
                                                                        </>}
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {data?.platformType != YOUTUBE_TYPE &&
                                                            <div className='secItem'>
                                                                <div className='followItems'>
                                                                    <div className='item bgTwo'>
                                                                        <ul>
                                                                            <li className='smallLabel'>
                                                                                <label className='text-center'><VideoLibraryOutlinedIcon color='error' /></label>
                                                                                <span >{data?.platform === "youtube" ? "Shorts" : "Reels"}</span>
                                                                            </li>

                                                                            <li>
                                                                                <label className='text-uppercase'>Avg. Views</label>
                                                                                <span>{getFixedValue(data?.Engagement?.reels?.avg_views) || "N/A"}</span>
                                                                            </li>
                                                                            <li>
                                                                                <label className='text-uppercase'>Avg. Likes</label>
                                                                                <span>{getFixedValue(data?.Engagement?.reels?.avg_likes) || "N/A"}</span>
                                                                            </li>
                                                                            <li>
                                                                                <label className='text-uppercase'>Avg. Comments</label>
                                                                                <span>{getFixedValue(data?.Engagement?.reels?.avg_comments) || "N/A"}</span>
                                                                            </li>
                                                                            <li>
                                                                                <label className='text-uppercase'>Engagement Rate&nbsp;<BootstrapTooltip arrow className='showHandCursor' title="Engagement rate shows the percent of the audience who engages with the content posted by this influencer.

It is calculated as average engagement (likes + comments) divided by follower count, and then multiply by 100 to give you a percentage." placement="top"><InfoIcon fontSize='small' color="secondary" /></BootstrapTooltip></label>
                                                                                <span>{data?.Engagement?.reels?.engagement_rate > 0 ? data?.Engagement?.reels?.engagement_rate?.toFixed(2) + "%" : "N/A"}</span>
                                                                            </li>
                                                                            <li>
                                                                                <label className='text-uppercase'>View Rate&nbsp;<BootstrapTooltip arrow className='showHandCursor' title="View rate shows the percent of the audience who engages with the reels posted by this influencer.

It is calculated as average reel views divided by followers count, and then multiply by 100 to give you a percentage." placement="top"><InfoIcon fontSize='small' color="secondary" /></BootstrapTooltip></label>
                                                                                <span>{data?.Engagement?.reels?.view_rate ? data?.Engagement?.reels?.view_rate?.toFixed(2) + "%" : "N/A"}</span>
                                                                            </li>

                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>}
                                                        <div className='secItem mt-4'>
                                                            <h4 className='text-uppercase'>Likes - Comments Ratio&nbsp;<BootstrapTooltip arrow title="The likes-comments ratio is a metric
that indicates the average number of
comments an influencer receives per
100 likes." className='showHandCursor' placement="top"><InfoIcon fontSize='small' color="secondary" /></BootstrapTooltip></h4>
                                                            <div className='followItems'>
                                                                <div className='item'>
                                                                    <Typography variant="h5" gutterBottom>
                                                                        {data?.overview?.likesToCommentsRatio?.toFixed(2) || "No Data Found"}
                                                                    </Typography>
                                                                    {/* {data?.insights_for_you?.length > 0 && false ? <><Chip label={data?.insights_for_you[0]?.rate} className={data?.insights_for_you[0]?.rate} />
                                                                        &nbsp;<Typography variant="subtitle1" gutterBottom>
                                                                            {data?.insights_for_you[0]?.text}
                                                                        </Typography></> : <ul>
                                                                        <li>
                                                                            <span>No Data Found</span>
                                                                        </li>
                                                                    </ul>} */}

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='secItem mt-4'>
                                                            {data?.platformType !== YOUTUBE_TYPE ?
                                                                <h4 className='text-uppercase'>Reel views to followers ratio&nbsp;<BootstrapTooltip arrow title="The ratio of reel views to followers is a
metric that represents the average
number of reel views an influencer
receives per 100 followers." className='showHandCursor' placement="top"><InfoIcon fontSize='small' color="secondary" /></BootstrapTooltip></h4> : <h4 className='text-uppercase'>RECURRING VIEWERSHIP&nbsp;<BootstrapTooltip arrow
                                                                    title="Average Recurring viewership refers to the
average number of video views an
influencer receives per 100 subscribers" className='showHandCursor' placement="top"><InfoIcon fontSize='small' color="secondary" /></BootstrapTooltip></h4>}


                                                            <div className='followItems'>
                                                                <div className='item'>
                                                                    <Typography variant="h5" gutterBottom>
                                                                        {data?.platformType !== YOUTUBE_TYPE ? data?.overview?.reelViewsToFollowersRatio?.toFixed(2) || "No Data Found" : data?.overview?.recurringViewership?.toFixed(2)}
                                                                    </Typography>
                                                                    {/* {data?.insights_for_you?.length > 3 ? <><Chip label={data?.insights_for_you[3]?.rate} className={data?.insights_for_you[3]?.rate} />
                                                                        &nbsp;<Typography variant="subtitle1" gutterBottom>
                                                                            {data?.insights_for_you[3]?.text}
                                                                        </Typography></> : <ul>
                                                                        <li>
                                                                            <span>No Data Found</span>
                                                                        </li>
                                                                    </ul>} */}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {data?.platformType === YOUTUBE_TYPE && <div className='secItem mt-4'>
                                                            <h4 className='text-uppercase'>LIKABILITY OF THE VIDEO
                                                                &nbsp;<BootstrapTooltip arrow title="The likability of a video among its
viewers can be determined by the number of likes received by an influencer per 1000 views." className='showHandCursor' placement="top"><InfoIcon fontSize='small' color="secondary" /></BootstrapTooltip></h4>
                                                            <div className='followItems'>
                                                                <div className='item'>
                                                                    <Typography variant="h5" gutterBottom>
                                                                        {data?.overview?.likabilityOfTheVideo?.toFixed(2) || "No Data Found"}
                                                                    </Typography>
                                                                </div>
                                                            </div>
                                                        </div>}
                                                        <div className='secItem mt-4'>
                                                            {data?.platformType !== YOUTUBE_TYPE ? <h4 className='text-uppercase'>Post frequency&nbsp;<BootstrapTooltip arrow title="Post frequency is a metric that 
measures the average number of posts made by an influencer in a 30-
day period." className='showHandCursor' placement="top"><InfoIcon fontSize='small' color="secondary" /></BootstrapTooltip></h4> : <h4 className='text-uppercase'>ABILITY TO TRIGGER CONVERSATIONS IN COMMENTS&nbsp;<BootstrapTooltip arrow title="The ability to generate discussions in
the comments section is measured by the average number of comments an influencer receives per 1000 views" className='showHandCursor' placement="top"><InfoIcon fontSize='small' color="secondary" /></BootstrapTooltip></h4>}
                                                            <div className='followItems'>
                                                                <div className='item'>
                                                                    <Typography variant="h5" gutterBottom>
                                                                        {data?.platformType !== YOUTUBE_TYPE ? data?.overview?.postFrequency?.toFixed(2) || "No Data Found" : data?.overview?.abilitytotriggerConversation?.toFixed(2) || "No Data Found"}
                                                                    </Typography>

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='secItem mt-4'>
                                                            <h4 className='text-uppercase'>Content Performance & Benchmarks&nbsp;<BootstrapTooltip arrow title="Content performance refers to how
well an influencer's content is
performing compared to other
contents in the same category." className='showHandCursor' placement="top"><InfoIcon fontSize='small' color="secondary" /></BootstrapTooltip></h4>
                                                            <div className='followItems'>
                                                                <div className='item'>
                                                                    {data?.insights_for_you?.length > 1 ? <><Chip label={data?.insights_for_you[1]?.rate} className={data?.insights_for_you[1]?.rate} />
                                                                        &nbsp;<Typography variant="subtitle1" gutterBottom>
                                                                            {data?.insights_for_you[2]?.text}
                                                                        </Typography></> : <ul>
                                                                        <li>
                                                                            <span>Coming Soon</span>
                                                                        </li>
                                                                    </ul>}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <hr />
                                                    {data?.platformType !== YOUTUBE_TYPE &&
                                                        <div className='newSec p-xxl-5 p-xl-5 p-lg-5 p-md-3 p-sm-3 p-3'>
                                                            <h4 className='text-uppercase'>Paid partnerships - Engagements & Views</h4>
                                                            <div className='secItem mb-2'>
                                                                <div className='followItems'>
                                                                    <div className='item bgThree '>
                                                                        <ul>
                                                                            <li className="smallLabel">
                                                                                <label className='text-center'><InstagramIcon color='error' /></label>
                                                                                <span>{data?.platform === "youtube" ? "Video" : "Posts"}</span>
                                                                            </li>
                                                                            <li>
                                                                                <label className='text-uppercase'>Total Posts</label>
                                                                                <span>{getFixedValue(data?.Engagement?.paid_partnerships?.posts?.total) || "N/A"}</span>
                                                                            </li>
                                                                            <li>
                                                                                <label className='text-uppercase'>Avg. Likes</label>
                                                                                <span>{getFixedValue(data?.Engagement?.paid_partnerships?.posts?.avg_likes) || "N/A"}</span>
                                                                            </li>
                                                                            <li>
                                                                                <label className='text-uppercase'>Avg. Comments</label>
                                                                                <span>{getFixedValue(data?.Engagement?.paid_partnerships?.posts?.avg_comments) || "N/A"}</span>
                                                                            </li>
                                                                            <li>
                                                                                <label className='text-uppercase'>Engagement Rate &nbsp;<BootstrapTooltip arrow className='showHandCursor' title="Engagement rate shows the percent of the audience who engages with the content posted by this influencer.

It is calculated as average engagement (likes + comments) divided by follower count, and then multiply by 100 to give you a percentage."  placement="top"><InfoIcon fontSize='small' color="secondary" /></BootstrapTooltip></label>
                                                                                <span>{data?.Engagement?.paid_partnerships?.posts?.engagement_rate?.toFixed(2) > 0 ? data?.Engagement?.paid_partnerships?.posts?.engagement_rate?.toFixed(2) + "%" : "N/A"}</span>
                                                                            </li>
                                                                            {/* <li>
                                                                                <label className='text-uppercase'>Estimated Reach</label>
                                                                                <span>{getFixedValue(data?.Engagement?.paid_partnerships?.posts?.estimated_reach) || "N/A"}</span>
                                                                            </li> */}

                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='secItem'>
                                                                <div className='followItems'>
                                                                    <div className='item bgTwo'>
                                                                        <ul>
                                                                            <li className="smallLabel">
                                                                                <label className='text-center'><VideoLibraryOutlinedIcon color='error' /></label>
                                                                                <span>{data?.platform === "youtube" ? "Shorts" : "Reels"}</span>
                                                                            </li>
                                                                            <li>
                                                                                <label className='text-uppercase'>Total Reels</label>
                                                                                <span>{getFixedValue(data?.Engagement?.paid_partnerships?.reels?.total) || "N/A"}</span>
                                                                            </li>
                                                                            <li>
                                                                                <label className='text-uppercase'>Avg. Views</label>
                                                                                <span>{getFixedValue(data?.Engagement?.paid_partnerships?.reels?.avg_views) || "N/A"}</span>
                                                                            </li>
                                                                            <li>
                                                                                <label className='text-uppercase'>Avg. Likes</label>
                                                                                <span>{getFixedValue(data?.Engagement?.paid_partnerships?.reels?.avg_likes) || "N/A"}</span>
                                                                            </li>
                                                                            <li>
                                                                                <label className='text-uppercase'>Avg. Comments</label>
                                                                                <span>{getFixedValue(data?.Engagement?.paid_partnerships?.reels?.avg_comments) || "N/A"}</span>
                                                                            </li>
                                                                            <li>
                                                                                <label className='text-uppercase'>Engagement Rate&nbsp;<BootstrapTooltip arro className='showHandCursor' title="Engagement rate shows the percent of the audience who engages with the content posted by this influencer.

It is calculated as average engagement (likes + comments) divided by follower count, and then multiply by 100 to give you a percentage." placement="top"><InfoIcon fontSize='small' color="secondary" /></BootstrapTooltip></label>
                                                                                <span>{data?.Engagement?.paid_partnerships?.reels?.engagement_rate > 0 ? data?.Engagement?.paid_partnerships?.reels?.engagement_rate?.toFixed(2) + "%" : "N/A"}</span>
                                                                            </li>
                                                                            <li>
                                                                                <label className='text-uppercase'>View Rate&nbsp;<BootstrapTooltip arrow className='showHandCursor' title="View rate shows the percent of the audience who engages with the reels posted by this influencer.

It is calculated as average reel views divided by followers count, and then multiply by 100 to give you a percentage." placement="top"><InfoIcon fontSize='small' color="secondary" /></BootstrapTooltip></label>
                                                                                <span>{data?.Engagement?.paid_partnerships?.reels?.view_rate != undefined && data?.Engagement?.paid_partnerships?.reels?.view_rate > 0 ? data?.Engagement?.paid_partnerships?.reels?.view_rate.toFixed(2) + "%" : "N/A"}</span>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }
                                                    <hr />
                                                    <div className='newSec p-xxl-5 p-xl-5 p-lg-5 p-md-3 p-sm-3 p-3' ref={step3Ref}>
                                                        <h4 className='text-uppercase d-flex align-items-center' style={{ gap: '7px' }}><PhotoSizeSelectActualOutlinedIcon /> Content</h4>
                                                        <div className='secItem mb-4'>
                                                            <h4>Hashtags &nbsp;<BootstrapTooltip arrow className='showHandCursor' title="The hashtags section lists an influencer's most popular and engaged hashtags related to the themes of their content.
You can use this information to understand the influencer's interests and see what topics are popular with their audience." placement="top"><InfoIcon fontSize='small' color="secondary" /></BootstrapTooltip></h4>
                                                            <div className='followItems'>

                                                                {data?.content?.hashtags?.length > 0 && <ReactWordcloud words={data?.content?.hashtags} options={options} />}
                                                                {/* {data?.content?.hashtags?.length > 0 ? data?.content?.hashtags?.toString() :
                                                                    <></>
                                                                } */}
                                                            </div>

                                                            {!data?.content?.hashtags?.length && <span>No Data Found</span>}
                                                        </div>
                                                        <hr></hr>
                                                        {false && <Stack direction="row" mt={3} mb={3} gap={4}>
                                                            <Typography variant='h5' className='text-uppercase' sx={{ width: "70%" }}>
                                                                Contents
                                                            </Typography>
                                                            <Box sx={{ width: "30%", display: 'flex', justifyContent: 'flex-end' }}>
                                                                <Stack direction="row" spacing={2}>
                                                                    <Typography variant='subtitle2'>
                                                                        Top Posts
                                                                    </Typography>
                                                                    <Typography variant='subtitle2'>
                                                                        Recent Posts
                                                                    </Typography>
                                                                </Stack>
                                                            </Box>
                                                        </Stack>}

                                                        <Posts details={data} flag={true} />

                                                    </div>
                                                    <hr ref={step4Ref} />
                                                    <div className='newSec p-xxl-5 p-xl-5 p-lg-5 p-md-3 p-sm-3 p-3'>
                                                        <h4 style={{ gap: '7px' }} className='text-uppercase d-flex align-items-center'><PeopleAltOutlinedIcon /> Audience&nbsp;<BootstrapTooltip arrow className='showHandCursor' title="This section provides you with details about the audience of this influencer. Here you can see where the majority of the audiences live, their age group and their gender split." placement="top"><InfoIcon fontSize='small' color="secondary" /></BootstrapTooltip></h4>
                                                        <div className='secItem'>
                                                            <div className='followItems'>
                                                                <div className='item bgOne'>
                                                                    <ul>
                                                                        <li>
                                                                            <label className='text-uppercase'>Top City</label>
                                                                            <span className='fCapital'>{data?.Audience?.cities?.length > 0 ? getMaximumKey(data?.Audience?.cities) : "N/A"}</span>
                                                                        </li>
                                                                        {data?.platformType != YOUTUBE_TYPE &&
                                                                            <li>
                                                                                <label className='text-uppercase'>Top State</label>
                                                                                <span className='fCapital'>{data?.Audience?.state?.length > 0 ? getMaximumKey(data?.Audience?.state) : "N/A"}</span>
                                                                            </li>}
                                                                        <li>
                                                                            <label className='text-uppercase'>Top Country</label>
                                                                            <span className='fCapital'>{data?.Audience?.country?.length > 0 ? COUNTRY_JSON[getMaximumKey(data?.Audience?.country)] || "N/A" : "N/A"}</span>
                                                                        </li>
                                                                        <li>
                                                                            <label className='text-uppercase'>Top Gender</label>
                                                                            <span className='fCapital'>{data?.Audience?.gender?.length > 0 ? getGender(getMaximumKey(data?.Audience?.gender?.filter((v, i) => v['U'] === undefined))) : "N/A"}</span>
                                                                        </li>
                                                                        <li>
                                                                            <label className='text-uppercase'>Top Age Group</label>
                                                                            <span>{data?.Audience?.age_group?.length > 0 ? formatAgeString(getMaximumKey(data?.Audience?.age_group)) + " Years" : "N/A"}    </span>

                                                                        </li>
                                                                        <li>
                                                                            <label className='text-uppercase'>Audience Quality</label>
                                                                            <span>Coming Soon</span>
                                                                        </li>
                                                                    </ul>
                                                                </div>

                                                            </div>
                                                        </div>
                                                        <Grid container>
                                                            <Grid className='progSection bgTwo' item md={12} sx={12} lg={12} mt={2}>

                                                                <Typography mb={3} className='fs-6 text-uppercase' variant='h4' component="h4">Audience Geography</Typography>

                                                                <Box className="progressbarList">

                                                                    {data?.Audience?.country?.length > 0 ? getTop10Records(data?.Audience?.country)?.map((obj, i) => {


                                                                        const key = Object.keys(obj)[0];
                                                                        const value = obj[key];
                                                                        const val = COUNTRY_JSON[key.toUpperCase()] || "-";
                                                                        const v = (value / sumOfAll(getTop10Records(data?.Audience?.country))) * 100; // sumOfAll(data?.Audience?.country);

                                                                        return <LinearProgressWithLabel key={i} value={v} country={val} />
                                                                    }) : <span>No Data Found</span>}


                                                                </Box>

                                                            </Grid>
                                                            <Grid className='progSection bgTwo' item md={12} sx={12} lg={12} mt={2}>

                                                                <Typography mb={3} className='fs-6 text-uppercase' variant='h4' component="h4">Audience Cities</Typography>
                                                                <Box className="progressbarList">

                                                                    {data?.Audience?.cities?.length > 0 ? getTop10Records(data?.Audience?.cities)?.map((obj, i) => {
                                                                        const key = Object.keys(obj)[0];
                                                                        const value = obj[key];

                                                                        return <LinearProgressWithLabel key={i} value={((value / sumOfAll(getTop10Records(data?.Audience?.cities))) * 100)} country={key} />
                                                                    }) : <span>No Data Found</span>}


                                                                </Box>
                                                            </Grid>
                                                            {false && <Grid className='progSection bgTwo p-0' item md={12} sx={12} lg={12} mt={2} >

                                                                <Typography mb={3} px={3} pt={3} className='fs-6 text-uppercase' variant='h4' component="h4">Audience Cities</Typography>
                                                                <Table className='table_data' aria-label="simple table">
                                                                    <TableBody>

                                                                        {data?.Audience?.cities?.length > 0 ? getTop10Records(data?.Audience?.cities)?.map((obj, i) => {
                                                                            const key = Object.keys(obj)[0];
                                                                            const value = obj[key];

                                                                            return (
                                                                                <TableRow key={key} >
                                                                                    <TableCell component="th" scope="row" style={{ background: 'transparent !important' }}>
                                                                                        {key}
                                                                                    </TableCell>
                                                                                    <TableCell align="right" style={{ background: 'transparent !important' }}>{value}</TableCell>
                                                                                </TableRow>
                                                                            );
                                                                        }) : <></>}

                                                                    </TableBody>
                                                                </Table>

                                                            </Grid>}
                                                            {data?.platformType != YOUTUBE_TYPE &&
                                                                <Grid className='progSection bgOne' item md={12} sx={12} lg={12} mt={2} >
                                                                    <Typography mb={3} className='fs-6 text-uppercase' variant='h4' component="h4">State</Typography>
                                                                    <Box className="progressbarList">
                                                                        {data?.Audience?.state?.length > 0 ? getTop10Records(data?.Audience?.state)?.map((obj, i) => {
                                                                            const key = Object.keys(obj)[0];
                                                                            const value = obj[key];
                                                                            const percentage = ((value / sumOfAll(getTop10Records(data?.Audience?.state))) * 100);
                                                                            return <LinearProgressWithLabel key={i} value={percentage} country={key} />
                                                                        }) : <span>No Data Found</span>}
                                                                    </Box>

                                                                </Grid>
                                                            }
                                                            <Grid className='progSection bgOne' item md={12} sx={12} lg={12} mt={2}>
                                                                <Typography mb={3} className='fs-6 text-uppercase' variant='h4' component="h4">Audience Age Group</Typography>

                                                                <Box className="progressbarList">

                                                                    {data?.Audience?.age_group?.length > 0 ? getTop10Records(data?.Audience?.age_group)?.map((obj, i) => {
                                                                        const key = Object.keys(obj)[0];
                                                                        const value = obj[key];

                                                                        return <LinearProgressWithLabel key={i} value={((value / sumOfAll(getTop10Records(data?.Audience?.age_group))) * 100)} country={formatAgeString(key)} />
                                                                    }) : <span>No Data Found</span>}


                                                                </Box>
                                                            </Grid>
                                                            <Grid className='progSection bgOne' item md={12} sx={12} lg={12} mt={2}>
                                                                <Typography mb={3} className='fs-6 text-uppercase' variant='h4' component="h4">Gender</Typography>

                                                                <Box className="progressbarList">

                                                                    {data?.Audience?.gender?.length > 0 ? getTop10Records(data?.Audience?.gender?.filter((v, i) => v['Male'] || v['Female'] || v['m'] || v['f'] || v['male'] || v['female'] || v['M'] || v['F']))?.map((obj, i) => {
                                                                        const key = Object.keys(obj)[0];
                                                                        const value = obj[key];

                                                                        return <LinearProgressWithLabel key={i} value={((value / sumOfAll(getTop10Records(data?.Audience?.gender?.filter((v, i) => v['Male'] || v['Female'] || v['m'] || v['f'] || v['male'] || v['female'] || v['M'] || v['F'])))) * 100)} country={getGender(key)} />
                                                                    }) : <span>No Data Found</span>}


                                                                </Box>
                                                            </Grid>

                                                            {false && <Grid className='progSection bgTwo p-0' item md={12} sx={12} lg={12} mt={2}>
                                                                <Typography mb={3} px={3} pt={3} className='fs-6' variant='h4' component="h4">Gender</Typography>
                                                                <Box className="progressbarList">

                                                                    <Table className='table_data' aria-label="simple table">
                                                                        <TableBody>
                                                                            {data?.Audience?.gender?.length > 0 && getTop10Records(data?.Audience?.gender)?.map((obj, i) => {
                                                                                const key = Object.keys(obj)[0];
                                                                                const value = obj[key];

                                                                                return (
                                                                                    <TableRow key={key} >
                                                                                        <TableCell component="th" scope="row" style={{ background: 'transparent !important' }}>
                                                                                            {key}
                                                                                        </TableCell>
                                                                                        <TableCell align="right" style={{ background: 'transparent !important' }}>{value}</TableCell>
                                                                                    </TableRow>
                                                                                );
                                                                            })}

                                                                        </TableBody>
                                                                    </Table>
                                                                </Box>
                                                            </Grid>}



                                                        </Grid>
                                                    </div>
                                                    <hr ref={step5Ref} />
                                                    <div className='newSec p-xxl-5 p-xl-5 p-lg-5 p-md-3 p-sm-3 p-3'>
                                                        <h4 className='text-uppercase d-flex align-items-center' style={{ gap: '10px' }}>
                                                            <TrendingUpIcon />GROWTH
                                                        </h4>
                                                        <div className='secItem mb-3'>
                                                            <div className='followItems'>
                                                                <div className='item bgOne'>
                                                                    <ul>

                                                                        <li>
                                                                            <label className='text-uppercase'>30D FOLLOWERS GROWTH RATE&nbsp;
                                                                                <BootstrapTooltip arrow title="30D followers growth rate is a
measure of how many new followers
an influencer has gained or lost over a
period of 30 days, expressed as a
percentage." placement="top"><InfoIcon fontSize='small' color="secondary" /></BootstrapTooltip></label>
                                                                            <span>{parseFloat(data?.Audience?.growth)?.toFixed(2) + "%" || "N/A"}</span>
                                                                        </li>
                                                                        <li>
                                                                            <label className='text-uppercase'>30D FOLLOWERS GAIN&nbsp;<BootstrapTooltip arrow title="30D followers gain shows the total
number of followers this influencer
gained or lost over a period of 30
days." placement="top"><InfoIcon fontSize='small' color="secondary" /></BootstrapTooltip></label>
                                                                            <span>{getFixedValue(data?.Audience?.monthGain) || "N/A"}</span>
                                                                        </li>

                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                    <hr ref={step6Ref} />
                                                    <div className='newSec p-xxl-5 p-xl-5 p-lg-5 p-md-3 p-sm-3 p-3' >


                                                        <Brands brands={data?.Brands} handleId={data?.handleRecordId} userId={data?.userId} />


                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </TabPanel>



                            </TabContext>
                        </Box>
                    </div>
                        :
                        detailLoader ? <Typography p={2} variant="h1" bgcolor={"white"}>
                            Loading...
                        </Typography> : <Typography p={2} variant="h1" bgcolor={"white"}>
                            No Data  found
                        </Typography>
                    }
                </Box>

            </Stack >
            <CustomModal
                open={open}
                onClose={() => setOpen(!open)}
                modal={modal}
                approveFn={handleSubmit}
            />


        </>
    )

}
export default UserSocialDetails;